import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  multiDepotOutlierURL,
  resourceURL,
} from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { multiDayUploadStepInfo } from "../../../static/constants/stepInfo";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { NextPageButton } from "../../assessmentPages/commonComponents";
import UseAuth from "../../auth/useAuth";
import { MultiDayUploadAnalysisStepper } from "../../secondary/steppers";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import {
  getLabel,
  getLocalData,
  getMasterData,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
} from "../../utils";
import SimulationSubtitle from "../dialogs/simulationSubtitle";

const fetchVehicleData = async () => {
  try {
    const result = await getLocalData("multiDayUpload", "data");
    return result?.data || [];
  } catch (error) {
    console.error("Error fetching vehicle data:", error);
    return [];
  }
};

/** Function to process the data and get earliest departure and latest return times for each vehicleId
 * @param {{vehicleId: any, depotArrivalDateTime: any, depotDepartureDateTime: any}[]} data
 * */
const getEarliestAndLatestTimes = (data) => {
  const vehicleTimes = {};

  // Group by vehicleId
  data.forEach((row) => {
    const { vehicleId, depotArrivalDateTime, depotDepartureDateTime } = row;
    if (!vehicleTimes[vehicleId]) {
      vehicleTimes[vehicleId] = {
        earliestDeparture: depotDepartureDateTime,
        latestArrival: depotArrivalDateTime,
        num_blocks: 0,
        days: new Set().add(depotDepartureDateTime.toDateString()),
      };
    } else {
      // Update the earliest departure and latest return for each vehicle
      if (
        depotDepartureDateTime &&
        (!vehicleTimes[vehicleId].earliestDeparture ||
          depotDepartureDateTime < vehicleTimes[vehicleId].earliestDeparture)
      ) {
        vehicleTimes[vehicleId].earliestDeparture = depotDepartureDateTime;
      }
      if (
        depotArrivalDateTime &&
        (!vehicleTimes[vehicleId].latestArrival ||
          depotArrivalDateTime > vehicleTimes[vehicleId].latestArrival)
      ) {
        vehicleTimes[vehicleId].latestArrival = depotArrivalDateTime;
      }
    }

    vehicleTimes[vehicleId].num_blocks += 1;
    vehicleTimes[vehicleId].days.add(depotDepartureDateTime.toDateString());
  });

  // Return the combined data for each vehicleId
  return Object.keys(vehicleTimes).map((id) => ({
    vehicleId: id,
    startDateTime: vehicleTimes[id].earliestDeparture,
    endDateTime: vehicleTimes[id].latestArrival,
    num_blocks: vehicleTimes[id].num_blocks,
    num_days: vehicleTimes[id].days.size,
  }));
};

const VehicleAssignment = () => {
  const STEP_NUMBER = 1;
  const [tableData, setTableData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();

  const units = getUnits();

  const isProceedDisabled = useMemo(() => {
    const typeSizeMap = {};
    tableData.forEach(({ type, size, num_blocks }) => {
      const key = `${type}-${size}`;
      typeSizeMap[key] = (typeSizeMap[key] || 0) + num_blocks;
      if (!size) typeSizeMap[key] = Infinity;
    });

    const hasSingleVehicle = Object.values(typeSizeMap).some(
      (count) => count <= 1
    );

    return hasSingleVehicle;
  }, [tableData]);

  useEffect(() => {
    document.querySelector("#App").scrollIntoView(); //scrolls to top of app

    const loadData = async () => {
      setLoading(true);
      const importedData = await fetchVehicleData();
      setImportedData(importedData);
      const { data: savedData } = await getLocalData(
        "vehicleAssignment",
        "data"
      );
      if (savedData) {
        setTableData(
          savedData.map((row) => ({
            ...row,
            veh_type: row.veh_type || "",
            size: row.size || "",
          }))
        );
      } else {
        const vehicleData = importedData.map((item) => ({
          vehicleId: item.vehicleId || "",
          depotArrivalDateTime: item.depotArrivalDateTime || "",
          depotDepartureDateTime: item.depotDepartureDateTime || "",
        }));
        const combinedData = getEarliestAndLatestTimes(vehicleData);
        setTableData(combinedData);
      }
      setLoading(false);
    };

    const handleFetchResources = async () => {
      const {
        data: { depot_id },
      } = await getLocalData("simulation", "data");

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      try {
        // get all the vehicle resources belonging to the depot (vehicles are type 2, deprecated chargers are type 1)
        const response = await fetch(
          `${resourceURL}?depot_id=${depot_id}&type=2`,
          { method: "GET", headers }
        );

        if (!response.ok) {
          console.error(
            "Failed to fetch resource information for depot:",
            response
          );
          return;
        }

        const { data: vehicleResources } = await response.json();

        // gets master data for all vehicles in the depot
        const vehicles = await getMasterData(snackBarElement, {
          models: vehicleResources.map((v) => v.resource_id),
        });

        const resourceList = vehicles
          .map((veh) => ({
            model: veh.model,
            veh_type: Number(veh.type),
            size: veh.size,
          }))
          .sort((a, b) => a.veh_type - b.veh_type);

        setResourceData(resourceList);
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    };

    loadData();
    handleFetchResources();
  }, []);

  const getSizeDisplay = (veh_type, size) => {
    const unit = unitSmallMap[units];

    if (veh_type == 1) {
      const formattedSize = roundNumber(unitFeet(size), 0);
      return `${formattedSize} ${unit}`;
    } else if (veh_type == 4) return `Type ${size}`;
    else if (size == 14) return "Transit";
    else return `Class ${size}`;
  };

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never>[]}
     */
    () => [
      {
        accessorKey: "vehicleId",
        header: getLabel("block_id_original"),
      },
      {
        accessorKey: "num_days",
        header: "Days",
      },
      {
        accessorKey: "num_blocks",
        header: getLabel("blocks"),
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
      },
      {
        accessorKey: "veh_type",
        header: "Type",
        Cell: ({ cell, row }) => {
          const options = Array.from(
            new Set(resourceData.map((v) => v.veh_type))
          );
          const selectedType = cell.getValue() || options[0] || "";

          return (
            <Select
              size="small"
              value={selectedType}
              onChange={(e) => {
                const newType = e.target.value;
                const updatedRowData = tableData.map((data, index) => {
                  if (index == row.index)
                    return { ...data, veh_type: newType, size: "" };
                  return data;
                });
                setTableData(updatedRowData);
              }}
              fullWidth
              displayEmpty
            >
              {options.length ? (
                options.map((veh_type, index) => (
                  <MenuItem key={index} value={veh_type}>
                    {TYPE_STRINGS.VEHICLE_TYPE[veh_type]}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value={selectedType}>
                  <Skeleton variant="wave" sx={{ width: "100%" }} />
                </MenuItem>
              )}
            </Select>
          );
        },
        filterVariant: "select",
        filterSelectOptions: [
          ...resourceData.reduce(
            (mem, veh) => mem.add(veh.veh_type),
            new Set()
          ),
        ].map((veh_type) => ({
          value: veh_type,
          label: TYPE_STRINGS.VEHICLE_TYPE[veh_type],
        })),
        muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } },
      },
      {
        accessorKey: "size",
        header: "Size",
        Cell: ({ cell, row }) => {
          const selectedType = row.getValue("veh_type");
          const rawSize = cell.getValue() || "";

          return (
            <Select
              size="small"
              value={rawSize}
              onChange={(e) => {
                const newSize = e.target.value;
                const updatedRowData = tableData.map((data, index) => {
                  if (index == row.index) return { ...data, size: newSize };
                  return data;
                });
                setTableData(updatedRowData);
              }}
              fullWidth
              displayEmpty
            >
              {resourceData.length ? (
                resourceData
                  .filter((v) => v.veh_type == selectedType) // Filter by selected type
                  .filter((value, index, self) => self.indexOf(value) == index) // Remove duplicates
                  .map((row, index) => (
                    <MenuItem key={index} value={row.size}>
                      {getSizeDisplay(selectedType, row.size)}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem disabled value={rawSize}>
                  <Skeleton variant="wave" sx={{ width: "100%" }} />
                </MenuItem>
              )}
            </Select>
          );
        },
        filterFn: (row, columnId, filterValue) =>
          getSizeDisplay(
            row.getValue("veh_type"),
            row.getValue(columnId)
          ).indexOf(filterValue) != -1,
      },
      {
        header: "First Date",
        accessorFn: (row) => row.startDateTime.toLocaleDateString(),
        sortingFn: "datetime",
        filterVariant: "date",
      },
      {
        header: "Last Date",
        accessorFn: (row) => row.endDateTime.toLocaleDateString(),
        sortingFn: "datetime",
        filterVariant: "date",
      },
    ],
    [resourceData, tableData]
  );

  useEffect(() => {
    const loadSavedData = async () => {
      if (tableData.length && resourceData.length)
        setTableData((savedData) =>
          savedData.map((row) => ({
            ...row,
            veh_type: row.veh_type || resourceData[0].veh_type,
            size:
              row.size ||
              (row.veh_type ? "" : getDefaultSize(resourceData[0].veh_type)), // if the type already exists, but the size doesn't, that means that the user has changed the type, but not finished changing the size yet, and we shouldn't interrupt
          }))
        );
    };

    loadSavedData();
  }, [resourceData]);

  const getDefaultSize = (selectedType) => {
    const typeEntry = resourceData.find((v) => v.veh_type == selectedType);
    return typeEntry ? typeEntry.size : "";
  };

  /**
   * accounts for the timezone offset from UTC
   * @param {Date} datetime
   */
  function handleTimezoneOffset(datetime) {
    datetime.setMinutes(datetime.getMinutes() - datetime.getTimezoneOffset());
  }

  const sendDataToBackend = async (fleetData, fleetSizeMap) => {
    try {
      const response = await fetch(multiDepotOutlierURL, {
        method: "POST",
        headers: {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=1.0.0`,
        },
        body: JSON.stringify(fleetData),
      });

      if (!response.ok) {
        errorHandler(response, snackBarElement, "Failed to compute outliers");
        setIsLoading(false);
        return;
      }

      const result = await response.json();

      storeLocalData("fleetProjection", { data: result, input: fleetSizeMap });
      await storeLocalData("vehicleAssignment", {
        data: tableData.map((row) => ({
          ...row,
          veh_type: row.veh_type || "",
          size: row.size || "",
          fleet_setting: "variable",
        })),
      });
      navigate(multiDayUploadStepInfo[STEP_NUMBER + 1].route);
    } catch (error) {
      console.log("Error sending fleet data:", error);
      snackBarElement.current.displayToast(
        "Network Error: failed to compute outliers",
        "error",
        5000
      );
      setIsLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmContinue = () => {
    setDialogOpen(false);
    handleContinue();
  };

  const handleContinue = () => {
    setIsLoading(true);

    //clear out the future pages' frontend data
    partialClearLocalData([
      "fleetProjection",
      "scheduleGeneration",
      "historicalAnalysisData",
    ]);

    // NOTE: ALSO DONE IN FLEET PROJECTION PAGE
    const fleetSizeMap = tableData.reduce((mem, { veh_type, size }) => {
      const key = `${TYPE_STRINGS.VEHICLE_TYPE[veh_type]}-${size}`;
      mem[key] = (mem[key] || 0) + 1;
      return mem;
    }, {});

    const fleetMap = new Map(); // To ensure unique fleet configurations

    const resourceMap = new Map();
    resourceData.forEach(({ veh_type, size, model }) => {
      const key = `${veh_type}-${size}`;
      if (!resourceMap.has(key)) resourceMap.set(key, []);
      resourceMap.get(key).push(model);
    });

    const importedDataMap = new Map();
    importedData.forEach((entry) => {
      if (!importedDataMap.has(entry.vehicleId))
        importedDataMap.set(entry.vehicleId, []);

      importedDataMap.get(entry.vehicleId).push(entry);
    });

    const duplicateBlockIdSet = new Set();
    tableData.forEach(({ veh_type, size, vehicleId }) => {
      const fleetKey = `${veh_type}-${size}`;
      let fleet = {};
      if (!fleetMap.has(fleetKey)) {
        fleet = {
          type_name: TYPE_STRINGS.VEHICLE_TYPE[veh_type],
          size: size,
          veh_type: veh_type,
          fleet_setting: "variable",
          block_schedule: [],
          vehicleModel: resourceMap.get(`${veh_type}-${size}`) || [],
        };
        fleetMap.set(fleetKey, fleet);
      } else fleet = fleetMap.get(fleetKey);

      const vehicleEntries = importedDataMap.get(vehicleId) || [];
      vehicleEntries.forEach((entry) => {
        const departureDateTime = entry.depotDepartureDateTime;
        const arrivalDateTime = entry.depotArrivalDateTime;

        handleTimezoneOffset(departureDateTime);
        handleTimezoneOffset(arrivalDateTime);

        const formattedDate = departureDateTime.toJSON().split("T")[0];

        // Prevent duplicate blocks
        const blockId = `${entry.blockId}_${formattedDate}`;
        if (!duplicateBlockIdSet.has(blockId)) {
          duplicateBlockIdSet.add(blockId);
          fleet.block_schedule.push({
            vehicleId: entry.vehicleId,
            blockId,
            depotDepartureDateTime: departureDateTime.toJSON(),
            depotArrivalDateTime: arrivalDateTime.toJSON(),
            arrivalDepot: entry.arrivalDepot,
            distance: entry.distance,
            timeOffset: entry.timeOffset,
          });
        }
      });
    });

    // Convert fleetMap values to an array
    const fleetData = { fleet_sizing: Array.from(fleetMap.values()) };
    sendDataToBackend(fleetData, fleetSizeMap);
  };

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: tableData,
    columns,
    autoResetPageIndex: false, //prevents the page index from resetting to 0 when editing type/size dropdowns
    enableEditing: false,
    enableRowSelection: false,
  });

  return (
    <div
      style={{
        margin: "20px auto",
        padding: "20px",
        width: "95%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <br />
      <br />
      <MultiDayUploadAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      {isProceedDisabled && (
        <Snackbar
          open={isProceedDisabled}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="warning">
            There must be at least 2 {getLabel("blocks")} per utilized type and
            size combination.
          </Alert>
        </Snackbar>
      )}
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {multiDayUploadStepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
        <Alert severity="info" sx={{ width: "150%" }}>
          This step connects the vehicle id to an EVopt Vehicle Model and is
          necessary to accurately continue the electrification process.
        </Alert>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <MaterialReactTable table={table} />
      </Container>
      <br />
      <br />
      <Container>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
        >
          <Button
            variant="outlined"
            className="btn"
            fullWidth
            component={Link}
            to={multiDayUploadStepInfo[STEP_NUMBER - 1].route}
            startIcon={<ArrowBackIosNew />}
          >
            Back to {multiDayUploadStepInfo[STEP_NUMBER - 1].label}
          </Button>
          <span>
            <NextPageButton
              fullWidth
              onClick={handleDialogOpen}
              disabled={
                isProceedDisabled ||
                tableData.some((row) => !row.size || !resourceData.length)
              }
              loading={isLoading}
            >
              Continue to {multiDayUploadStepInfo[STEP_NUMBER + 1].label}
            </NextPageButton>
          </span>
        </Stack>
      </Container>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Box>
            <p>
              In the next step, we're analyzing trip distances and durations to
              identify any that stand out as unusually long or short. These
              trips will be flagged as outliers and temporarily excluded from
              the analysis. However, you will have the opportunity to review
              them and re-add any that should be considered based on your
              fleet’s operational needs.
            </p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Go Back
          </Button>
          <Button
            onClick={handleConfirmContinue}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Continue"}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default VehicleAssignment;
