import Cookie from "js-cookie";
import { Route, Routes } from "react-router-dom";
//unversioned
import NotFound from "../secondary/notFound";

//old versions
import BatterySizingV1 from "./pages/old_pages/batterySizing_v1";
import EnergyAnalysisV1 from "./pages/old_pages/energyAnalysis_v1";
import FinancialAnalysisV1 from "./pages/old_pages/financialAnalysis_v1";
import FleetSizingV1 from "./pages/old_pages/fleetSizing_v1";

//most recent versions
import FleetProjection from "./multiDayUploadPages/fleetProjection";
import HistoricalAnalysis from "./multiDayUploadPages/historicalAnalysis";
import MultiDayUpload from "./multiDayUploadPages/multiDayUpload";
import ScheduleGeneration from "./multiDayUploadPages/scheduleGeneration";
import VehicleAssignment from "./multiDayUploadPages/vehicleAssignment";
import BatterySizing from "./pages/batterySizing";
import EnergyAnalysis from "./pages/energyAnalysis";
import FinancialAnalysis from "./pages/financialAnalysis";
import FleetManual from "./pages/fleetManual";
import FleetSizing from "./pages/fleetSizing";
import GTFSImport from "./pages/gtfsImport";
import RouteDefinition from "./pages/routeDefinition";
import RouteEnergy from "./pages/routeEnergy";
import SchoolBusImport from "./pages/schoolBusImport";
import SimulationSummary from "./pages/simulationSummary";

/**
 * a function that determines which file to render for the pages, based of the simulation version
 * @param {String} simulation_version the version of the simulation
 */
function version_lookup(simulation_version) {
  switch (simulation_version) {
    case "1.0.0":
      return {
        "gtfs-import": <GTFSImport />,
        "school-bus": <SchoolBusImport />,
        "fleet-manual": <FleetManual />,
        "route-definition": <RouteDefinition />,
        "route-energy": <RouteEnergy />,
        "battery-sizing": <BatterySizingV1 />,
        "fleet-charging": <FleetSizingV1 />,
        "energy-analysis": <EnergyAnalysisV1 />,
        "financial-Analysis": <FinancialAnalysisV1 />,
        summary: <SimulationSummary />,
      };
    case "1.1.0":
    case "1.2.0":
      return {
        "gtfs-import": <GTFSImport />,
        "school-bus": <SchoolBusImport />,
        "fleet-manual": <FleetManual />,
        "route-definition": <RouteDefinition />,
        "route-energy": <RouteEnergy />,
        "battery-sizing": <BatterySizingV1 />,
        "fleet-charging": <FleetSizingV1 />,
        "energy-analysis": <EnergyAnalysisV1 />,
        "financial-Analysis": <FinancialAnalysis />,
        summary: <SimulationSummary />,
      };
    case "1.3.0":
    case "1.4.0":
      return {
        "gtfs-import": <GTFSImport />,
        "school-bus": <SchoolBusImport />,
        "fleet-manual": <FleetManual />,
        "multi-day-upload": <MultiDayUpload />,
        "vehicle-assignment": <VehicleAssignment />,
        "fleet-projection": <FleetProjection />,
        "historical-analysis": <HistoricalAnalysis />,
        "schedule-generation": <ScheduleGeneration />,
        "route-definition": <RouteDefinition />,
        "route-energy": <RouteEnergy />,
        "battery-sizing": <BatterySizing />,
        "fleet-charging": <FleetSizing />,
        "energy-analysis": <EnergyAnalysis />,
        "financial-Analysis": <FinancialAnalysis />,
        summary: <SimulationSummary />,
      };
  }
}

/** determines which version of the assesment analysis components to use */
function AssessmentAnalyses() {
  const simulation_version = Cookie.get("simulation_version");
  const routes = version_lookup(simulation_version);

  return simulation_version ? (
    <Routes>
      {Object.entries(routes).map(([path, element]) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  ) : (
    <NotFound />
  );
}

export default AssessmentAnalyses;
