import { MenuItem, TextField } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { unitWrapper } from "../../utils";

export default function BatterySizingGroupEdit({
  allData,
  rowSelection,
  chargerUpdateOptionsLookup,
}) {
  const data = useMemo(() => {
    let dataLookup = {};
    allData.forEach((row) => {
      if (
        !(row.vehicleModel in dataLookup) ||
        dataLookup[row.vehicleModel]?.batter_size < row.batter_size //only display largest batter size available
      )
        dataLookup[row.vehicleModel] = {
          ...dataLookup[row.vehicleModel], // preserve sums
          model: row.vehicleModel,
          veh_type: row.veh_type,
          size: row.size,
          batter_size: row.batter_size,
        };
      //add 1 to the vehicleModel's modelCounter, or set modelCount to 1 if this is the first appearance
      dataLookup[row.vehicleModel].modelCount =
        dataLookup[row.vehicleModel].modelCount + 1 || 1;
      // if the selectedCount doesn't exist yet, set it to 0, and then if the current row was selected, add 1
      dataLookup[row.vehicleModel].selectedCount =
        (dataLookup[row.vehicleModel].selectedCount || 0) +
        Boolean(rowSelection[row.id]);
    });
    return Object.values(dataLookup);
  }, [allData]);
  const units = getUnits();

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown

      return [
        // { header: "Vehicle Model", accessorKey: "model" },
        {
          header: "Selected",
          accessorKey: "selectedCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
        },
        {
          header: "Total",
          accessorKey: "modelCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
        },
        {
          header: "Vehicle Type",
          accessorKey: "veh_type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "select",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } }, //sx: { width: "calc(var(--header-veh_type-size)* 1px)" },
        },
        {
          header: "Size",
          accessorKey: "size",
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : cell.getValue() == 14 ? (
              "Transit"
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : row.getValue(columnId) == 14
              ? "Transit"
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
        },
        {
          header: "Current Battery",
          accessorKey: "batter_size",
          enableEditing: false,
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
        },
        {
          header: "Selected Battery",
          Cell: ({ row }) => {
            const key = `t_${row.getValue("veh_type")}_s_${row.getValue(
              "size"
            )}`;
            return (
              <TextField
                name={row.original.model} //uses row's model, to identify original model during bulkEditSubmission
                fullWidth
                defaultValue="unaltered"
                select
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: "30%" } } },
                }}
              >
                <MenuItem value={"unaltered"}>
                  {unitWrapper("EVopt Selected")}
                </MenuItem>
                {Object.entries(
                  key in chargerUpdateOptionsLookup
                    ? chargerUpdateOptionsLookup[key]
                    : []
                )
                  .sort(
                    ([_modelA, batter_sizeA], [_modelB, batter_sizeB]) =>
                      batter_sizeA - batter_sizeB
                  )
                  .map(([model, batter_size]) => (
                    <MenuItem key={model} value={model}>
                      {batter_size} {unitWrapper("kWh")}
                    </MenuItem>
                  ))}
              </TextField>
            );
          },
        },
      ];
    },
    [chargerUpdateOptionsLookup, data]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data,
    columns,
    initialState: {
      ...materialReactTableOptions().initialState,
      columnVisibility: { model: false },
    },
    enableHiding: false,
    enablePagination: false, //disabling pagination seems to remove the limit on number of rows displayed at once (tested for up to 14 rows)
    enableBottomToolbar: false,
    enableTopToolbar: false,
  });

  return <MaterialReactTable table={table} />;
}
