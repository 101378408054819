import Dexie from "dexie";

export const localDb = new Dexie("localDatabase");

localDb.version(9).stores({
  //You must manually update the version number whenever you add/remove to the below schema
  ///the page/table data is contained in the "data" key for each table, and the custom input fields are contained in the "input" key
  //NOTE: remember to update localDbkeys in static/constants/localDb.json to match
  manualInput: "blockId",
  project: "++id",
  blocks: "++id",
  routeEnergy: "++id",
  battery: "++id",
  fleetSizing: "++id",
  evAssessment: "++id",
  financial: "++id",
  tco: "++id",
  simulation: "++id",
});

localDb
  .version(10)
  .stores({
    //You must manually update the version number whenever you add/remove to the below schema
    ///the page/table data is contained in the "data" key for each table, and the custom input fields are contained in the "input" key
    //NOTE: remember to update localDbTableNames in static/constants/localDb.json to match
    manualInput: "blockId", //manual input remains unchanged
    project: null, //delete the original table
    projectTemp: "key",
    blocks: null, //delete the original table
    blocksTemp: "key",
    routeEnergy: null, //delete the original table
    routeEnergyTemp: "key",
    battery: null, //delete the original table
    batteryTemp: "key",
    fleetSizing: null, //delete the original table
    fleetSizingTemp: "key",
    evAssessment: null, //delete the original table
    evAssessmentTemp: "key",
    financial: null, //delete the original table
    financialTemp: "key",
    tco: null, //delete the original table
    tcoTemp: "key",
    simulation: null, //delete the original table
    simulationTemp: "key",
  })
  .upgrade(async (tx) => {
    await Promise.all(
      [
        "project",
        "blocks",
        "routeEnergy",
        "battery",
        "fleetSizing",
        "evAssessment",
        "financial",
        "tco",
        "simulation",
      ].map((key) =>
        tx?.[key]?.toCollection()?.last((oldFormat) =>
          tx?.table(`${key}Temp`)?.bulkAdd([
            { key: "data", value: oldFormat?.data },
            { key: "input", value: oldFormat?.input },
          ])
        )
      )
    );
  });

//note: normally, version updates don't require the use of a .upgrade().
// It was required here due to the change in primary keys, which normally isn't permitted by dexie
localDb
  .version(11)
  .stores({
    //You must manually update the version number whenever you add/remove to the below schema
    ///the page/table data is contained in the "data" key for each table, and the custom input fields are contained in the "input" key
    //NOTE: remember to update localDbTableNames in static/constants/localDb.json to match
    manualInput: "blockId", //manual input remains unchanged
    project: "key", // move the temp table back into the original key
    projectTemp: null, //delete the temp table
    blocks: "key", // move the temp table back into the original key
    blocksTemp: null, //delete the temp table
    routeEnergy: "key", // move the temp table back into the original key
    routeEnergyTemp: null, //delete the temp table
    battery: "key", // move the temp table back into the original key
    batteryTemp: null, //delete the temp table
    fleetSizing: "key", // move the temp table back into the original key
    fleetSizingTemp: null, //delete the temp table
    evAssessment: "key", // move the temp table back into the original key
    evAssessmentTemp: null, //delete the temp table
    financial: "key", // move the temp table back into the original key
    financialTemp: null, //delete the temp table
    tco: "key", // move the temp table back into the original key
    tcoTemp: null, //delete the temp table
    simulation: "key", // move the temp table back into the original key
    simulationTemp: null, //delete the temp table
  })
  .upgrade((tx) =>
    [
      "project",
      "blocks",
      "routeEnergy",
      "battery",
      "fleetSizing",
      "evAssessment",
      "financial",
      "tco",
      "simulation",
    ].forEach((key) =>
      tx?.[`${key}Temp`]?.bulkGet(["data", "input"])?.then(([data, input]) =>
        tx?.table(key)?.bulkAdd([
          { key: "data", value: data?.value },
          { key: "input", value: input?.value },
        ])
      )
    )
  );

// New Version 12 - Add multiDayUpload tables
localDb.version(12).stores({
  // Schema changes for version 13
  manualInput: "blockId", // No change for manualInput table
  project: "key", // No change for project table
  blocks: "key", // No change for blocks table
  routeEnergy: "key", // No change for routeEnergy table
  battery: "key", // No change for battery table
  fleetSizing: "key", // No change for fleetSizing table
  evAssessment: "key", // No change for evAssessment table
  financial: "key", // No change for financial table
  tco: "key", // No change for tco table
  simulation: "key", // No change for simulation table
  multiDayUpload: "key",
  vehicleAssignment: "key",
  historicalAnalysisData: "key",
  fleetProjection: "key",
  scheduleGeneration: "key",
});
