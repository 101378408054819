import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import {
  loginURL,
  logoutURL,
  termsOfServiceURL,
} from "../../static/constants/backendRoutes";
import TermsConditions from "../dialogs/termsConditions";
import { clearLocalDb, errorHandler, getSubdomain } from "../utils";
import UseAuth from "./useAuth";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [termsAndConditionsState, setTermsAndConditionsState] = useState({
    token: null,
    isOpen: false,
    hasRead: false,
    hasAgreed: false, //if they have agreed once this session
    isAgreed: false, //if they are currently agreeing
  });
  const [showPassword, setShowPassword] = useState(false);
  const { logo } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (UseAuth("get")) {
      // If the user somehow reaches this page without being logged in, log them out.
      fetch(logoutURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("true_get")}`,
        },
      }).finally(() => {
        clearLocalDb();
        UseAuth("remove");
      });
    }
  }, []);

  /**
   *
   * @param {{token: string, is_new_session: boolean}} auth
   */
  function successfulLogin(auth) {
    if (UseAuth("set", { key: auth.token })) navigate("/select-membership");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get("email"),
      password: data.get("password"),
      subdomain: getSubdomain(),
    };

    fetch(loginURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        console.log(response);
        if (response.ok)
          response.json().then(({ data }) => {
            console.log(data);
            if (!data.terms_of_service_accepted) {
              setTermsAndConditionsState((prev) => ({
                ...prev,
                token: data.token,
                isOpen: true,
              }));
              snackBarElement.current.displayToast(
                "Must read and accept legal documents before continuing",
                "warning",
                10000
              );
              setLoading(false);
            } else if (data.token) successfulLogin(data);
            else {
              setLoading(false);
              window.location.reload(); //why reload here?
            }
          });
        else {
          errorHandler(response, snackBarElement);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        snackBarElement.current.displayToast(String(err), "error");
        setLoading(false);
      });
  };

  function handleTermsAndConditions(e) {
    e.preventDefault();
    if (termsAndConditionsState.isAgreed) {
      setLoading(true);

      const headers = {
        Authorization: `Token ${termsAndConditionsState.token}`,
        "Content-Type": "application/json",
      };
      const body = {
        terms_of_service_accepted: termsAndConditionsState.isAgreed,
      };

      fetch(termsOfServiceURL, {
        method: "PATCH",
        headers,
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) successfulLogin(termsAndConditionsState);
          else {
            errorHandler(res, snackBarElement);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Network Error: Failed to login",
            "error"
          );
          setLoading(false);
        });
    } else {
      snackBarElement.current.displayToast(
        "Must accept Terms and Conditions before continuing",
        "error",
        5000
      );
    }
  }

  return (
    <Grid container component="main" className="login-center">
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component="div"
        square="true"
        className="login-center"
      >
        <Box className="login-center" sx={{ my: 15 }}>
          <Paper
            variant="outlined"
            className="login-center"
            sx={{ p: { xs: 2, md: 3 } }}
          >
            <Avatar sx={{ m: 1, bgcolor: "parimary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                className="textFieldBorder"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                className="textFieldBorder"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 2, borderRadius: "16px" }}
                loadingIndicator="Signing In..."
                loading={loading}
              >
                Sign In
              </LoadingButton>
              <Grid container>
                <Grid item xs sx={{ display: "flex" }}>
                  <Link
                    component={RouterLink}
                    to="/forgotpassword"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className="login-center"
        sx={{ ml: "-1%" }}
      >
        <img
          style={{ maxWidth: "75%", maxHeight: "75%", padding: "0% 5% 0% 5%" }}
          src={logo}
          alt="logo"
        />
      </Grid>

      {/* Terms and conditions dialog */}
      <Dialog
        open={termsAndConditionsState?.isOpen}
        onClose={() =>
          setTermsAndConditionsState((state) => ({ ...state, isOpen: false }))
        }
        scroll="paper"
        maxWidth="lg"
        component="form"
        onSubmit={handleTermsAndConditions}
        fullWidth
      >
        <DialogTitle>Legal documents</DialogTitle>
        <DialogContent id="terms-and-conditions-body">
          <TermsConditions setState={setTermsAndConditionsState} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setTermsAndConditionsState((state) => ({
                ...state,
                isOpen: false,
              }))
            }
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={!termsAndConditionsState.hasRead}
            onClick={() =>
              setTermsAndConditionsState((state) => ({
                ...state,
                isOpen: false,
                hasAgreed: true,
                isAgreed: true,
              }))
            }
          >
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
