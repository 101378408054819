import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import milestone_target from "../../static/images/Milestone-Target.png";

/** the design case dialog
 * @param {Object} param0
 * @param {Boolean} param0.open whether the dialog is open
 * @param {Boolean} param0.loading when the dialog's submit button is loading
 * @param {(event: {}, reason: "backdropClick" | "escapeKeyDown") => void} param0.onClose the close dialog function
 * @param {import("react").FormEventHandler<HTMLDivElement>} param0.onSubmit the submit dialog button (fires event containing the dialog details); should be able to handle either POST/PATCH design case
 * @param {import("react").MouseEventHandler<HTMLButtonElement>} param0.onDelete
 * @param {Object} param0.designCase the design case object
 */
export default function DesignCaseDialog({
  open,
  loading,
  onClose,
  onSubmit,
  onDelete,
  designCase = {},
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={onSubmit}
      maxWidth="sm"
      fullWidth
      sx={{ "& .MuiDialog-paper": { width: "625px", maxWidth: "unset" } }}
    >
      <DialogTitle>
        Designate Electrification Milestones for {designCase.depot_name}
        <br />
        <Typography variant="body1">Analysis: {designCase.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} alignContent="center">
            <img
              src={milestone_target}
              alt="Electrification Roadmap"
              style={{ width: "100%", height: "auto", marginBottom: "8px" }}
            />
          </Grid>
          <Grid item xs={6} alignContent="center">
            This analysis represents
            <br />
            the Milestone year{" "}
            <TextField
              name="absolute_year"
              type="number"
              size="small"
              defaultValue={designCase.absolute_year}
              placeholder="20XX"
              sx={{
                width: "4em",
                backgroundColor: "#f3f4f6",
                "&:focus-within": {
                  backgroundColor: "#e0f7fa",
                },
                verticalAlign: "middle",
                mx: 0.5,
                fontSize: "1rem",
                lineHeight: "1.5",
                padding: "4px 0",
              }}
              inputProps={{
                min: 2024,
                style: { textAlign: "center", padding: 0 },
              }}
            />
            in the fleet's electrification plan.
          </Grid>
          <Grid item xs={6} display="flex" alignItems="start">
            <Checkbox
              name="is_primary"
              sx={{ fontSize: "1rem" }}
              defaultChecked={designCase.is_primary}
            />
            <Typography
              variant="body2"
              py="9px"
              sx={{ fontSize: "1.0rem", lineHeight: "1.5", py: 1 }}
            >
              This analysis also represents the fleet's Target (Final Milestone)
              for electrification at this depot.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {designCase.key_metrics && (
          <LoadingButton loading={loading} onClick={onDelete}>
            Remove Milestones
          </LoadingButton>
        )}
        <LoadingButton type="submit" loading={loading}>
          {designCase.key_metrics ? "Save Changes" : "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
