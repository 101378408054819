import MaterialTable, { MTableToolbar } from "@material-table/core";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Fade,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import axios from "axios";
import introJs from "intro.js/intro";
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  depotURL,
  projectURL,
  resourceURL,
  tariffURL,
} from "../../static/constants/backendRoutes";
import { unitSmallMap } from "../../static/constants/systems_of_measurement";
import { resourceInventoryTourOptions } from "../../static/constants/tourOptions";
import { monthNames } from "../assessmentPages/pages/financialAnalysis";
import UseAuth from "../auth/useAuth";
import Empty from "../secondary/empty";
import { ProjectStepper } from "../secondary/steppers";
import TourBeacon from "../secondary/tourBeacon";
import { unitFeet, unitPerMoney } from "../secondary/unitConversions";
import {
  Icons,
  errorHandler,
  getMasterData,
  handleKey,
  unitWrapper,
  useQuery,
} from "../utils";
import DetailedTariffInputs from "./dialogs/detailedTariffInputs";

function ResourceInventory() {
  const depotId = useQuery().get("depotId");
  const [existingDepot, setExistingDepot] = useState([]);
  const [currentDepot, setCurrentDepot] = useState();
  const [resourceData, setResourceData] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [depotLookup, setDepotLookup] = useState({
    name: {},
    unit: {},
    currency_code: {},
  });

  //displays error message in table if data failed to fetch/loading message if response not yet recieved
  const [dataFetchError, setDataFetchError] = useState(false);

  const { accessRights, organizationMemo } = useContext(DataContext);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    /**
     * fetches all the depots from the backend, to populate dropdown
     * then if the depot is in the cookies,
     * it retrieves that depot and it's associated resources from the backend,
     */
    async function fetchData() {
      try {
        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };
        //fetches all depot data, to populate depot dropdown
        fetch(`${depotURL}?organization_id=all`, {
          method: "GET",
          headers: headers,
        }).then((depotResponse) => {
          if (depotResponse.ok) {
            return depotResponse.json().then(({ data: depots }) => {
              fetch(`${projectURL}?organization_id=all`, {
                method: "GET",
                headers: headers,
              }).then((projectResponse) => {
                if (projectResponse) {
                  return projectResponse.json().then(({ data: projects }) => {
                    setExistingDepot(depots);
                    let depot_lookup = {
                      name: {},
                      unit: {},
                      currency_code: {},
                    };
                    depots.forEach((depot) => {
                      const project = projects.find(
                        (project) => project.id == depot.project_id
                      );
                      depot_lookup.name[depot.id] = depot.name;
                      //todo: get backend to start including unit/currency field in resource response and remove the below unit lookup
                      depot_lookup.unit[depot.id] = project.unit;
                      depot_lookup.currency_code[depot.id] =
                        project.currency_code;
                    });
                    setDepotLookup(depot_lookup);
                    if (depotId)
                      setCurrentDepot(
                        depots.find((depot) => depot.id == depotId)
                      );
                  });
                }
                //todo: remove this once backend starts including the unit in their resource response
                errorHandler(projectResponse, snackBarElement);
                setDataFetchError(true);
              });
            });
          }
          //else
          errorHandler(depotResponse, snackBarElement);
          setDataFetchError(true);
          setExistingDepot(undefined);
        });

        //get all the vehicle data
        const vehicles = await getMasterData(snackBarElement);

        //fetches all vehicle resources in the depot
        fetch(
          `${resourceURL}?type=2&depot_id=${depotId ?? `&organization_id=all`}`,
          { method: "GET", headers: headers }
        ).then((response) => {
          if (response.ok) {
            return response.json().then(({ data: vehicleResources }) => {
              const resourceList = vehicleResources.map((resource) => {
                //adds in size/veh_type and for table
                const x = vehicles.find(
                  (vehicle) => vehicle.model == resource.resource_id
                );
                return { ...resource, veh_type: x.type, size: x.size };
              });
              setVehicle(vehicles);
              setResourceData(resourceList);
              if (!resourceList.length) setDataFetchError(true); //replaces Loading message with a "No records to display" if the list is empty
            });
          }
          //else
          errorHandler(
            response,
            snackBarElement,
            "Something went wrong getting resources from DB"
          );
          setDataFetchError(true);
        });
      } catch (e) {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to get Vehicle data");
        else {
          console.log(e);
          snackBarElement.current.displayToast(
            "Something went wrong",
            "error",
            5000
          );
        }
        setDataFetchError(true);
        setExistingDepot(undefined);
      }
    }
    fetchData();
  }, [depotId, organizationMemo.lookup]);

  const handleSubmit = useCallback(
    /**
     * creates a new resource and refreshes the table
     * @returns {Promise<number>} 0 for success, -1 for failure
     */
    (new_data, type) => {
      try {
        const depotToBeSent = depotId ?? new_data.depot_id;

        const selectedVehicle = vehicle.find(
          (v) => v.type == new_data.veh_type && v.size == new_data.size
        );

        if (!depotToBeSent || !selectedVehicle) {
          snackBarElement.current.displayToast(
            "Selected Vehicle/Depot not found",
            "error"
          );
          return new Promise((resolve) => resolve(-1));
        }

        return fetch(resourceURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${UseAuth("get")}`,
          },
          body: JSON.stringify({
            depot_id: depotToBeSent,
            type: type,
            resource_id: selectedVehicle.model, //get vehicle model name from vehicle type and size
          }),
        })
          .then((response) => {
            if (response.ok) {
              snackBarElement.current.displayToast("Resource Added!");
              return response.json().then(({ data: newResource }) => {
                const veh = vehicle.find(
                  (v) => v.model == newResource.resource_id
                );
                setResourceData([
                  { ...newResource, veh_type: veh.type, size: veh.size },
                  ...resourceData,
                ]);
                return 0;
              });
            }
            //else
            errorHandler(response, snackBarElement);
            return -1;
          })
          .catch((err) => {
            console.log(err);
            snackBarElement.current.displayToast(String(err), "error");
            return -1;
          });
      } catch (error) {
        console.log(error);
        snackBarElement.current.displayToast("Something went wrong", "error");
        return -1;
      }
    },
    [vehicle, depotId, resourceData]
  );

  // /**
  //  * @deprecated
  //  * note: if ever used again, make sure to replace the setRefreshKey with a setResourceData to avoid additional GET call
  //  * updates a resource (unused, but left in for potential future usage)
  //  */
  // const handleUpdate = useCallback(async (new_data, old_data) => {
  //   let resource = {
  //     id: new_data.id,
  //     depot_id: new_data.depot_id,
  //     type: new_data.type,
  //     resource_id:
  //       //get vehicle model name from vehicle type and size
  //       vehicle.find(
  //         (v) => v.type == new_data.veh_type && v.size == new_data.size
  //       ).model,
  //   };
  //   fetch(resourceURL, {
  //     method: "PATCH",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${UseAuth("get")}`,
  //     },
  //     body: JSON.stringify(resource),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         //todo: update this so that it doesn't need to refresh (not necessary until users can edit resources again)
  //         snackBarElement.current.displayToast("Resource Updated!");
  //         setRefreshKey((oldKey) => oldKey + 1);
  //       } else errorHandler(response, snackBarElement);
  //     })
  //     .catch((err) => {
  //       snackBarElement.current.displayToast(String(err), "error");
  //       console.log(err);
  //     });
  // }, []);

  const handleDelete = useCallback(
    /**
     * deletes a list of resources
     * @param {number[]} resource_id_list
     * @returns {Promise<number>} 0 for success, -1 for failure
     */
    (resource_id_list) => {
      return fetch(resourceURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify({ resource_id_list }),
      })
        .then((response) => {
          if (response.ok) {
            snackBarElement.current.displayToast("Resource Deleted!");
            const updatedResources = resourceData.filter(
              (resource) => !resource_id_list.includes(resource.id)
            );
            setResourceData(updatedResources);
            if (!updatedResources.length) setDataFetchError(true); //so that, if the final visible resource was deleted, the table doesn't display the loading icon endlessly
            return 0;
          }
          //else
          errorHandler(response, snackBarElement);
          return -1;
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something is not right, try again",
            "error"
          );
          return -1;
        });
    },
    [resourceData]
  );

  return (
    <div>
      <br />
      <br />
      <ProjectStepper stepNum={3} projectId={currentDepot?.project_id} />
      <br />
      <br />
      {existingDepot && existingDepot.length > 0 ? (
        <div>
          <Container
            fixed
            maxWidth="xl"
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="left"
              className="page-title"
            >
              {depotId ? (
                <>
                  Current Depot:{" "}
                  <span className="header-green">
                    {existingDepot.find((depot) => depot.id == depotId)?.name}
                  </span>
                </>
              ) : (
                <>Resource Inventory Management</>
              )}
            </Typography>
          </Container>
          <br />
          <div>
            <Container fixed maxWidth="xl">
              <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
                <MemoizedResourceTable
                  depotId={depotId}
                  resourceData={resourceData}
                  vehicle={vehicle}
                  depotLookup={depotLookup}
                  dataFetchError={dataFetchError}
                  handleDelete={handleDelete}
                  handleSubmit={handleSubmit}
                  accessRights={accessRights}
                />
              </Paper>
            </Container>
            <br />
            <Container fixed>
              <Grid container spacing={1}>
                <Grid className="grid-left" item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    className="btn"
                    component={Link}
                    to={
                      depotId && currentDepot?.project_id
                        ? `/depot?projectId=${currentDepot?.project_id}`
                        : "/depot"
                    }
                    startIcon={<ArrowBackIosNew />}
                  >
                    Return to Depots
                  </Button>
                </Grid>
                {depotId && (
                  <Grid className="grid-right" item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      className="btn"
                      component={Link}
                      to={`/simulations?projectId=${currentDepot?.project_id}`}
                      disabled={!currentDepot}
                    >
                      View Project Analyses
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        </div>
      ) : dataFetchError && existingDepot == undefined ? (
        //if the project_lookup fetch succeeded, but another fetch failed, display this message
        <Empty
          text={
            <>
              No Depots Created. Create one <Link to="/depot">here</Link> and
              come back
            </>
          }
        />
      ) : (
        // displayed when fetching projects for project_lookup table
        <Fade in={true}>
          <div className="centered">
            <CircularProgress />
          </div>
        </Fade>
      )}
    </div>
  );
}

const MemoizedResourceTable = memo(
  ({
    depotId,
    resourceData,
    vehicle,
    depotLookup,
    dataFetchError,
    handleDelete,
    handleSubmit,
    accessRights,
  }) => {
    const [editTariffRowId, setEditTariffRowId] = useState(); // used to determine the id of the row being editted, if there is one
    const [tariffData, setTariffData] = useState([]);
    const [tariffEditOpen, setTariffEditOpen] = useState(false);
    const [tariffPreviewView, setTariffPreviewView] = useState(false); // used to determine if user is viewing a tariff without the ability to save the tariff (i.e. preview view)
    const [tableDisplay, setTableDisplay] = useState(2); //2 for "vehicle" view, 3 for "tariff" view
    const [isTariffLoading, setIsTariffLoading] = useState(true);

    const [isTourActive, setIsTourActive] = useState(false);

    const tableLookup = { 1: "Charger", 2: "Vehicle", 3: "Tariff" };

    const materialTableRef = useRef();
    const { organizationMemo } = useContext(DataContext);
    const { snackBarElement } = useContext(SnackBarContext);

    const [resourceDataFiltered, tariffDataFiltered] = useMemo(
      () => [
        resourceData.filter(
          (resource) =>
            !organizationMemo?.selectedOrg ||
            resource.organization_id == organizationMemo.selectedOrg
        ),
        tariffData.filter(
          (tariff) =>
            !organizationMemo?.selectedOrg ||
            tariff.organization_id == organizationMemo.selectedOrg
        ),
      ],
      [resourceData, tariffData, organizationMemo]
    );

    useEffect(() => {
      /** fetches tariff data */
      function fetchData() {
        fetch(
          `${tariffURL}?type=2&depot_id=${depotId ?? `&organization_id=all`}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${UseAuth("get")}`,
            },
          }
        )
          .then((response) => {
            if (response.ok)
              response.json().then(({ data }) => setTariffData(data));
            else errorHandler(response, snackBarElement);
          })
          .catch((e) => {
            console.log(e);
            snackBarElement.current.displayToast(
              "Network Error: failed to obtain tariff data",
              "error"
            );
          })
          .finally(() => setIsTariffLoading(false));
      }

      fetchData();
    }, [depotId]);

    /** @type {import("@material-table/core").Column<never>[]} */
    const resourceColumns = [
      //format for the Vehicle table view
      {
        title: "Resource Type",
        field: "type",
        lookup: tableLookup,
        editable: "never",
      },
      {
        title: "Vehicle Type",
        field: "veh_type",
        lookup: TYPE_STRINGS.VEHICLE_TYPE,
        validate: (rowData) =>
          TYPE_STRINGS.VEHICLE_TYPE[rowData.veh_type]
            ? true
            : "Select Vehicle Type",
        editComponent: (props) => {
          const selectableTypes = [
            ...new Set(vehicle.map((v) => v.type)), //create a list of vehicle types, removing duplicate types
          ];

          return (
            <FormControl
              fullWidth
              error={
                !props.value || props.value == "" || props.helperText
                  ? true
                  : false
              }
              variant="standard"
              required
            >
              <InputLabel id="veh-type-label">Type</InputLabel>
              <Select
                labelId="veh-type-label"
                id="veh-type"
                name="veh-type"
                value={props.value ? props.value : ""}
                defaultValue=""
                label="veh-type"
                onChange={(e) => props.onChange(e.target.value)}
                autoFocus //cursor moves to this field on edit/add
              >
                {selectableTypes.map((val) => (
                  <MenuItem key={val} value={val} onKeyDown={handleKey}>
                    {TYPE_STRINGS.VEHICLE_TYPE[val]}
                  </MenuItem>
                ))}
              </Select>
              {(!props.value || props.value === "" || props.helperText) && (
                <FormHelperText>{props.helperText}</FormHelperText>
              )}
            </FormControl>
          );
        },
      },
      {
        title: "Size",
        field: "size",
        render: (rowData) =>
          rowData.veh_type == 1 ? (
            <>
              {unitFeet(rowData.size, depotLookup.unit[rowData.depot_id] == 1)}{" "}
              {unitWrapper(unitSmallMap[depotLookup.unit[rowData.depot_id]])}
            </>
          ) : rowData.veh_type == 4 ? (
            //if selected vehicle type is a schoolbus, display Vehicle Type Character instead
            `Type ${rowData.size}`
          ) : rowData.size == 14 ? (
            "Transit"
          ) : (
            `Class ${rowData.size}`
          ),
        customFilterAndSearch: (term, rowData) =>
          (rowData.veh_type == 1
            ? unitFeet(rowData.size, depotLookup.unit[rowData.depot_id] == 1)
            : rowData.veh_type == 4
            ? `Type ${rowData.size}`
            : rowData.size == 14
            ? "Transit"
            : `Class ${rowData.size}`
          )
            .toString()
            .includes(term),
        validate: (rowData) => {
          const selectableSizes = vehicle
            .filter((v) => v.type == rowData.veh_type)
            .map((v) => v.size);
          return rowData.veh_type === "" ||
            !selectableSizes.includes(rowData.size)
            ? "No Selection"
            : true;
        },
        editComponent: (props) => {
          const selectableSizes = [
            ...new Set( //remove duplicate sizes
              vehicle
                .filter(
                  //make a list of vehicle sizes associated with the selected type
                  (v) => v.type == props.rowData.veh_type
                )
                .map((v) => v.size)
            ),
          ];

          return (
            <FormControl
              fullWidth
              error={
                !props.value || props.value == "" || props.helperText
                  ? true
                  : false
              }
              variant="standard"
              required
            >
              <InputLabel id="veh-size-label">Size</InputLabel>
              <Select
                labelId="veh-size-label"
                id="veh-size"
                name="veh-size"
                value={props.value ? props.value : ""}
                defaultValue=""
                label="veh-size"
                onChange={(e) => props.onChange(e.target.value)}
              >
                {selectableSizes.map((val) => (
                  <MenuItem key={val} value={val} onKeyDown={handleKey}>
                    {props.rowData.veh_type == 1 ? (
                      <>
                        {unitFeet(
                          val,
                          depotLookup.unit[props.rowData.depot_id || depotId] ==
                            1
                        )}{" "}
                        {unitWrapper(
                          unitSmallMap[
                            depotLookup.unit[props.rowData.depot_id || depotId]
                          ]
                        )}
                      </>
                    ) : props.rowData.veh_type == 4 ? (
                      `Type ${val}`
                    ) : val == 14 ? (
                      "Transit"
                    ) : (
                      `Class ${val}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              {(!props.value || props.value === "" || props.helperText) && (
                <FormHelperText>{props.helperText}</FormHelperText>
              )}
            </FormControl>
          );
        },
      },
      {
        title: "Depot",
        field: "depot_id",
        lookup: depotLookup.name,
        editable: `${depotId ? "never" : "onAdd"}`,
        emptyValue: Object.keys(depotLookup.name).length ? "" : "Loading...",
        validate: (rowData) =>
          rowData.depot_id || depotId ? true : "No Selection",
        editComponent: (
          props //takes care of when we create a new resource
        ) => (
          <FormControl
            fullWidth
            error={!props.value}
            variant="standard"
            required
          >
            <InputLabel id="resource_id-label">Depot</InputLabel>
            <Select
              labelId="resource_id-label"
              id="resource_id"
              name="resource_id"
              value={props.value ? props.value : ""}
              label="Resource ID"
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
            >
              {Object.entries(depotLookup.name).map(
                ([depot_id, depot_name]) => (
                  <MenuItem
                    key={depot_id}
                    value={depot_id}
                    onKeyDown={handleKey}
                  >
                    {depot_name}
                  </MenuItem>
                )
              )}
            </Select>
            {!props.value && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
          </FormControl>
        ),
      },
    ];

    /** @type {import("@material-table/core").Column<never>[]} */
    const tariffColumns = [
      {
        title: "Resource Type",
        field: "type",
        render: () => "Tariff",
        editable: "never",
      },
      { title: "Utility Name", field: "utility_name" },
      { title: "Name", field: "name" },
      {
        title: "Depot",
        field: "depot_id",
        lookup: depotLookup.name,
        editable: `${depotId ? "never" : "onAdd"}`,
        emptyValue: Object.keys(depotLookup.name).length ? "" : "Loading...",
      },
    ];

    const columnsLookup = {
      1: resourceColumns,
      2: resourceColumns,
      3: tariffColumns,
    };
    const dataLookup = {
      1: resourceDataFiltered,
      2: resourceDataFiltered,
      3: tariffDataFiltered,
    };

    function handleCloseTariff() {
      setIsTariffLoading(false);
      setTariffEditOpen(false);
      setTimeout(() => setTariffPreviewView(false), 1000);
    }

    /**
     * Formats and saves the detailed energy dialog inputs
     * @param {React.FormEvent<HTMLDivElement>} event
     */
    function handleTariffSave(event) {
      event.preventDefault();
      setIsTariffLoading(true);
      const newDataForm = new FormData(event.target);
      const newDataJSON = Object.fromEntries(newDataForm.entries());
      const { id, tariff_style, depot_id, name, utility_name } = newDataJSON;

      //compute/format tariffs
      const months = monthNames.map((month) => month.toLowerCase());
      let tariffs = { energy: {}, demand: {} };
      if (tariff_style == 1) {
        //yearly
        months.forEach((month) => {
          tariffs.energy[month] = Array(24).fill(
            unitPerMoney(
              +newDataJSON.energy,
              depotLookup.currency_code[depot_id]
            )
          );
          tariffs.demand[month] = Array(24).fill(
            unitPerMoney(
              +newDataJSON.demand,
              depotLookup.currency_code[depot_id]
            )
          );
        });
      } else if (tariff_style == 2) {
        //monthly
        months.forEach((month, monthIndex) => {
          tariffs.energy[month] = Array(24).fill(
            unitPerMoney(
              +newDataJSON[`energy_${monthIndex}`],
              depotLookup.currency_code[depot_id]
            )
          );
          tariffs.demand[month] = Array(24).fill(
            unitPerMoney(
              +newDataJSON[`demand_${monthIndex}`],
              depotLookup.currency_code[depot_id]
            )
          );
        });
      } else if (tariff_style == 3) {
        //detailed
        const { periodData, dataMatrix } = JSON.parse(newDataJSON.detailedData);
        //generate a period lookup table, so that the outputValue computation is less expensive overall
        const periodLookup = { energy: [], demand: [], period: {} };
        periodData.forEach(({ backgroundColor, period, energy, demand }) => {
          periodLookup.energy[period] = unitPerMoney(
            energy,
            depotLookup.currency_code[depot_id]
          );
          periodLookup.demand[period] = unitPerMoney(
            demand,
            depotLookup.currency_code[depot_id]
          );
          if (!periodLookup.period[`${energy}_${demand}`])
            //to remove duplicate periods
            periodLookup.period[`${energy}_${demand}`] = {
              backgroundColor,
              period,
              demand: +periodLookup.demand[period],
              energy: +periodLookup.energy[period],
            };
        });

        months.forEach((month, monthIndex) => {
          tariffs.energy[month] = dataMatrix[monthIndex].map(
            (period) => +periodLookup.energy[period]
          );
          tariffs.demand[month] = dataMatrix[monthIndex].map(
            (period) => +periodLookup.demand[period]
          );
        });
        //maintain the user's period order
        tariffs.period = Object.values(periodLookup.period).sort(
          ({ period: a }, { period: b }) => a - b
        );
      }

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      const body = {
        id,
        tariff_style,
        depot_id,
        name,
        utility_name,
        classification: { type: 1 }, //note: also defined in financial analysis' POST
        tariffs,
      };

      fetch(tariffURL, {
        method: id ? "PATCH" : "POST", // if ID exists, then this is a row EDIT, if not, it's a row ADD
        headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            if (id) {
              //if ID exists, then this is a row EDIT
              const index = tariffData.findIndex((tariff) => tariff.id == id);
              tariffData[index] = body;
              setTariffData([...tariffData]);
            } else {
              response
                .json()
                .then(({ data: newTariff }) =>
                  setTariffData([...tariffData, newTariff])
                );
            } //if ID does not exist/is empty string, then this is a row ADD

            handleCloseTariff();
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Something went wrong, try again later"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Network Error: Failed to save tariff",
            "error"
          );
        })
        .finally(() => setIsTariffLoading(false));
    }

    function handleTariffDelete(tariff_id_list) {
      return fetch(tariffURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("get")}`,
        },
        body: JSON.stringify({ tariff_id_list }),
      })
        .then((response) => {
          if (response.ok) {
            snackBarElement.current.displayToast("Tariff Deleted!");
            const updatedTariff = tariffData.filter(
              (tariff) => !tariff_id_list.includes(tariff.id)
            );
            setTariffData(updatedTariff);
            return 0;
          }
          //else
          errorHandler(response, snackBarElement);
          return -1;
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something is not right, try again",
            "error"
          );
          return -1;
        });
    }

    const handleTourStart = () => {
      let tour = introJs();

      setIsTourActive(true);
      setTimeout(
        () =>
          tour
            .setOptions(resourceInventoryTourOptions())
            .onexit(() => setIsTourActive(false))
            .start(),
        [100]
      );
    };

    //allows add Icon to be overwritten to open tariff dialog map
    const addIcon = Icons("Tariff Resource").Add.render();

    return (
      <>
        {/* general table */}
        <MaterialTable
          title="Assign / Manage Resources"
          columns={columnsLookup[tableDisplay]}
          data={dataLookup[tableDisplay]}
          icons={Icons(`Vehicle Resource`)}
          tableRef={materialTableRef}
          localization={{
            body: {
              emptyDataSourceMessage:
                (tableDisplay != 3 &&
                  (dataFetchError || resourceData.length)) ||
                (tableDisplay == 3 && !isTariffLoading) ? (
                  // if an error occurs in resource fetch, display this message in the table
                  "No records to display"
                ) : (
                  // until the point that an error occurs or the data is retrieved, display a loading message in table
                  <>
                    <CircularProgress />
                    <br />
                    Loading...
                  </>
                ),
            },
            toolbar: {
              searchPlaceholder: "Filter",
              searchTooltip: "Filter",
            },
          }}
          actions={[
            {
              icon: () => <TourBeacon component="div" />,
              tooltip: "Tutorial",
              isFreeAction: true,
              onClick: handleTourStart,
              hidden:
                isTourActive ||
                !resourceDataFiltered.length ||
                // !accessRights.projects.create_resource ||
                tableDisplay != 1,
            },
            {
              icon: forwardRef((props, ref) => addIcon),
              tooltip: "Add Tariff",
              isFreeAction: true,
              onClick: (e) => {
                setEditTariffRowId(undefined);
                setTariffEditOpen(true);
              },
              hidden: tableDisplay != 3, //|| !accessRights.projects.create_resource,
            },
            {
              icon: EditIcon,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setEditTariffRowId(rowData.id);
                setTariffEditOpen(true);
              },
              hidden: tableDisplay != 3, //|| !accessRights.projects.create_resource,
            },
            {
              icon: VisibilityIcon,
              tooltip: "Preview",
              onClick: (event, rowData) => {
                setEditTariffRowId(rowData.id);
                setTariffEditOpen(true);
                setTariffPreviewView(true);
              },
              hidden: tableDisplay != 3,
            },
            // {
            //   tooltip: "Remove Selected Rows",
            //   icon: () => <DeleteIcon />,
            //   onClick: (event, rowData) => {
            //     new Promise((resolve, reject) => {
            //       setTimeout(() => {
            //         let resource_list = [];
            //         rowData.forEach((rd) => {
            //           resource_list.push(rd.id);
            //         });
            //         handleDelete(resource_list);
            //         setRefreshKey((oldKey) => oldKey + 1);
            //         resolve();
            //       }, 1000);
            //     });
            //   },
            // },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label="Vehicles"
                      variant={tableDisplay == 2 ? "filled" : "outlined"}
                      onClick={() => setTableDisplay(2)} //sets table display to "Vehicle Models"
                    />
                    <Chip
                      label="Tariffs"
                      variant={tableDisplay == 3 ? "filled" : "outlined"}
                      onClick={() => {
                        //if user was adding a vehicle resource, cancel the add
                        const materialTable = materialTableRef.current;
                        materialTable.setState({
                          ...materialTable.dataManager.getRenderState(),
                          showAddRow: false,
                        });
                        setTableDisplay(3); //sets table display to "Tariff models"
                      }}
                    />
                  </Stack>
                </div>
              </div>
            ),
          }}
          editable={{
            onRowAddCancelled:
              (tableDisplay == 2 || //&& accessRights.projects.create_resource
                undefined) &&
              (() => new Promise((resolve, reject) => resolve())),
            onRowAdd:
              ((tableDisplay == 2 &&
                //  accessRights.projects.create_resource &&
                Object.keys(depotLookup.name).length) ||
                undefined) &&
              ((newData, oldData) =>
                new Promise((resolve, reject) => {
                  handleSubmit(newData, tableDisplay).then((status) => {
                    //table display is a stand-in for resource type
                    if (status == 0) resolve();
                    else reject();
                  });
                })),
            onRowDelete:
              tableDisplay == 2
                ? //accessRights.projects.create_resource &&
                  (Object.keys(depotLookup.name).length || undefined) &&
                  ((oldData) =>
                    new Promise((resolve, reject) => {
                      handleDelete([oldData.id]).then((status) => {
                        if (status == 0) resolve();
                        else reject();
                      });
                    }))
                : (oldData) =>
                    new Promise((resolve, reject) => {
                      handleTariffDelete([oldData.id]).then((status) => {
                        if (status == 0) resolve();
                        else reject();
                      });
                    }),
          }}
        />

        {/* Tariff Edit/Add dialog */}
        <Dialog
          open={tariffEditOpen}
          component="form"
          onClose={handleCloseTariff}
          onSubmit={(e) =>
            tariffPreviewView ? undefined : handleTariffSave(e)
          }
          maxWidth="lg"
        >
          <DetailedTariffInputs
            initialData={tariffData?.find((row) => row.id == editTariffRowId)}
            depotLookup={depotLookup}
            depotId={depotId}
            disabled={tariffPreviewView}
          />
          <DialogActions>
            <Button onClick={handleCloseTariff}>
              {tariffPreviewView ? "Close" : "Cancel"}
            </Button>
            {!tariffPreviewView && (
              <LoadingButton loading={isTariffLoading} type="submit">
                Save Tariff
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default ResourceInventory;
