import {
  Alert,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useMemo, useRef, useState } from "react";
import "../../../Grid.css";
import {
  currencySymbol,
  unitMoneyWithoutRounding,
  unitPerMoney,
} from "../../secondary/unitConversions";
import { roundNumber, unitWrapper } from "../../utils";

const periodColors = [
  "#ffffff",
  "#33FF57",
  "#5733FF",
  "#FF33A6",
  "#33A6FF",
  "#A6FF33",
  "#FFD633",
  "#FF336E",
  "#33FFD6",
  "#D633FF",
];

const Wrapper = styled("div")({ display: "flex", justifyContent: "center" });

const StyledTableContainer = styled(TableContainer)({
  maxWidth: "850px",
  maxHeight: "300px",
  margin: "auto",
  marginTop: "20px",
});

const StyledTableCell = styled(TableCell)({ fontSize: "14px" });

const DetailedEnergyInput = ({ initialData, initialStyle = undefined }) => {
  /** @type {[{backgroundColor: String, period: Number, energy: Number, demand: Number}[]]} */
  const [periodData, setPeriodData] = useState([
    { backgroundColor: "#ffffff", period: 1, energy: 0.0, demand: 0.0 },
  ]);
  const [dragStart, setDragStart] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  // a matrix of periods
  const [dataMatrix, setDataMatrix] = useState(
    Array(12)
      .fill()
      .map(() => Array(24).fill(1))
  );

  const colorChangeTimeout = useRef(0);

  useEffect(() => {
    function fetchData() {
      //periodlookup is a hashmap of [energy_demand] values, to determine if a period has been encountered before
      let periodLookup = {};
      let newPeriodData = [];
      //note: don't remove the period style stuff on next version, as this component is also used in resourceInventory view
      // maintain period style/order (older tariffs may not have this saved, so it's optional)
      const periodStyleLookup = {};
      if (initialStyle)
        initialStyle.forEach(
          (period) =>
            (periodStyleLookup[`${period.energy}_${period.demand}`] = period)
        );

      const dataMatrix = initialData?.map((rowData) =>
        rowData.map((cell) => {
          const cellKey = `${cell.energy}_${cell.demand}`;
          if (!periodLookup[cellKey]) {
            newPeriodData.push({
              backgroundColor:
                periodStyleLookup[cellKey]?.backgroundColor ??
                periodColors[newPeriodData.length % periodColors.length],
              period:
                periodStyleLookup[cellKey]?.period ?? newPeriodData.length + 1,
              energy: cell.energy,
              demand: cell.demand,
            });

            //if this is the first time the energy/demand combo has been encountered, create new period
            periodLookup[cellKey] =
              periodStyleLookup[cellKey]?.period ?? newPeriodData.length;
          }
          return periodLookup[cellKey];
        })
      );

      if (initialStyle) newPeriodData.sort((a, b) => a.period - b.period);
      //set the detailed data
      setPeriodData(newPeriodData);
      setDataMatrix(dataMatrix);
    }
    //potentially needs to run every initialDataChange
    if (initialData) fetchData();
  }, []);

  // determines if any of the rows are duplicates (duplicates are removed on save)
  const isDuplicates = useMemo(
    () =>
      periodData.some(
        ({ energy, demand }, index) =>
          index !=
          periodData.findLastIndex(
            (row) => row.energy == energy && row.demand == demand
          )
      ),
    [periodData]
  );

  const handleAddRow = () => {
    const newPeriodValue = Number(periodData[periodData.length - 1].period);
    setPeriodData([
      ...periodData,
      {
        backgroundColor: periodColors[newPeriodValue % periodColors.length],
        period: newPeriodValue + 1,
        energy: 0.0,
        demand: 0.0,
      },
    ]);
  };

  const handleDeletePeriod = (deletedPeriod) => {
    setDataMatrix(
      dataMatrix.map((dataArray) =>
        dataArray.map((period) => (period == deletedPeriod ? 1 : period))
      )
    );

    const newData = periodData.filter((row) => row.period !== deletedPeriod);
    setPeriodData(newData);
  };

  const handleInputChange = (index, field, value) => {
    const newData = [...periodData];
    newData[index][field] = unitPerMoney(+value);
    if (value === "") newData[index][field] = value;
    setPeriodData(newData);
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const hours = Array.from({ length: 24 }, (_, index) => index);

  const handleCellClick = (month, hour) => {
    if (dragStart) {
      return;
    }

    const clickedCell = { month: month, hour: hour };

    if (
      selectedCells.length === 1 &&
      selectedCells[0].month === clickedCell.month &&
      selectedCells[0].hour === clickedCell.hour
    ) {
      setSelectedCells([]);
    } else {
      setSelectedCells([clickedCell]);
    }
  };

  const handleDragStart = (month, hour) => {
    setDragStart({ month, hour });
  };

  const handleDragOver = (month, hour) => {
    if (dragStart) {
      const startMonth = dragStart.month;
      const startHour = dragStart.hour;

      const minMonth = Math.min(month, startMonth);
      const maxMonth = Math.max(month, startMonth);
      const minHour = Math.min(hour, startHour);
      const maxHour = Math.max(hour, startHour);

      const newSelection = [];

      for (let m = minMonth; m <= maxMonth; m++) {
        for (let h = minHour; h <= maxHour; h++) {
          newSelection.push({ month: m, hour: h });
        }
      }
      //keeping the selected cells in their own array seems a little innefficient
      // you could accomplish the same by merely keeping track of start & end indices
      setSelectedCells(newSelection);
    }
  };

  const handleDragEnd = () => {
    setDragStart(null);
  };

  const handleSetRowValue = (index) => {
    const selectedRow = periodData[index];
    const selectedValue = selectedRow ? selectedRow.period : "1";

    selectedCells.forEach((cell) => {
      dataMatrix[cell.month][cell.hour] = selectedValue;
    });

    setDataMatrix(JSON.parse(JSON.stringify(dataMatrix)));
  };

  return (
    <Wrapper>
      {/* The formatted data that is passed up to parent component on submission, hidden from user */}
      <input
        name="detailedData"
        value={JSON.stringify({ periodData, dataMatrix })}
        readOnly
        hidden
      />
      <div>
        {/* Dynamic Table */}
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddRow}
                  >
                    Add Period
                  </Button>
                </StyledTableCell>
                <StyledTableCell>Period</StyledTableCell>
                <StyledTableCell>
                  Energy Charge {unitWrapper(`(${currencySymbol()}/kWh)`)}
                </StyledTableCell>
                <StyledTableCell>
                  Demand Charge {unitWrapper(`(${currencySymbol()}/kW)`)}
                </StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {periodData.map((row, index) => (
                <TableRow
                  key={`period_${row.period}`}
                  sx={{
                    //colors duplicate period rows a light pink
                    backgroundColor:
                      periodData.filter(
                        ({ energy, demand }) =>
                          row.energy == energy && row.demand == demand
                      ).length >= 2
                        ? "lightpink"
                        : undefined,
                  }}
                >
                  <StyledTableCell>
                    <input
                      type="color"
                      value={row.backgroundColor}
                      onChange={(e) => {
                        //uses a timeoutReference to avoid too many (hundreds per second) re-renders
                        row.backgroundColor = e.target.value;
                        clearTimeout(colorChangeTimeout.current);
                        colorChangeTimeout.current = setTimeout(() => {
                          setPeriodData(periodData.map((i) => i));
                        }, [100]);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.period}</StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      type="number"
                      value={
                        !isNaN(row.energy) && row.energy !== ""
                          ? roundNumber(unitMoneyWithoutRounding(row.energy), 4)
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(index, "energy", e.target.value)
                      }
                      InputProps={{
                        inputProps: { min: 0.0, step: 0.0001 },
                        style: { width: "140px", height: "30px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbol()}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      type="number"
                      value={
                        !isNaN(row.demand) && row.demand !== ""
                          ? roundNumber(unitMoneyWithoutRounding(row.demand), 4)
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(index, "demand", e.target.value)
                      }
                      InputProps={{
                        inputProps: { min: 0.0, step: 0.0001 },
                        style: { width: "140px", height: "30px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbol()}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.period != 1 && (
                      <>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeletePeriod(row.period)}
                        >
                          Delete
                        </Button>
                        &nbsp;
                      </>
                    )}
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => handleSetRowValue(index)}
                    >
                      Set Selected
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <br />
        {isDuplicates ? (
          <Box display="flex" justifyContent="center">
            <Alert severity="warning">
              Duplicate periods detected. Duplicates will be combined on save.
            </Alert>
          </Box>
        ) : undefined}
        <br />
        {/* new grid */}
        <table className="table-input" onMouseLeave={handleDragEnd}>
          <tbody>
            <tr>
              <th className="header-cell" />
              {hours.map((hour) => (
                <th key={`${hour}:00_header`} className="header-cell">
                  {hour}:00
                </th>
              ))}
            </tr>
            {dataMatrix.map((rowData, row) => (
              <tr key={`${months[row]}_row`}>
                <th className="header-cell">{months[row]}</th>
                {rowData.map((cellPeriod, col) => (
                  <td
                    key={`row${row}_col${col}`}
                    className="data-cell"
                    onClick={() => handleCellClick(row, col)}
                    onMouseDown={() => handleDragStart(row, col)}
                    onMouseEnter={() => handleDragOver(row, col)}
                    onMouseUp={handleDragEnd}
                    style={{
                      backgroundColor: periodData.find(
                        (period) => period.period == dataMatrix[row][col]
                      )?.backgroundColor,
                      // add some highlights to the selected cells
                      boxShadow: selectedCells.some(
                        ({ month, hour }) => month == row && hour == col
                      )
                        ? "inset 0 0 0 1000px rgba(63,143,227,.4)"
                        : "none",
                      borderColor: selectedCells.some(
                        ({ month, hour }) => month == row && hour == col
                      )
                        ? "rgba(63,143,227)"
                        : "",
                    }}
                  >
                    {cellPeriod}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

export default DetailedEnergyInput;
