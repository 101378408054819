import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";
import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import introJs from "intro.js/intro";
import Cookie from "js-cookie";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../../contexts/dataContext";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  batteryURL,
  combineBlocksURL,
  depotURL,
  routeEnergyURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import { defaultRouteEnergyInput } from "../../../static/constants/defaultInputs";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import stepInfo from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import { routeEnergyTourOptions } from "../../../static/constants/tourOptions";
import UseAuth from "../../auth/useAuth";
import MRT_DownloadButton from "../../secondary/mrtDownloadButton";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import TourBeacon from "../../secondary/tourBeacon";
import {
  getUnits,
  unitFeet,
  unitMiles,
  unitPerMile,
} from "../../secondary/unitConversions";
import {
  MFM_to_AMPM,
  errorHandler,
  getLabel,
  getLocalData,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import {
  RouteEnergyInputsForm,
  updateIndexDb,
} from "../dialogs/editAnalysisInputs";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import { EnergyDefinitionGraph } from "../graphs/energyDefinitionGraph";
import RouteEnergyGroupEdit from "../tables/routeEnergyGroupEdit";

const STEP_NUMBER = 2;

/**
 * converts a table data row into the format necessary for the material-react-table edits
 */

export const rowDataConversion = (row) => {
  //convert efficiency into correct units
  row.detailed_energy.updated_efficiency = unitPerMile(
    row.detailed_energy.updated_efficiency
  );
  //though not shown in table, still used for several computations
  row.distance = unitMiles(row.distance);
  return row;
};

/**
 * converts a table data row from material-react-table format back into the format used for storage and backend operations
 * @param {JSON} row an individual row of data
 * @param {JSON} [rowSelection={}] the rowSelection state
 */
function rowDataReversion(row, rowSelection = {}) {
  //convert efficiency back
  row.detailed_energy.updated_efficiency = unitMiles(
    row.detailed_energy.updated_efficiency
  );
  //though not shown in table, still used for several computations
  row.distance = unitPerMile(row.distance);
  //since rowDataReversion is only used when storing data in steps, bring checked status up to date as well
  row.checked = Boolean(rowSelection[row.id]);
  return row;
}

export default function RouteEnergy() {
  const [data, setData] = useState([]);
  const [isTourActive, setIsTourActive] = useState(false);
  const [depotLookup, setDepotLookup] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showTable, setShowTable] = useState(0); ///0 for display table, false for display chart
  const viewNameLookup = { 0: "Table", 1: "Chart" };
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [validationErrors, setValidationErrors] = useState({}); //used to display table errors for cells on attempted edit of rows

  const [routeEnergyInputsDialogOpen, setRouteEnergyInputsDialogOpen] =
    useState(false);
  const [routeEnergyInputs, setRouteEnergyInputs] = useState(
    defaultRouteEnergyInput
  );
  const [currentProject, setCurrentProject] = useState();
  const [confirmCombineBlocksDialogOpen, setConfirmCombineBlocksDialogOpen] =
    useState(false);
  const [combineBeforeContinueDialogOpen, setCombineBeforeContinueDialogOpen] =
    useState(false); // pop up that appears before going to next page if there are blocks that can be combined, but haven't yet

  const [anchorEl, setAnchorEl] = useState(null); // represents the location of the "battery sizing inputs" item after clicking the options button
  const [dataFetchError, setDataFetchError] = useState(false);
  const [subheaderContent, setSubheaderContent] = useState([]);

  const originalColumnVisibility = useRef(null); //used to restore column visibility after edit, if an editable column was hidden
  const [columnVisibility, setColumnVisibility] = useState({
    //initially hidden columns
    block_id_original: false,
    endDepot: false,
    "detailed_energy.traction": false,
    "detailed_energy.HVAC": false,
    "detailed_energy.Aux": false,
  });
  const [enableHiding, setEnableHiding] = useState(true); // used to prevent users from hiding editable columns during edits
  /** @type {[MRT.MRT_Row<MRT.TData> | null]} */
  const [editingRow, setEditingRow] = useState(null); //note: had to take control of the editing row in order to handle the visibility column changes
  /** @type {[import("material-react-table").RowSelectionState]} */
  const [rowSelection, setRowSelection] = useState({});
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [groupEditOpen, setGroupEditOpen] = useState(false); //opens the dialog for alternate bulk edit by vehicle model
  const [sim, setSim] = useState({});

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const units = getUnits();

  /**  if there are rows that can be combined in the table
   * @constant
   * @type {boolean}
   */
  const isCombinable = Boolean(
    (currentProject?.type == 4 || //schoolbus project
      currentProject?.type == 6 || //passenger vehicle project
      Cookie.get("input_method") == 4) && //multi-day input method
      data?.length &&
      data[0]?.block_id_original
  );

  /**
   *
   * @param {Array} data
   * @param {"energy" | "energy_calc"} energyKey //energy_calc is for when the monthly_HVAC slider is in play
   */
  function updateSubheader(data, energyKey = "energy") {
    let [avgEfficiency, maxEnergy, minEnergy, sumEnergy] = [
      0,
      data?.[0]?.[energyKey] || 0,
      data?.[0]?.[energyKey] || 0,
      0,
    ];

    data.forEach((row) => {
      avgEfficiency +=
        energyKey == "energy"
          ? row.detailed_energy.updated_efficiency // if not using hvac slider, get the average by summing the default
          : (row.HVAC + row.traction + row.aux) / row.distance; //otherwise, compute the efficiency by calculating value using the selected monthly hvac
      maxEnergy = Math.max(row[energyKey], maxEnergy);
      minEnergy = Math.min(row[energyKey], minEnergy);
      sumEnergy += row[energyKey];
    });

    sumEnergy = Math.round(sumEnergy);
    sumEnergy = roundNumber(sumEnergy, 4 - sumEnergy.toString().length);

    avgEfficiency = avgEfficiency / data.length;
    setSubheaderContent([
      {
        value: roundNumber(avgEfficiency).toLocaleString(),
        label: `Average Efficiency (kWh/${unitLargeAbbr[units]})`,
      },
      {
        value: Math.round(minEnergy).toLocaleString(),
        label: `Minimum ${getLabel("block")} Energy (kWh)`,
      },
      {
        value: Math.round(maxEnergy).toLocaleString(),
        label: `Maximum ${getLabel("block")} Energy(kWh)`,
      },
      {
        value: Math.round(sumEnergy).toLocaleString(),
        label: "Total Daily Energy (kWh)",
      },
    ]);
  }

  useEffect(() => {
    if (data.length) updateSubheader(data);
  }, [data]);

  useEffect(() => {
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        const { data: currentBlocks, input: indexedRouteEnergyInputs } =
          await getLocalData("routeEnergy");

        if (!currentBlocks) {
          setDataFetchError(true);
          return;
        }

        if (indexedRouteEnergyInputs)
          //if the battery sizing inputs were previously set, use the existing values
          setRouteEnergyInputs(indexedRouteEnergyInputs);

        const { data: sim } = await getLocalData("simulation", "data");
        setSim(sim);

        let rowSelection = {};
        currentBlocks.forEach((row, index) => {
          row.id = index; // set the row's Id to be the index of the row, for use in material-react-table edits
          rowDataConversion(row);
          // deprecated row.tableData.checked: left in for original functionality, replaced with row.checked
          if (row?.tableData?.checked || row.checked)
            rowSelection[row.id] = true;
          delete row.tableData; //delete tableData, if there was any
        });

        if (!Object.keys(rowSelection).length)
          //if there are no previously saved selected rows, auto-select all rows
          rowSelection = currentBlocks.reduce(
            (prev, row) => ({ ...prev, [row.id]: true }),
            {}
          );

        setData(currentBlocks);
        setRowSelection(rowSelection);

        //retrieves the project JSON from the localDb
        const { data: currentProject } = await getLocalData("project", "data");
        setCurrentProject(currentProject);

        const headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        let depot_lookup = {};
        //fetches all the depots associated with the selected project
        fetch(`${depotURL}?project_id=${currentProject.id}`, {
          method: "GET",
          headers: headers,
        })
          .then((res) => {
            if (res.ok) {
              return res.json().then(({ data: depots }) => {
                depots.forEach(
                  (depot) => (depot_lookup[depot.id] = depot.name)
                );
                setDepotLookup(depot_lookup);
              });
            }
            //else
            errorHandler(res, snackBarElement);
          })
          .catch((err) => {
            snackBarElement.current.displayToast(
              "Something went wrong in depot fetch",
              "info"
            );
            console.log(String(err));
            setData([]);
            setDepotLookup([]);
            setDataFetchError(true);
          })
          .finally(() => setDataFetchError(true));
      } catch (e) {
        snackBarElement.current.displayToast(
          "Something went wrong in charger fetch in useEffect",
          "warning"
        );
        console.log(e);
        setDataFetchError(true);
      }
    }
    fetchData();
  }, []);

  /** "run block scheduling" button-
   * gathers all the selected input blocks (rows) and
   * sends them to the backend API for processing/scheduling
   * and stores the response (s25d) into indexDb
   * @param {*} event
   */
  const handleBatterySizing = async (event) => {
    event.preventDefault();
    if (!Object.values(rowSelection).some((i) => i)) {
      //technically, this check should never fire, as button is disabled if no rows are selected
      snackBarElement.current.displayToast("No Rows Selected", "warning");
      return;
    }

    setButtonLoading(true);

    //convert data back into correct formatting to send to batterySizing
    const revertedData = JSON.parse(JSON.stringify(data)).map((row) =>
      rowDataReversion(row, rowSelection)
    );

    //filter out non-selected rows, and remove the checked status from those rows (for sending to backend)
    const selectedRows = revertedData.filter((row) => {
      delete row.checked; //delete checked status, so that it doesn't get sent to next page
      return rowSelection[row.id];
    });

    const body = {
      block_schedule: selectedRows,
      ...routeEnergyInputs,
    };

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.route_energy.battery_sizing_analysis}`,
    };

    fetch(batteryURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          response.json().then((responseData) => {
            storeLocalData("battery", { data: responseData });

            //updates the checked row status, so that when it gets saved to indexDb, checked rows aren't lost
            revertedData.forEach(
              (row) => (row.checked = Boolean(rowSelection[row.id]))
            );
            /**
             * overwrite the old routeEnergy indexDb with the current one, so that we know which rows were
             * selected on future pages (fleetChargerSizing and LoadProfile)
             */
            storeLocalData("routeEnergy", {
              data: revertedData,
              input: routeEnergyInputs,
            });

            //Save the data on the backend Body
            const backendBody = {
              id: sim.id, //The simulation ID
              current_page: stepInfo[STEP_NUMBER + 1].route,
              steps: {
                battery: responseData,
                routeEnergy: revertedData,
                input: { routeEnergy: routeEnergyInputs },
                //clear out future pages' backend data
                evAssessment: {},
                financial: {},
                tco: {},
              },
              completed: false,
            };

            //clear out the future pages' frontend data
            partialClearLocalData([
              "fleetSizing",
              "evAssessment",
              "financial",
              "tco",
            ]);

            fetch(simulationURL, {
              method: "PATCH",
              headers: headers,
              body: JSON.stringify(backendBody),
            })
              .then((response) => {
                if (response.ok) {
                  snackBarElement.current.displayToast(
                    `${stepInfo[STEP_NUMBER].label} Analysis Complete`
                  );
                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Error Sending Data to Backend"
                  );
                  setButtonLoading(false);
                }
              })
              .catch((e) => {
                snackBarElement.current.displayToast(
                  "Error Sending Data to Backend",
                  "error"
                );
                console.log("error", e);
                setButtonLoading(false);
              });
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            `Failed to run ${stepInfo[STEP_NUMBER + 1].label} Analysis`
          );
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          `Network Error: Failed to run ${
            stepInfo[STEP_NUMBER + 1].label
          } Analysis`,
          "error",
          5000
        );
        setButtonLoading(false);
      });
  };

  /**
   * should fire when closing EITHER row edit or bulk edit,
   * resets the column visibility to state that it was prior to edit
   */
  function generalEditClose() {
    setEnableHiding(true); //prevents users from hiding editable columns during edits
    setGroupEditOpen(false);
    if (originalColumnVisibility.current != null) {
      //if the column visisbility was automatically altered to include hidden editable columns, restore it to original form
      setColumnVisibility(originalColumnVisibility.current);
      originalColumnVisibility.current = null;
    }
  }

  /**
   * should fire when opening either bulk or row edit,
   * ensures all editable columns are visible, and if they're not,
   * it also saves the original visibility status for when edit is closed
   * @param {MRT.MRT_Row<MRT.TData> | null | undefined} [row] optional param used in row edit change, to determine that the row wasn't just closed
   */
  function generalEditOpen(row = undefined, isBulk = true) {
    if (row !== null || isBulk) setEnableHiding(false); //prevents users from hiding editable columns during edits, use if statement to avoid running when canceling a row edit
    const editableCols = columns.filter((col) => col.enableEditing != false); // != false is important, as field is not defined as true
    if (
      editableCols.some(
        (col) => columnVisibility?.[col.accessorKey] == false
      ) &&
      row !== null
    ) {
      originalColumnVisibility.current = { ...columnVisibility };
      setColumnVisibility((prev) => ({
        ...prev,
        ...editableCols.reduce(
          (colVis, col) => ({ ...colVis, [col.accessorKey]: true }),
          {}
        ),
      }));
    }
  }

  function onEditingRowClose() {
    setValidationErrors({});
    setEditingRow(null);
    generalEditClose();
  }

  /**
   * @param {{ exitEditingMode: () => void, row: import("material-react-table").MRT_Row<never>, table: MRT_TableInstance<never>, values: Record<string & Record<never, never>, any>}} props
   */
  async function onEditingRowSave({ exitEditingMode, row, table, values }) {
    if (
      row.getValue("detailed_energy.updated_efficiency") ==
      roundNumber(row.original.detailed_energy.updated_efficiency)
    ) {
      //checks if the new block Data is the same as the old block, and
      //doesn't send a new PATCH if the data is unaltered
      snackBarElement.current.displayToast(
        "Vehicle efficiency was not altered from original",
        "info"
      );
      return;
    }

    if (Object.values(validationErrors).filter((i) => i).length) {
      //if any rows have invalid data, display message, and stop save
      snackBarElement.current.displayToast("Invalid Input", "error", 5000);
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.fleet_operation.route_energy_analysis}`,
    };

    let newRow = {
      ...row.original,
      detailed_energy: {
        ...row.original.detailed_energy,
        updated_efficiency: row.getValue("detailed_energy.updated_efficiency"),
      },
    };

    const body = { blocks: [rowDataReversion(newRow)] };

    fetch(routeEnergyURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          response.json().then((responseData) => {
            newRow = responseData[0];
            const index = data.findIndex((row) => row.id == newRow.id);
            data[index] = rowDataConversion(newRow); //convert data into a table-readible format
            setData([...data]); //update table state
            storeLocalData("routeEnergy", {
              //save reverted deep copy of original data back to indexDb
              data: JSON.parse(JSON.stringify(data)).map((row) =>
                rowDataReversion(row, rowSelection)
              ),
            });
            //exit editing mode on success
            exitEditingMode();
            onEditingRowClose();
          });
        } else errorHandler(response, snackBarElement, "Failed to update data");
      })
      .catch((err) => {
        console.log(String(err));
        snackBarElement.current.displayToast(
          "Looks like that didn't work",
          "error"
        );
      });
  }

  function handleBulkEditClose() {
    setIsBulkEdit(false);
    generalEditClose();
  }

  async function handleBulkEditSave(e) {
    e.preventDefault();

    let oldData = [];
    let newData = [];

    table.getCoreRowModel().rows.forEach((row) => {
      oldData.push(row.original);
      newData.push({
        ...row.original,
        detailed_energy: {
          ...row.original.detailed_energy,
          updated_efficiency: row.getValue(
            "detailed_energy.updated_efficiency"
          ),
        },
      });
    });

    const alteredRows = newData.filter(
      (row, index) =>
        row.detailed_energy.updated_efficiency !=
        roundNumber(oldData[index].detailed_energy.updated_efficiency)
    );

    if (!alteredRows.length) {
      //if no data was changed from original, display an error message, and return
      snackBarElement.current.displayToast(
        "data was not altered from Original",
        "info"
      );
      return;
    }

    if (Object.values(validationErrors).some((error) => error)) {
      //if any cells have invalid data, display message, and stop save
      snackBarElement?.current?.displayToast(
        "Invalid cell data detected",
        "error",
        5000
      );
      return;
    }

    setButtonLoading(true);

    const body = {
      blocks: alteredRows.map((row) => rowDataReversion(row, rowSelection)),
    };

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.fleet_operation.route_energy_analysis}`,
    };

    fetch(routeEnergyURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          response.json().then((responseData) => {
            const newData = responseData.map(rowDataConversion);

            //overwrite the data elements with the newly computed rows
            newData.forEach((newRow) => {
              const index = data.findIndex((oldRow) => oldRow.id == newRow.id); //todo: you could technically probably make this a binary search tree lookup using IDs, might be worth looking into down the line (keep in mind: Ids might be modified by the combineBlocks function)
              data[index] = newRow;
            });
            snackBarElement.current.displayToast("Update Complete", "success");
            setData([...data]);
            storeLocalData("routeEnergy", {
              data: JSON.parse(JSON.stringify(data)).map(
                (row) => rowDataReversion(row, rowSelection) //deep copy before reverting, so as to not affect the table's render data
              ),
            });
            handleBulkEditClose();
          });
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to update ${stepInfo[STEP_NUMBER].label} Analyses`
          );
      })
      .catch((err) => {
        console.log(String(err));
        snackBarElement.current.displayToast(
          `Network Error: Failed to update ${stepInfo[STEP_NUMBER].label} Analyses`,
          "error",
          5000
        );
      })
      .finally(() => setButtonLoading(false));
  }

  /**
   * Saves group bulkedit
   * @param {SubmitEvent} event
   */
  function handleGroupEditSave(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    //gets the status of the isAllRows toggle, and then deletes the value from the form
    const isAllRows = Boolean(formData.get("isAllRows"));
    formData.delete("isAllRows");
    const formJSON = Object.fromEntries(formData.entries());

    for (const model in formJSON) {
      //if user didn't input any efficiency, remove it from the change
      if (!formJSON[model]) delete formJSON[model];
      //otherwise, convert the new efficiency into a number
      else formJSON[model] = +formJSON[model];
    }

    if (!Object.values(formJSON).length) {
      snackBarElement.current.displayToast(
        "No efficiencies altered",
        "warning",
        5000
      );
      return;
    }
    if (Object.values(formJSON).some((row) => isNaN(row))) {
      snackBarElement.current.displayToast(
        "Invalid number input",
        "error",
        5000
      );
      return;
    }

    table.getCoreRowModel().rows.forEach((row) => {
      //update all rows that have an edited model efficiency
      if (
        row.original.vehicleModel in formJSON &&
        (isAllRows || rowSelection[row.id]) //(if user only wants to alter selected rows) don't update rows that aren't selected
      )
        row._valuesCache["detailed_energy.updated_efficiency"] =
          formJSON[row.original.vehicleModel];
    });

    handleBulkEditSave(event);
  }

  /**
   * handles submission of the "Battery Sizing Inputs"
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const newData = updateIndexDb("routeEnergy", event);
    setRouteEnergyInputs(newData);
    setRouteEnergyInputsDialogOpen(false);
  };

  const handleCombine = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.route_energy.combine_routes}`,
    };

    try {
      const { data: responseData } = await axios.post(
        combineBlocksURL,
        { blocks: data }, // note: don't need to convert data back because the combineRoutes code computes a new updated_efficiency value by dividing total_energy by distance
        { headers }
      );
      responseData.forEach((el) => rowDataConversion(el));
      //update row selection's keys to match those of the newly combined rows
      setRowSelection(
        responseData
          .filter((row) => rowSelection[row.id])
          .reduce(
            (newRowSelection, row) => ({ ...newRowSelection, [row.id]: true }),
            {}
          )
      );
      setData(responseData);
    } catch (e) {
      errorHandler(e, snackBarElement, "Failed to combine rows");
    }
    setConfirmCombineBlocksDialogOpen(false);
    setButtonLoading(false);
  };

  const handleTourStart = () => {
    setIsTourActive(true);
    let tour = introJs();
    setTimeout(
      () =>
        tour
          .setOptions(routeEnergyTourOptions(isCombinable))
          .onexit(() => setIsTourActive(false))
          .start(),
      [100]
    );
  };

  /**
   * defines the column structure of the table
   */
  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          header: getLabel("block_id_original"),
          accessorKey: "block_id_original",
          accessorFn: (row) => row.block_id_original ?? row.blockId,
          visibleInShowHideMenu: data.some((i) => i.block_id_original),
          enableEditing: false,
        },
        {
          header: getLabel("block_id"),
          accessorKey: "blockId",
          enableEditing: false,
        },
        {
          header: "Depot Depart Time",
          accessorKey: "dh_st_time",
          Cell: ({ cell }) => MFM_to_AMPM(cell.getValue()),
          filterFn: (row, columnId, filterValue) =>
            MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
          enableEditing: false,
        },
        {
          header: "Depot Arrive Time",
          accessorKey: "dh_end_time",
          Cell: ({ cell }) => (
            <>
              {MFM_to_AMPM(cell.getValue())}{" "}
              {cell.getValue() >= 1440 ? (
                <Tooltip title="Arrival time is on the next day">
                  {unitWrapper("Next Day", { style: { color: "blue" } })}
                </Tooltip>
              ) : (
                ""
              )}
            </>
          ),
          filterFn: (row, columnId, filterValue) =>
            MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
          enableEditing: false,
        },
        {
          header: "Depot Location",
          accessorKey: "endDepot",
          Cell: ({ cell }) => depotLookup[cell.getValue()],
          enableEditing: false,
          filterVariant: "select",
          filterSelectOptions: Object.entries(depotLookup).map(
            ([value, label]) => ({ value, label })
          ),
          muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } },
        },
        // {
        //   header: "Vehicle ID",
        //   accessorKey: "vehicleModel",
        //   enableEditing: false,
        // },
        {
          header: "Vehicle Type",
          accessorKey: "veh_type",
          enableEditing: false,
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "select",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } }, //sx: { width: "calc(var(--header-veh_type-size)* 1px)" },
        },
        {
          header: "Vehicle Size",
          accessorKey: "size",
          enableEditing: false,
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : cell.getValue() == 14 ? (
              "Transit"
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : rowData.size == 14
              ? "Transit"
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
        },
        {
          header: "Efficiency",
          accessorKey: "detailed_energy.updated_efficiency",
          accessorFn: (row) =>
            roundNumber(row.detailed_energy.updated_efficiency),
          enableHiding, // prevents users from hiding columns during edits
          units: `kWh/${unitLargeAbbr[units]}`, //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper(`kWh/${unitLargeAbbr[units]}`)}
            </>
          ),
          muiEditTextFieldProps: ({ table, row, cell }) => ({
            type: "number",
            InputProps: {
              inputProps: { min: 0.01, step: 0.01, style: { minWidth: "6ch" } }, //minWidth to make sure that the numbers aren't covered by the units of measurment/slider for up to 6 characters
              endAdornment: (
                <InputAdornment position="end">
                  kWh/{unitLargeAbbr[units]}
                </InputAdornment>
              ),
              disableUnderline: false, //adds underline back to bulk edit, to visually differentiate editable cells from non-editable
            },
            error: !!validationErrors[cell.id],
            helperText: validationErrors[cell.id],
            onChange: (e) => {
              row._valuesCache[cell.column.id] = e.target.value;
              if (isBulkEdit) table.setEditingCell(cell);
            },
            onBlur: () =>
              setValidationErrors({
                ...validationErrors,
                [cell.id]: !(cell.getValue() > 0)
                  ? "Must be greater than 0"
                  : undefined,
              }),
          }),
        },
        {
          header: "Traction Energy",
          accessorKey: "detailed_energy.traction",
          accessorFn: (row) => roundNumber(row.detailed_energy.traction),
          enableEditing: false,
          units: "kWh", //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
        },
        {
          header: "HVAC Energy",
          accessorKey: "detailed_energy.HVAC",
          accessorFn: (row) => roundNumber(row.detailed_energy.HVAC),
          enableEditing: false,
          units: "kWh", //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
        },
        {
          header: "Aux Energy",
          accessorKey: "detailed_energy.Aux",
          accessorFn: (row) => roundNumber(row.detailed_energy.Aux),
          enableEditing: false,
          units: "kWh", //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
        },
        {
          header: "Total Energy",
          accessorKey: "detailed_energy.total_energy",
          accessorFn: (row) => roundNumber(row.detailed_energy.total_energy),
          enableEditing: false,
          units: "kWh", //used for exporting table
          Cell: ({ table, row, cell }) =>
            table.getState().editingRow?.id == row.id || isBulkEdit ? (
              "" // if editing the row, hide the total energy column
            ) : (
              <>
                {cell.getValue()} {unitWrapper("kWh")}
              </>
            ),
        },
      ];
    },
    [depotLookup, validationErrors, isBulkEdit, enableHiding]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data,
    columns,
    state: {
      ...materialReactTableOptions().state,
      rowSelection,
      showAlertBanner: Object.values(rowSelection).some((i) => i),
      isLoading: !dataFetchError,
      editingRow,
      columnVisibility,
    },
    // column visibility settings
    onColumnVisibilityChange: setColumnVisibility,
    //selection settings
    enableRowSelection: true,
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
    //data edit settings
    enableEditing: true,
    editDisplayMode: isBulkEdit ? "table" : "row",
    // row edit settings
    onEditingRowChange: (row) => {
      generalEditOpen(row, false);
      setEditingRow(row ? { ...row } : null);
    },
    onEditingRowSave: onEditingRowSave,
    onEditingRowCancel: onEditingRowClose,
    //bulk edit settings
    // onEditingCellChange: onEditingCellChange,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <span style={{ width: "100%" }} />
        {isBulkEdit ? (
          <>
            <Button
              className="btn"
              variant="outlined"
              onClick={() => setGroupEditOpen(true)}
              sx={{ px: 3 }}
            >
              Group Edit
            </Button>
            <Tooltip title="Cancel Edit All">
              {/* todo: implement bulk edit save */}
              <IconButton
                onClick={() => {
                  table
                    .getCoreRowModel()
                    .rows.forEach(
                      (row) =>
                        (row._valuesCache[
                          "detailed_energy.updated_efficiency"
                        ] = roundNumber(
                          row.original.detailed_energy.updated_efficiency
                        ))
                    ); //resets the table's display data to what it was before the edit
                  handleBulkEditClose();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save Edit All">
              <IconButton onClick={handleBulkEditSave}>
                <SaveIcon color="info" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TourBeacon
                onClick={handleTourStart}
                hidden={isTourActive || Boolean(table.getState().editingRow)}
              />
              <Tooltip title="Edit All">
                <IconButton
                  onClick={() => {
                    generalEditOpen();
                    setIsBulkEdit(true);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
        <MRT_DownloadButton
          table={table}
          fileName={stepInfo[STEP_NUMBER].label}
          disabled={!Object.keys(depotLookup).length || !data.length}
        />
      </>
    ),
  });

  const isNavDisabled = isBulkEdit || Boolean(table.getState().editingRow);

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle setRouteEnergyInputs={setRouteEnergyInputs} />
        <Chip
          label={viewNameLookup[showTable]}
          onClick={(e) => setViewAnchorEl(e.currentTarget)}
          sx={{ minWidth: "6rem" }}
        />
        <Menu
          open={Boolean(viewAnchorEl)}
          anchorEl={viewAnchorEl}
          onClose={() => setViewAnchorEl(null)}
          PaperProps={{
            style: { minWidth: viewAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(viewNameLookup).map(([key, label]) => (
            <MenuItem
              key={`ShowTable_${key}_Option`}
              value={key}
              onClick={(e) => {
                updateSubheader(data);
                setShowTable(e.currentTarget.value);
                setViewAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          {showTable == 0 ? (
            <MaterialReactTable table={table} />
          ) : (
            //  creates a graph with all selected rows (if no rows are selected, then graph contains all rows)
            <EnergyDefinitionGraph
              data={
                Object.values(rowSelection).some((i) => i)
                  ? data.filter((row) => rowSelection[row.id])
                  : data
              }
              updateSubheader={updateSubheader}
              sim={sim}
            />
          )}
        </Paper>
      </Container>
      <br />
      <br />
      <Container>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          sx={{ width: "100%", margin: "0 auto" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={stepInfo[STEP_NUMBER - 1].route}
                startIcon={<ArrowBackIosNew />}
                disabled={isNavDisabled}
              >
                Previous Step: {stepInfo[STEP_NUMBER - 1].label}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                id="route-energy-options"
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                disabled={!data.length > 0}
                onClick={(e) =>
                  isCombinable
                    ? setAnchorEl(e.currentTarget)
                    : setRouteEnergyInputsDialogOpen(true)
                }
              >
                {isCombinable ? "Options" : "Battery Sizing Inputs"}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "center",
                }}
                PaperProps={{ style: { minWidth: anchorEl?.clientWidth } }} // makes the dropdown the same size as the button
              >
                <MenuItem
                  onClick={() => {
                    setRouteEnergyInputsDialogOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Battery Sizing Inputs
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setConfirmCombineBlocksDialogOpen(true);
                    setAnchorEl(null);
                  }}
                  disabled={isNavDisabled}
                >
                  Combine {getLabel("blocks")}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <NextPageButton
            id="next-page"
            onClick={
              isCombinable
                ? () => setCombineBeforeContinueDialogOpen(true)
                : handleBatterySizing
            }
            disabled={
              //check that at least 1 selected row is true
              !Object.values(rowSelection).some((i) => i) ||
              isNavDisabled ||
              !accessRights.analysis.create_battery_sizing_analysis
            }
            loading={buttonLoading}
          >
            Run {stepInfo[STEP_NUMBER + 1].label} Analysis
          </NextPageButton>
        </Stack>
      </Container>

      {/* Block Scheduling Inputs Dialog box */}
      <Dialog
        component="form"
        onSubmit={handleSubmit}
        open={routeEnergyInputsDialogOpen}
        onClose={() => setRouteEnergyInputsDialogOpen(false)}
      >
        <DialogTitle>Battery Sizing Inputs</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <RouteEnergyInputsForm
              inputs={routeEnergyInputs}
              accessRights={accessRights}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRouteEnergyInputsDialogOpen(false)}>
            Cancel
          </Button>
          <Button type="submit">Save and Continue</Button>
        </DialogActions>
      </Dialog>

      {/* Edit All dialog for editing efficiencies by vehicle model */}
      <Dialog
        component="form"
        open={groupEditOpen}
        onClose={() => setGroupEditOpen(false)}
        onSubmit={handleGroupEditSave}
        maxWidth="xl"
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Edit Efficiency by Group
          <span style={{ display: "flex", alignItems: "center" }}>
            <Typography>Edit Selected Rows</Typography>
            <Switch name="isAllRows" />
            <Typography>Edit All Rows</Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <RouteEnergyGroupEdit data={data} rowSelection={rowSelection} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupEditOpen(false)}>Cancel</Button>
          <LoadingButton loading={buttonLoading} type="submit">
            Save Group Edit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* confirmation of combine blocks dialog box */}
      <Dialog
        open={confirmCombineBlocksDialogOpen}
        onClose={() => setConfirmCombineBlocksDialogOpen(false)}
      >
        <DialogTitle>Confirm Combination of {getLabel("blocks")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will combine {getLabel("blocks").toLowerCase()} and disable
            charging between them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmCombineBlocksDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleCombine}
            type="submit"
            loading={buttonLoading}
          >
            Combine
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Dialog box that appears when you click "RUN ____ Analysis" */}
      <Dialog
        open={combineBeforeContinueDialogOpen}
        onClose={() => setCombineBeforeContinueDialogOpen(false)}
      >
        <DialogTitle>Confirm Continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your runs have not been combined; are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCombineBeforeContinueDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleBatterySizing}
            type="submit"
            loading={buttonLoading}
          >
            Continue without Combining
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* The loading screen that appears for 3 seconds after the data has been sent to backend */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={buttonLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}
