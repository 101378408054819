import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { multiDepotScheduleURL } from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { multiDayUploadStepInfo } from "../../../static/constants/stepInfo";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import UseAuth from "../../auth/useAuth";
import { MultiDayUploadAnalysisStepper } from "../../secondary/steppers";
import {
  errorHandler,
  getLocalData,
  partialClearLocalData,
  storeLocalData,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import { getSizeDisplay } from "./historicalAnalysis";

const STEP_NUMBER = 3;
const VEHICLES_TO_DAYS_RATIO = 0.7;

const fetchVehicleAssignmentData = async () => {
  try {
    const { data } = await getLocalData("vehicleAssignment", "data");
    if (data && Array.isArray(data)) {
      return data.map((item) => ({
        veh_type: item.veh_type || "",
        size: item.size || "",
        vehicleId: item.vehicleId || "",
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching vehicle data:", error);
    return [];
  }
};

const FleetProjection = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [outlierData, setOutlierData] = useState([]);
  const [openContinueDialog, setOpenContinueDialog] = useState(false);
  const { snackBarElement } = useContext(SnackBarContext);

  const warningRows = tableData
    .filter(
      (row) => row.actualFleetCount / row.num_days >= VEHICLES_TO_DAYS_RATIO
    )
    .map(
      (row) =>
        `${TYPE_STRINGS.VEHICLE_TYPE[row.veh_type]}: ${getSizeDisplay(
          row.veh_type,
          row.size
        )}`
    );

  const fetchFleetProjection = async (selectedData) => {
    const { input, data } = await getLocalData("fleetProjection");

    if (input && selectedData) {
      //todo: vehicle_lookup can probably be combined with vehicle_days_lookup
      // Extracting vehicle counts from input
      const vehicle_lookup = {};
      for (const veh_type_size in input)
        vehicle_lookup[veh_type_size] = {
          actualFleetCount: input[veh_type_size],
        };

      //extract the vehicleDays from the selected historical analysis rows
      // {vehicle_type_size: { vehicle_id: Set(date)}}
      const vehicle_model_vehicle_id_days_lookup = {};

      // create sets of unique days for each vehicle ID
      Object.entries(data).forEach(([veh_type_size, vehicleData]) => {
        if (!vehicle_model_vehicle_id_days_lookup[veh_type_size])
          vehicle_model_vehicle_id_days_lookup[veh_type_size] = {};
        Object.values(vehicleData).forEach(
          ({ energy_results, vehicle_usage: { max_usage } }) => {
            vehicle_lookup[veh_type_size].max_usage = max_usage;
            Object.values(energy_results).forEach(({ blocks }) =>
              blocks.forEach((row) => {
                if (
                  selectedData[row.blockId] &&
                  vehicle_model_vehicle_id_days_lookup[veh_type_size][
                    row.vehicle_id
                  ]
                )
                  vehicle_model_vehicle_id_days_lookup[veh_type_size][
                    row.vehicle_id
                  ].add(row.date);
                else if (selectedData[row.blockId])
                  //first appearance of this vehicle ID
                  vehicle_model_vehicle_id_days_lookup[veh_type_size][
                    row.vehicle_id
                  ] = new Set().add(row.date);
              })
            );
          }
        );
      });
      const vehicle_days_lookup = {};
      // sum up the number of days for each vehicle model
      Object.entries(vehicle_model_vehicle_id_days_lookup).forEach(
        ([veh_type_size, vehicle_id_days_set]) =>
          (vehicle_days_lookup[veh_type_size] = Object.values(
            vehicle_id_days_set
          ).reduce((sum_days, curr_set) => sum_days + curr_set.size, 0))
      );

      return { vehicle_lookup, vehicle_days_lookup };
    } else return {};
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      document.querySelector("#App").scrollIntoView(); //scrolls to top of app

      const { data: selectedData } = await getLocalData(
        "historicalAnalysisData",
        "data"
      );
      if (!selectedData) {
        // if the previous page hasn't been completed yet, don't show anything here
        snackBarElement?.current?.displayToast(
          "No data found",
          "warning",
          5000
        );
        setIsLoading(false);
        return;
      }
      const fetchedVehicleData = await fetchVehicleAssignmentData();
      const { vehicle_lookup = {}, vehicle_days_lookup = {} } =
        await fetchFleetProjection(selectedData);

      const groupedData = {};
      fetchedVehicleData.forEach(({ veh_type, size }) => {
        const key = `${veh_type}_${size}`;
        if (!groupedData[key]) {
          groupedData[key] = { veh_type, size, vehiclesLogged: 0 };
        }
        groupedData[key].vehiclesLogged += 1;
      });

      const formattedData = Object.values(groupedData).map((item) => {
        const type_size_key = `${TYPE_STRINGS.VEHICLE_TYPE[item.veh_type]}-${
          item.size
        }`;

        return {
          ...item,
          ...vehicle_lookup[type_size_key],
          num_days: vehicle_days_lookup[type_size_key] || 0,
        };
      });

      setTableData(formattedData);
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleFleetCountChange = (index, change) => {
    const newData = [...tableData];
    const currentRow = newData[index];

    const newFleetCount = Math.max(1, currentRow.actualFleetCount + change);

    currentRow.actualFleetCount = newFleetCount;

    setTableData(newData);
  };

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => {
      const vehTypeFilterOptionsSet = new Set(
        tableData.map((row) => +row.veh_type)
      ); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          accessorKey: "veh_type",
          header: "Type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "select",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          filterFn: "equals", // prevents fuzzy matches like "Box Truck" (2) = "Tractor Trailer" (12)
        },
        {
          id: "size",
          header: "Size",
          accessorFn: (row) => getSizeDisplay(row.veh_type, row.size),
        },
        {
          accessorKey: "vehiclesLogged",
          header: "Total Vehicles",
          Cell: ({ cell }) => cell.getValue().toLocaleString(),
        },
        {
          accessorKey: "max_usage",
          header: "Maximum Daily Vehicles",
          Cell: ({ cell }) => cell.getValue().toLocaleString(),
        },
        {
          accessorKey: "num_days",
          header: "Vehicle Days",
          Cell: ({ cell }) => cell.getValue().toLocaleString(),
        },
        {
          accessorKey: "actualFleetCount",
          header: "Actual Number of Vehicles in Fleet",
          Cell: ({ row }) => {
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  sx={{ marginRight: "5px" }}
                  onClick={() => handleFleetCountChange(row.index, -1)}
                  disabled={row.original.actualFleetCount <= 1}
                >
                  -
                </Button>
                <TextField
                  variant="outlined"
                  size="small"
                  value={row.original.actualFleetCount}
                  inputProps={{ readOnly: true }}
                  sx={{ width: "60px", textAlign: "center" }}
                />
                <Button
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => handleFleetCountChange(row.index, 1)}
                >
                  +
                </Button>
              </Box>
            );
          },
        },
      ];
    },
    [tableData]
  );

  const createNewLocationSummary = async () => {
    const { data } = await getLocalData("fleetProjection", "data");

    // NOTE: ALSO DONE IN VEHICLE ASSIGNMENT PAGE
    const fleetSizeMap = tableData.reduce(
      (mem, { veh_type, size, actualFleetCount }) => {
        const key = `${TYPE_STRINGS.VEHICLE_TYPE[veh_type]}-${size}`;
        mem[key] = actualFleetCount;
        return mem;
      },
      {}
    );

    setOutlierData(data);

    const selectedDataWrapper = await getLocalData(
      "historicalAnalysisData",
      "data"
    );
    const selectedData = selectedDataWrapper?.data || {};

    const summary = {};

    // Iterate over all bus classes in the sample output
    Object.keys(data).forEach((busClass) => {
      const busClassData = data[busClass];
      summary[busClass] = {};

      Object.keys(busClassData).forEach((location) => {
        const locationData = busClassData[location];
        const { energy_results, gumbel_info, fixed } = locationData;

        // Initialize non_outliers and outliers arrays for the location
        summary[busClass][location] = {
          non_outliers: [],
          outliers: [],
          energy_results,
          gumbel_info,
          fixed,
          schedule_size: fleetSizeMap[busClass],
        };

        const locationBlockIds = new Set();
        Object.values(energy_results).forEach((result) => {
          result.blocks.forEach((block) => {
            locationBlockIds.add(block.blockId);
          });
        });

        // Categorize blocks into non_outliers and outliers
        locationBlockIds.forEach((blockId) => {
          if (selectedData[blockId]) {
            summary[busClass][location].non_outliers.push(blockId);
          } else {
            summary[busClass][location].outliers.push(blockId);
          }
        });
      });
    });
    return { newSummary: { vehicles: summary }, fleetSizeMap };
  };

  const sendDataToBackend = async (newSummary, fleetSizeMap) => {
    try {
      const response = await fetch(multiDepotScheduleURL, {
        method: "POST",
        headers: {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=1.0.0`,
        },

        body: JSON.stringify(newSummary),
      });

      if (!response.ok) {
        errorHandler(response, snackBarElement, "Error computing schedule");
        setIsLoading(false);
        return;
      }

      const result = await response.json();
      storeLocalData("fleetProjection", { input: fleetSizeMap });
      await storeLocalData("scheduleGeneration", { data: result });
      navigate(multiDayUploadStepInfo[STEP_NUMBER + 1].route);
    } catch (error) {
      snackBarElement.current.displayToast(
        "Network error: failed to compute schedule",
        "error",
        5000
      );
      console.log("Error sending fleet data:", error);
      setIsLoading(false);
    }
  };

  const handleContinue = async () => {
    if (!outlierData) {
      snackBarElement.current.displayToast(
        "OutlierData not available",
        "error",
        5000
      );
      return;
    }

    try {
      setIsLoading(true);
      //clear out the future pages' frontend data
      partialClearLocalData(["scheduleGeneration"]);
      const { newSummary, fleetSizeMap } = await createNewLocationSummary();

      sendDataToBackend(newSummary, fleetSizeMap);
    } catch (error) {
      snackBarElement.current.displayToast(
        "Failed to retrieve fleet projection data",
        "error",
        5000
      );
      setIsLoading(false);
    }
  };

  const handleContinueDialogOpen = () => {
    setOpenContinueDialog(true);
  };

  const handleContinueDialogClose = () => {
    setOpenContinueDialog(false);
  };

  return (
    <div
      style={{
        margin: "20px auto",
        padding: "20px",
        width: "95%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <br />
      <br />
      <MultiDayUploadAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {multiDayUploadStepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
        {warningRows.length ? (
          <Alert severity="warning" sx={{ width: "150%" }}>
            The following rows have limited data, which may impact the accuracy
            of the projected schedule.
            <br />
            {warningRows.join(", ")}
          </Alert>
        ) : (
          <Alert severity="info" sx={{ width: "150%" }}>
            The number of vehicles in this analysis is based on the data
            provided. If this doesn’t match your actual fleet size, you can
            proceed with the current set or update the Actual Number of Vehicles
            to better reflect reality.
          </Alert>
        )}
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <MaterialReactTable
          {...materialReactTableOptions()}
          columns={columns}
          data={tableData}
          enableEditing={false}
          enableRowSelection={false}
        />
      </Container>
      <br />
      <br />
      <Container>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="outlined"
            className="btn"
            sx={{ width: "95%" }}
            component={Link}
            to={multiDayUploadStepInfo[STEP_NUMBER - 1].route}
            startIcon={<ArrowBackIosNew />}
          >
            Back to {multiDayUploadStepInfo[STEP_NUMBER - 1].label}
          </Button>
          <NextPageButton
            sx={{ width: "95%" }}
            onClick={handleContinueDialogOpen}
            loading={isLoading}
          >
            Continue to {multiDayUploadStepInfo[STEP_NUMBER + 1].label}
          </NextPageButton>
        </Stack>
      </Container>

      {/* Continue Confirmation Dialog */}
      <Dialog open={openContinueDialog} onClose={handleContinueDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Box>
            <p>
              In the next step, we’ll use the selected trips and actual fleet
              size to create a representative daily schedule based on patterns
              from your historical data. This schedule will help design a system
              that meets operational needs while ensuring future usage stays
              within its limits. Any unselected trips will not be included.
            </p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContinueDialogClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              handleContinue();
              handleContinueDialogClose();
            }}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default FleetProjection;
