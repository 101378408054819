import AcUnit from "@mui/icons-material/AcUnit";
import AirportShuttle from "@mui/icons-material/AirportShuttle";
import ElectricRickshawIcon from "@mui/icons-material/ElectricRickshaw";
import EvStation from "@mui/icons-material/EvStation";
import LocalFlorist from "@mui/icons-material/LocalFlorist";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Star from "@mui/icons-material/Star";
import WbSunny from "@mui/icons-material/WbSunny";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  divIcon,
  latLngBounds,
  Map,
  MarkerCluster,
  Point,
  point,
} from "leaflet";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  LayersControl,
  MapContainer,
  Tooltip as MapTooltip,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import { Link } from "react-router-dom";

import { DataContext } from "../../../contexts/dataContext";
import FallIcon from "../../../static/images/fall-icon.png";
import MarkerClusterGroup from "../../secondary/MarkerCluster.tsx";
import { MFM_to_AMPM, roundNumber, unitWrapper } from "../../utils";
import DepotResourceIcon from "../depotResourceIcon";

const initialLayerIndex = 1;

/**
 * @type {{label: string, fn: Function, lower_bound: number, upper_bound: number }[]}
 */
const clusterFilterList = [
  {
    label: "Peak Hourly Power",
    fn: (cluster) => {
      const clusteredFullPowerArrays = cluster.getAllChildMarkers().map(
        (pin) => pin.options.designCase?.full_power_hourly ?? Array(24).fill(0) //use an array of 0s in the scenario there isn't a preferred design case for depot
      );
      //sum up all the individual full power hours, to find the Max sum hour
      const sum_full_power_hourly = Array(24)
        .fill()
        .map((_v, i) =>
          clusteredFullPowerArrays.reduce(
            (sum_power_hour, current_full_power_hourly) =>
              sum_power_hour + current_full_power_hourly[i],
            0
          )
        );
      return roundNumber(Math.max(...sum_full_power_hourly), 0);
    },
    lower_bound: 100,
    upper_bound: 1000,
  },
  {
    label: "Number of Depots", //label is the displayed name in the layer popup
    fn: (cluster) => cluster.getChildCount(), //the function that computes the display value
    lower_bound: 10, //the display value boundary for color change from green to yellow
    upper_bound: 100, //the display value boundary for color change from yellow to orange
  },
  {
    label: "Number of Chargers",
    fn: (cluster) =>
      cluster
        .getAllChildMarkers()
        .reduce(
          (sum, row) => sum + (row.options.designCase?.num_chargers ?? 0),
          0
        ),
    lower_bound: 10,
    upper_bound: 100,
  },
  {
    label: "Number of Vehicles",
    fn: (cluster) =>
      cluster
        .getAllChildMarkers()
        .reduce(
          (sum, row) => sum + (row.options.designCase?.ev_fleet_size ?? 0),
          0
        ),
    lower_bound: 10,
    upper_bound: 100,
  },
];

/**
 *
 * @param {Object} props
 * @param {{primary: Object, all: Object}} props.designCaseLookups
 * @param {{depot_id: Simulation}} [props.simulationLookup]
 * @param {Object[]} props.coordinates list of pins; coordinates of markers for the map
 * @param {number} props.coordinates[].latitude
 * @param {number} props.coordinates[].longitude
 * @param {string} props.coordinates[].name for tooltip title
 */
export default function DepotMap({
  projectLookup,
  simulationLookup,
  designCaseLookups,
  coordinates,
  vehicleList,
  tariffList,
}) {
  const [selectedFeederId, setSelectedFeederId] = useState("");
  /** @type {[Map]} */
  const [map, setMap] = useState(null);
  const { organizationMemo } = useContext(DataContext);
  const coordinatesFiltered = useMemo(
    () =>
      coordinates.filter(
        (coordinate) =>
          (!organizationMemo?.selectedOrg ||
            coordinate.organization_id == organizationMemo.selectedOrg) &&
          (!selectedFeederId || coordinate.feeder_id == selectedFeederId)
      ),
    [coordinates, organizationMemo, selectedFeederId]
  );

  const feeder_options = useMemo(
    () =>
      Array.from(
        new Set(coordinates.map((depot) => depot.feeder_id).filter(Boolean))
      ),
    [coordinates]
  );

  /**
   *
   * @param {MarkerCluster} cluster
   * @param {Number} clusterFilterListIndex
   */
  const createClusterIcon = (cluster, clusterFilterListIndex) => {
    const displayValue = clusterFilterList[clusterFilterListIndex].fn(cluster);

    //based off defaultIconCreateFunction (https://github.com/Leaflet/Leaflet.markercluster/blob/15ed12654acdc54a4521789c498e4603fe4bf781/src/MarkerClusterGroup.js#L542)
    let className = "marker-cluster-";
    if (displayValue < clusterFilterList[clusterFilterListIndex].lower_bound)
      className += "small";
    else if (
      displayValue < clusterFilterList[clusterFilterListIndex].upper_bound
    )
      className += "medium";
    else className += "large";

    return divIcon({
      html: `<div><span>${displayValue || "-"}</span></div>`,
      className: `marker-cluster ${className}`, // uses combination of default and custom marker-cluster styles
      iconSize: point(70, 70, true),
    });
  };

  const center = coordinatesFiltered
    .reduce(
      //calculates the average lat/long value, for the center of the map
      ([sum_latitude, sum_longitude], { latitude, longitude }) => [
        sum_latitude + latitude,
        sum_longitude + longitude,
      ],
      [0, 0]
    )
    .map((value) => value / coordinatesFiltered.length);

  let bounds = latLngBounds(); //init boundaries, so that map zooms to fit the coordinates
  coordinatesFiltered.forEach(
    ({ latitude, longitude }) => bounds.extend([latitude, longitude]) //calculate the north-eastern-most and south-western most boundaries
  );
  if (!Object.keys(bounds).length) {
    //if there were no points to focus/zoom in on, then default to the world map
    bounds.extend([-85, -175]);
    bounds.extend([85, 175]);
  }

  const pins = coordinatesFiltered.map((pointData, index) => {
    // Define the fields to display in the popup
    const fieldsToDisplayBig = ["address1", "address2"];
    const fieldsToDisplaySmall = ["city", "state", "country"];

    const AddressComponent = () => (
      <>
        {/* the address */}
        {fieldsToDisplayBig.map((key) => (
          <div style={{ fontSize: "1.25em" }} key={key}>
            {pointData?.[key]?.trim?.() || pointData?.[key]}
          </div>
        ))}
        {fieldsToDisplaySmall.map((key) => (
          <div key={key}>{pointData?.[key]?.trim?.() || pointData?.[key]}</div>
        ))}
        <div>
          {pointData?.feeder_id && `Feeder: ${pointData.feeder_id.trim()}`}
        </div>
      </>
    );

    //only used on depots with a electrification milestones
    const designCase = designCaseLookups.primary[pointData.id];
    const maxHourlyPower = designCase && designCase.max_full_power;

    /**
     * @type {import("chart.js").ChartOptions}
     */
    const barChartOptions = {
      responsive: true,
      scales: {
        x: {
          grid: { offset: false },
          ticks: {
            callback: (val) =>
              val == 12
                ? MFM_to_AMPM(val * 60)
                : val % 6 == 0 && val != 0
                ? ""
                : null,
          },
        },
        y: {
          title: { display: true, text: "kW" },
          ticks: { display: false },
          grid: { display: false },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          position: "nearest",
          callbacks: {
            title: (value) => MFM_to_AMPM(value[0].label * 60),
            label: (value) => `${Math.round(value.raw)} kW`,
          },
        },
        annotation: {
          annotations: {
            line1: {
              type: "line",
              yMax: maxHourlyPower,
              yMin: maxHourlyPower,
              borderColor: "khaki",
            },
          },
        },
      },
    };

    return (
      <Marker
        designCase={designCase}
        key={`marker_${pointData.id}_designCase_${Boolean(designCase)}`} // add boolean of design case to force rerender of marker clusters when design case changes
        position={[pointData.latitude, pointData.longitude]}
      >
        <MapTooltip>
          <div style={{ textAlign: "left" }}>
            <big>
              <b>{pointData.name}</b>
              <br />
              Fleet: {projectLookup?.[pointData?.project_id]?.name}
            </big>
          </div>
        </MapTooltip>
        <Popup minWidth={200} maxWidth={1000}>
          {/* Display the name */}
          <Typography
            fontSize="1.5em"
            display="flex"
            justifyContent="space-between"
            style={{ marginBottom: 0 }}
          >
            <span>
              <b>{pointData.name}&nbsp;&nbsp;&nbsp;</b>
              <br />
              <Link
                to={`/simulations?depotId=${pointData.id}`}
                style={{ fontSize: "0.75em" }}
              >
                Go to analyses
              </Link>
            </span>
            <span style={{ textAlign: "right" }}>
              {projectLookup?.[pointData?.project_id]?.name}
              <br />
              {designCase
                ? ""
                : unitWrapper(
                    simulationLookup?.[pointData.id]
                      ? "No Electrification Target Set"
                      : "No Analyses Exist",
                    {
                      style: { color: "orange", fontSize: "0.75em" },
                    }
                  )}
            </span>
          </Typography>
          <Divider sx={{ borderColor: "#64C8FF", mb: 1 }} />
          {designCase ? (
            <>
              <Grid container>
                <Grid item xs={4}>
                  <AddressComponent />
                </Grid>
                <Grid item xs={4} px={1}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pl: "8px",
                    }}
                  >
                    <span>
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.ev_fleet_size}
                      </span>
                      <br />
                      Vehicle
                      {designCase.ev_fleet_size == 1 ? "" : "s"}
                      <br />
                      <br />
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.unique_models.length}
                      </span>
                      <br />
                      Vehicle Type
                      {designCase.unique_models.length == 1 ? "" : "s"}
                    </span>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ElectricRickshawIcon
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{
                          fontSize: "3em",
                          mr: 2,
                          mb: "-0.25em",
                          mt: "0.25em",
                        }}
                      />
                      <AirportShuttle
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{ fontSize: "3em", ml: "-2em", mb: "-0.25em" }}
                      />
                      <LocalShippingIcon
                        className="rotate-icon-4"
                        color="disabled"
                        sx={{ fontSize: "3em", mr: 1.5, mb: "0.25em" }}
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={4} px={1}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      overflow: "hidden",
                      pl: "8px",
                      mr: -1,
                    }}
                  >
                    <span>
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.num_chargers}
                      </span>
                      <br />
                      Charger{designCase.num_chargers == 1 ? "" : "s"}
                      <br />
                      <br />
                      <span style={{ fontSize: "1.5em" }}>
                        {designCase.charger_models?.length > 1 ? (
                          "Multiple"
                        ) : (
                          <>
                            {designCase.charger_models?.[0]?.rating ??
                              designCase.charger_model.rating}
                            &nbsp;
                            {unitWrapper("kW")}
                          </>
                        )}
                      </span>
                      <br />
                      {designCase.charger_models?.length > 1
                        ? "Ratings"
                        : "Rating"}
                    </span>
                    <EvStation
                      className="rotate-icon-4"
                      color="disabled"
                      sx={{ fontSize: "8em", py: "0.0625em" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <br />
              <Stack direction="row" spacing={1}>
                <Paper elevation={3} sx={{ minWidth: "25em", pr: "1em" }}>
                  <Box
                    display="flex"
                    textAlign="center"
                    justifyContent="center"
                  >
                    Hourly Load Profile for Peak Day
                  </Box>
                  <Bar
                    data={{
                      labels: Array(24)
                        .fill()
                        .map((_v, i) => i),
                      datasets: [{ data: designCase.full_power_hourly }],
                    }}
                    options={barChartOptions}
                  />
                </Paper>
                <Paper elevation={3} component={Grid} container minWidth="25em">
                  <Grid item xs={12} sx={{ maxHeight: "1em", pl: "0.5em" }}>
                    1-Minute Peak Day
                  </Grid>
                  <Grid item xs={6} textAlign="center" alignContent="center">
                    <Typography style={{ margin: 0, fontSize: "1.5em" }}>
                      {Math.round(designCase.max_full_power).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kW")}
                    </Typography>
                    <Typography variant="body2" style={{ margin: 0 }}>
                      Power
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="center" alignContent="center">
                    <Typography style={{ margin: 0, fontSize: "1.5em" }}>
                      {Math.round(
                        designCase.full_power_hourly.reduce(
                          (sum, curr) => sum + curr,
                          0
                        )
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kWh")}
                    </Typography>
                    <Typography variant="body2" style={{ margin: 0 }}>
                      Energy Day
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ borderColor: "#64C8FF", mb: 0.1 }} />
                  </Grid>
                  <Grid item xs={12} sx={{ maxHeight: "1em", pl: "0.5em" }}>
                    Seasonal 15-Minute Peak and Average
                  </Grid>
                  {[
                    <AcUnit />,
                    <LocalFlorist />,
                    <WbSunny />,
                    <img src={FallIcon} height="24px" width="24px" />,
                  ].map((icon, index) => (
                    <Grid
                      item
                      xs={3}
                      key={`season_${index}`}
                      textAlign="center"
                    >
                      {icon}
                      <br />
                      {Math.round(
                        designCase.seasonal_load_profile[index].peak_power
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kW")}
                      <br />
                      {Math.round(
                        designCase.seasonal_load_profile[index].average_day
                      ).toLocaleString()}
                      &nbsp;
                      {unitWrapper("kWh")}
                    </Grid>
                  ))}
                </Paper>
              </Stack>
            </>
          ) : (
            <>
              <Grid container alignItems="stretch" spacing={1}>
                <Grid item xs={simulationLookup?.[pointData.id] ? 5 : 9}>
                  <AddressComponent />
                </Grid>
                <Grid
                  item
                  xs={simulationLookup?.[pointData.id] ? 5 : 1}
                  component={
                    simulationLookup?.[pointData.id] ? Paper : undefined
                  }
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ px: 1, mt: 1 }}
                >
                  {simulationLookup?.[pointData.id] ? (
                    <span>
                      <span style={{ color: "#007FFF", fontSize: "2em" }}>
                        {simulationLookup[pointData.id].num_complete}
                      </span>
                      <span style={{ fontSize: "1.5em" }}>&nbsp;of&nbsp;</span>
                      <span style={{ color: "#007FFF", fontSize: "2em" }}>
                        {simulationLookup[pointData.id].num_analyses}
                      </span>
                      <br />
                      Analyses&nbsp;completed
                    </span>
                  ) : undefined}
                </Grid>
                <Grid item xs={2}>
                  <Paper
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <DepotResourceIcon
                      vehicleList={vehicleList}
                      tariffList={tariffList}
                      depot_id={pointData.id}
                      alignItems="center"
                      direction="column"
                      spacing={1}
                      py={1}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <br />
              Electrification Milestones can be set by selecting{" "}
              <Star style={{ verticalAlign: "middle" }} /> next to a<br />
              completed and locked analysis
            </>
          )}
        </Popup>
      </Marker>
    );
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <TextField
          size="small"
          variant="outlined"
          value={selectedFeederId}
          onChange={(e) => setSelectedFeederId(e.target.value)}
          sx={{ width: "25ch", textAlign: "start", pb: 2 }}
          select
          SelectProps={{
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
            displayEmpty: true,
          }}
        >
          <MenuItem value="">{unitWrapper("Filter by Feeder")}</MenuItem>
          {feeder_options.map((feeder_id) => (
            <MenuItem key={feeder_id} value={feeder_id}>
              {feeder_id}
            </MenuItem>
          ))}
        </TextField>
        {map ? <MapTitle map={map} /> : <span />}
      </Box>
      <Paper elevation={3}>
        {/* NOTE: this was memoized in the react-leaflet external state example, but that seems unnecessary for this implementation. */}
        <MapContainer center={center} bounds={bounds} ref={setMap}>
          <TileLayer
            // attribution text
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* add a filter control scheme as a leaflet layer control */}
          <LayersControl position="topright">
            {clusterFilterList.map(({ label }, index) => (
              <LayersControl.BaseLayer //use baseLayer (radio) instead of overlay (checkbox)
                checked={index == initialLayerIndex}
                key={`layer_${label}`}
                name={label}
              >
                {/* Okay... so, the docs on react marker cluster group aren't great, so you'll probably have to use these instead:
                https://github.com/Leaflet/Leaflet.markercluster#all-options*/}
                <MarkerClusterGroup
                  singleMarkerMode
                  maxClusterRadius={90} //the max distance, in pixels, that clusters can be from each other before combining
                  spiderfyDistanceMultiplier={2} //increases the length of the "click on cluster while at max zoom" lines by a factor of n
                  iconCreateFunction={(cluster) => {
                    if (cluster.bindTooltip)
                      cluster.bindTooltip(`<big><b>${label}<b></big>`, {
                        direction: "top",
                        offset: new Point(0, -5),
                      });
                    return createClusterIcon(cluster, index);
                  }}
                >
                  {pins}
                </MarkerClusterGroup>
              </LayersControl.BaseLayer>
            ))}
          </LayersControl>
        </MapContainer>
      </Paper>
    </>
  );
}

/**
 * changes the header on header change without rerendering the leaflet map
 * @param {Object} param0
 * @param {Map} param0.map
 */
function MapTitle({ map }) {
  const [label, setLabel] = useState(
    clusterFilterList[initialLayerIndex].label
  );
  const onBaseLayerChange = useCallback((e) => setLabel(e.name), [map]);

  useEffect(() => {
    map.on("baselayerchange", onBaseLayerChange);

    return () => map.off("baselayerchange", onBaseLayerChange);
  }, [map, onBaseLayerChange]);

  return (
    <Typography variant="h6" display="flex" alignItems="center">
      {label}
    </Typography>
  );
}
