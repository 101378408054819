import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { forwardRef, useImperativeHandle, useState } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * This function displays a temporary toast message in the top-right of the site
 * @callback displayToast
 * @param {import("react").ReactNode|import("react").ReactNode[]} [context="No Message Provided"] the toast message, use arrays for breaklines
 * @param {"success"|"warning"|"info"|"error"} [severity="success"] The type of toast message to be displayed. Default is "success"
 * @param {number} [duration=3000] the time, in milliseconds, the message is displayed. Default is 3000
 * @param {import("@mui/material").SnackbarProps} snackbarProps optional props
 */

/**
 * A SnackbarAlert that doesn't need the values set in useStates in every single folder,
 * but instead accepts the values at the time its called
 */
const SnackbarAlert = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({
    context: "No Message Provided",
    severity: "success",
    duration: 3000,
    secondaryToastMargin: "5%",
  });
  /** @type {[import("@mui/material").SnackbarProps]} */
  const [snackbarProps, setSnackbarProps] = useState({});

  //todo: make the third argument an "options" object that can accept multipl different values (i.e. forceClearSnackBar and secondaryToastNumber)
  /** @type {displayToast} */
  const displayToast = (
    context,
    severity = "success",
    duration = 3000,
    snackbarProps = {}
  ) => {
    const secondaryToastMargin = "5%";
    // secondaryToastNumber
    // ? secondaryToastNumber * 10 + "%"
    // : "5%";
    if (duration == 0) setOpen(false);
    else {
      setAlertContent({ context, severity, duration, secondaryToastMargin });
      setOpen(true);
      setSnackbarProps(snackbarProps);
    }
  };

  useImperativeHandle(ref, () => ({
    displayToast: displayToast,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={alertContent.duration ?? 1000}
      onClose={handleClose}
      {...snackbarProps}
      sx={{
        marginTop: alertContent.secondaryToastMargin,
        zIndex: 1500,
        width: "25%",
        ...snackbarProps.sx,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
        ...snackbarProps.anchorOrigin,
      }}
    >
      <Alert
        onClose={handleClose}
        severity={alertContent.severity}
        {...snackbarProps?.AlertProps}
        sx={{ fontSize: "1em", ...snackbarProps?.AlertProps?.sx }}
      >
        {Array.isArray(alertContent.context)
          ? alertContent.context.map((x) => (
              <>
                {x}
                <br />
              </>
            ))
          : alertContent.context}
      </Alert>
    </Snackbar>
  );
});

export default SnackbarAlert;
