import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Skeleton,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { emissionsURL } from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { fetchCountries, fetchStates } from "../utils";

//todo: consider making these props into an mui theme instead
/** props applied to all textfield inputs in dialog boxes */
const dialogTextFieldProps = { size: "small", required: true };

/** props applied to all textfield DROPDOWN/SELECT inputs in dialog boxes */
const dialogTextFieldSelectProps = {
  ...dialogTextFieldProps,
  variant: "outlined",
  fullWidth: true,
  select: true,
  SelectProps: { MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } } },
};

/** props applied to all grid items in dialog boxes*/
const dialogGridItemProps = { item: true, xs: 6, md: 3 };

/** props applied to all grid item labels in dialog boxes */
const dialogGridLabelProps = {
  ...dialogGridItemProps,
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
};

/**
 * the edit/add emissions model dialog box
 * @param {Object} props
 * @param {{dialogView: Number, modelState: Object[]}} props.state dialog is open if dialogView === 3, modelState contains the state of the new/edit emission model
 * @param {function} props.setState
 * @param {Object[]} props.allEmissions contains all the emissions models in the table
 * @param {*} props.resetToggle todo: remove this once merged with branch 316
 * @param {*} props.setResetToggle
 */
export default function MasterDataEmissionsDialog({
  state,
  setState,
  allEmissions,
  setAllEmissions,
}) {
  const [loading, setLoading] = useState(false);
  /** @type {[{name: String, iso2: String, phone_code: String, emoji: String}[]]} */
  const [countries, setCountries] = useState([]);
  /** @type {[{name: String, state_code: String}[]]} */
  const [states, setStates] = useState([]);

  const { snackBarElement } = useContext(SnackBarContext);

  const emissionModel = state?.modelState;
  /** determines if the user is in Edit mode (true) or add mode (false) */
  const isEditMode = Boolean(emissionModel?.id);

  useEffect(() => {
    async function fetchData() {
      const countries = await fetchCountries(snackBarElement);
      setCountries(countries);
    }
    fetchData();
  }, []);

  useEffect(() => {
    /** fires on dialog box open, if in edit mode */
    async function fetchData() {
      const iso2 = countries?.find(
        (x) => x?.name === state?.modelState?.country
      )?.iso2;
      const states = await fetchStates(iso2, snackBarElement);
      setStates(states);
    }

    if (state?.dialogView === 3 && state?.modelState?.country) {
      fetchData();
    }
  }, [state?.dialogView]);

  /**
   * closes the "create/edit a new model" dialog box
   */
  const handleClose = () => {
    setState({ ...state, dialogView: 0 });
    setLoading(false);
    setTimeout(() => {
      //wait for the dialog box to finish fading before clearing out the data
      setStates([]);
    }, [400]);
  };

  /**
   * creates a new Emission, and closes the dialog box
   * @param {SubmitEvent} event
   */
  const handleCreate = (event) => {
    event.preventDefault();
    setLoading(true);
    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    fetch(emissionsURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newDataJSON),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(({ data: newEmissions }) => {
            setAllEmissions([newEmissions, ...allEmissions]);
            snackBarElement.current.displayToast("Emission Model Created");
            handleClose();
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to creating emission model"
          );
        }
      })
      .catch((error) => {
        console.log(newDataJSON);
        snackBarElement.current.displayToast(
          "Error Creating Emission Model",
          "error"
        );
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Updates a charger row, and sends the updated object to backend in PATCH request
   * @param {SubmitEvent} event
   */
  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateDataForm = new FormData(event.target);
    const updateDataJSON = Object.fromEntries(updateDataForm.entries());

    const originalData = allEmissions.find(
      (emission) => emission?.id == emissionModel?.id
    );

    if (
      Object.entries(updateDataJSON).every(
        ([key, value]) => String(originalData?.[key]) == value
      )
    ) {
      //if data is unaltered, then display an info card, and return
      snackBarElement.current.displayToast(
        "Data was not altered from Original",
        "info"
      );
      setLoading(false);
      return;
    }

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = { id: emissionModel?.id, ...updateDataJSON };

    fetch(emissionsURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          const index = allEmissions.findIndex(
            (emission) => emission.id === body?.id
          );
          allEmissions[index] = {
            ...body,
            country: countries?.find(
              (country) => country.iso2 == body?.country_code
            ).name,
            state: states?.find(
              (country) => country.state_code == body?.state_code
            ).name,
          };
          setAllEmissions([...allEmissions]);
          snackBarElement.current.displayToast("Emission Updated!");
          handleClose();
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to update Emission model",
            "error"
          );
        }
      })
      .catch((err) => {
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={state?.dialogView === 3}
      onClose={handleClose}
      onSubmit={isEditMode ? handleUpdate : handleCreate}
      maxWidth="md"
      fullWidth
      component="form"
    >
      <DialogTitle>
        {isEditMode ? "Update" : "Create New"} Emission Model
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2} mt={2}>
          <Grid {...dialogGridLabelProps}>Country</Grid>
          <Grid {...dialogGridItemProps}>
            <TextField
              {...dialogTextFieldSelectProps}
              id="country_code"
              name="country_code"
              value={state?.modelState?.country_code || ""}
              label="Select Country"
              onChange={async (event) => {
                //finds the selected country's iso2
                setState({
                  ...state,
                  modelState: {
                    ...state?.modelState,
                    country_code: event.target.value,
                    state_code: "",
                  },
                });
                const iso2 = countries?.find(
                  (x) => x?.iso2 === event.target.value
                )?.iso2;
                const states = await fetchStates(iso2, snackBarElement);
                setStates(states);
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.iso2} value={country.iso2}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid {...dialogGridLabelProps}>State</Grid>
          <Grid {...dialogGridItemProps}>
            <TextField
              {...dialogTextFieldSelectProps}
              required={false}
              id="state_code"
              name="state_code"
              value={state?.modelState?.state_code || ""}
              label="Select State"
              onChange={(event) =>
                setState((state) => ({
                  ...state,
                  modelState: {
                    ...state?.modelState,
                    state_code: event.target.value,
                  },
                }))
              }
            >
              {states.length ? (
                states.map((state) => (
                  <MenuItem key={state.state_code} value={state.state_code}>
                    {state.name}
                  </MenuItem>
                ))
              ) : state?.modelState?.state && isEditMode ? (
                // to avoid having the state be out-of-range while loading the states list during first render of Edit
                <MenuItem
                  key={state?.modelState?.state}
                  value={state?.modelState?.state}
                >
                  {state?.modelState?.state}
                </MenuItem>
              ) : (
                <Skeleton variant="wave" sx={{ width: "100%" }} />
              )}
            </TextField>
          </Grid>
          <Grid {...dialogGridLabelProps}>Emission</Grid>
          <Grid {...dialogGridItemProps}>
            <TextField
              {...dialogTextFieldProps}
              id="emission"
              name="emission"
              type="number"
              placeholder="0.43"
              defaultValue={emissionModel?.emission ?? ""}
              InputProps={{
                inputProps: { min: 0.0, step: 0.00001 },
                endAdornment: (
                  <InputAdornment position="end">kg/kWh</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid {...dialogGridLabelProps}>NOX</Grid>
          <Grid {...dialogGridItemProps}>
            <TextField
              {...dialogTextFieldProps}
              id="nox"
              name="nox"
              type="number"
              placeholder="0.0"
              defaultValue={emissionModel?.nox ?? ""}
              InputProps={{
                inputProps: { min: 0.0, step: 0.00001 },
                endAdornment: (
                  <InputAdornment position="end">g/kWh</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid {...dialogGridLabelProps}>SO2</Grid>
          <Grid {...dialogGridItemProps}>
            <TextField
              {...dialogTextFieldProps}
              id="so2"
              name="so2"
              type="number"
              placeholder="0.0"
              defaultValue={emissionModel?.so2 ?? ""}
              InputProps={{
                inputProps: { min: 0.0, step: 0.00001 },
                endAdornment: (
                  <InputAdornment position="end">g/kWh</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          className="btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          fullWidth
          color="primary"
          variant="contained"
          className="btn"
          loading={loading}
        >
          {isEditMode ? "Update" : "Create"} Emission Model
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
