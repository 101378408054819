import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  geotabMultidayOperationsURL,
  geotabOperationalGroupsURL,
} from "../../../static/constants/backendRoutes";
import UseAuth from "../../auth/useAuth";
import { errorHandler, getLocalData, storeLocalData } from "../../utils";
import { filterMultidayData } from "../multiDayUploadPages/multiDayUpload";

export default function GeotabImport({ onClose, setImportedData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({ devices_by_group: {}, root_groups: [] });
  const [expandedGroups, setExpandedGroups] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});

  const { devices_by_group, root_groups } = data;
  const id_to_vehicle_map = useRef({});

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    // fetches operational groups data
    function fetchData() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      setIsLoading(true);
      fetch(geotabOperationalGroupsURL, { method: "GET", headers })
        .then((response) => {
          if (!response.ok) {
            errorHandler(response, snackBarElement, "Something went wrong");
            setIsLoading(false);
            return;
          }

          response.json().then(({ data }) => {
            setData(data);
            setIsLoading(false);
            id_to_vehicle_map.current = Object.values(
              data.devices_by_group
            ).reduce(
              (mapping, row) => ({ ...mapping, ...row.id_to_vehicle_map }),
              {}
            );
          });
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Something went wrong",
            "error",
            5000
          );
          setIsLoading(false);
        });
    }

    fetchData();
  }, []);

  function toggleSelection(group_id) {
    setSelectedGroups((prev) => {
      const isSelected = !prev[group_id];
      if (isSelected)
        for (const i in devices_by_group[group_id].descendents)
          prev[devices_by_group[group_id].descendents[i]] = true;
      else
        for (const i in devices_by_group[group_id].descendents)
          delete prev[devices_by_group[group_id].descendents[i]];
      return { ...prev };
    });
  }

  /**
   * recursively displays a list of child components
   * @param {String[]} children the group Ids to display in a list
   */
  function RecursiveOperationalGroups({ children }) {
    return children.map((child_id) => {
      if (devices_by_group[child_id].children.length) {
        const isChecked = devices_by_group[child_id].descendents.every(
          (ancestor_id) => selectedGroups[ancestor_id]
        );
        const isIndeterminate =
          !isChecked &&
          devices_by_group[child_id].descendents.some(
            (ancestor_id) => selectedGroups[ancestor_id]
          );
        return (
          <Fragment key={child_id}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() =>
                    setExpandedGroups((prev) => ({
                      ...prev,
                      [child_id]: !prev[child_id],
                    }))
                  }
                >
                  {expandedGroups[child_id] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={() => toggleSelection(child_id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${
                    devices_by_group[child_id].name
                  } (${devices_by_group[child_id].descendents.reduce(
                    (sum, descendent_id) =>
                      sum + devices_by_group[descendent_id].device_ids.length,
                    0
                  )} vehicles)`}
                />
              </ListItemButton>
            </ListItem>
            <Collapse
              in={Boolean(expandedGroups[child_id])}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding sx={{ pl: 4 }}>
                {devices_by_group[child_id].device_ids.length > 0 && (
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      setSelectedGroups((prev) => {
                        const isSelected = !prev[child_id];
                        if (isSelected) prev[child_id] = isSelected;
                        else delete prev[child_id];
                        return { ...prev };
                      });
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedGroups[child_id]}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${devices_by_group[child_id].name} (${devices_by_group[child_id].device_ids.length} vehicles)`}
                    />
                  </ListItemButton>
                )}
                <RecursiveOperationalGroups
                  children={devices_by_group[child_id].children}
                />
              </List>
            </Collapse>
          </Fragment>
        );
      } else if (devices_by_group[child_id].device_ids.length > 0) {
        return (
          <ListItem key={child_id} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={() => toggleSelection(child_id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedGroups[child_id]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary={`${devices_by_group[child_id].name} (${devices_by_group[child_id].device_ids.length} vehicles)`}
              />
            </ListItemButton>
          </ListItem>
        );
      }
    });
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  async function handleOperationalGroupsSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const {
      data: { depot_id },
    } = await getLocalData("simulation", "data");

    const body = {
      device_id_list: Object.keys(selectedGroups).flatMap(
        (id) => devices_by_group[id].device_ids
      ),
      depot_id: depot_id,
    };

    fetch(geotabMultidayOperationsURL, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          errorHandler(response, snackBarElement, "Something went wrong");
          setIsLoading(false);
          return;
        }

        response.json().then(({ data: multiDayData }) => {
          multiDayData.forEach((row) => {
            row.vehicleId = (
              id_to_vehicle_map.current[row.block_id_original] ??
              row.block_id_original
            ).replaceAll("_", "-");
            // time is sent in UST, with an offset based on depot's timezone (offset is the last six characters of the string)
            // Simply remove the offset to get the time in locale datetime  units
            row.depotDepartureDateTime = new Date(row.start_time.slice(0, -6));
            row.depotArrivalDateTime = new Date(row.end_time.slice(0, -6));
          });

          // filter out invalid rows
          const filteredData = filterMultidayData(multiDayData);

          if (multiDayData.length != filteredData.length) {
            //if rows were removed, but did not raise error flag, raise a warning to notify user
            const nRowsRemoved = multiDayData.length - filteredData.length;
            snackBarElement.current.displayToast(
              `We have successfully processed your data and removed ${nRowsRemoved.toLocaleString()} trip${
                nRowsRemoved == 1 ? "" : "s"
              } that did not meet our data quality standards or analysis minimum criteria. 
This may include trips with zero movement, unusually high speeds or accelerations, very short distances or durations,
and trips that last over 2 days or 36 hours.`,
              "info",
              5000,
              { sx: { width: "40%" } }
            );
          }

          storeLocalData("multiDayUpload", { data: filteredData });
          setImportedData(filteredData);
          setIsLoading(false);
          onClose();
        });
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network Error: Something went wrong",
          "error",
          5000
        );
        setIsLoading(false);
      });
  }

  return (
    <>
      <DialogTitle>Select Operational Groups</DialogTitle>
      <DialogContent sx={{ maxHeight: "30em" }}>
        <FormGroup>
          <RecursiveOperationalGroups children={root_groups} />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          fullWidth
          type="submit"
          className="btn"
          variant="contained"
          color="success"
          loading={isLoading}
          disabled={!Object.keys(selectedGroups).length}
          onClick={handleOperationalGroupsSubmit}
        >
          Extract Data for Selected Groups
        </LoadingButton>
      </DialogActions>
    </>
  );
}
