import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { geotabLoginURL } from "../../static/constants/backendRoutes";
import UseAuth from "../auth/useAuth";
import { errorHandler } from "../utils";

/**
 * telematic login dialog
 * @param {{onClose: Function}} param0
 */
export default function TelematicsProviderDialog({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [selectedProviderIndex, setSelectedProviderIndex] = useState("");

  const { setTelematicsActive } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  /** @type {{label:String, component: import("react").ReactNode, selectProps: }[]} */
  const telematics_providers = [
    {
      label: "Geotab",
      component: <GeotabLogin />,
      route: geotabLoginURL,
      cookie: "geotab",
    },
  ];

  /**
   *
   * @param {SubmitEvent} event
   */
  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());
    setLoading(true);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    fetch(telematics_providers[selectedProviderIndex].route, {
      method: "POST",
      headers,
      body: JSON.stringify(formJSON),
    })
      .then((res) => {
        if (res.ok) {
          snackBarElement.current.displayToast(
            `Logged into ${telematics_providers[selectedProviderIndex].label}`
          );
          UseAuth("set_telematics", { key: "geotab" });
          setTelematicsActive(true);
          onClose();
        } else {
          errorHandler(res, snackBarElement, "Something went wrong logging in");
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Network Error: Login failed",
          "error",
          5000
        );
        setLoading(false);
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Login to Telematics Provider</DialogTitle>
      <DialogContent>
        <br />
        <TextField
          fullWidth
          label="Telematics Provider"
          variant="outlined"
          value={selectedProviderIndex}
          onChange={(e) => setSelectedProviderIndex(e.target.value)}
          select
        >
          {telematics_providers.map(({ label }, index) => (
            <MenuItem key={label} value={index}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        {telematics_providers?.[selectedProviderIndex]?.component}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          disabled={selectedProviderIndex === ""}
        >
          Login
        </LoadingButton>
      </DialogActions>
    </form>
  );
}

function GeotabLogin() {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <br />
      <br />
      <TextField
        name="username"
        label="Username"
        variant="outlined"
        required
        fullWidth
      />
      <br />
      <br />
      <TextField
        name="password"
        label="Password"
        autoComplete="one-time-code"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        required
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <br />
      <TextField
        name="database"
        label="Database"
        variant="outlined"
        required
        fullWidth
      />
      <br />
      <br />
    </>
  );
}
