import Cookie from "js-cookie";
import { clearLocalDb } from "../utils";

const in8Hours = 8 / 24;

/**
 * Manages cookies for user login (and organization details)
 * @param  {"set"|"alt_set"|"get"|"get_id"|"true_get"|"set_details"|"get_details"|"remove"|"alt_remove"|"get_membership"|"set_membership"|"get_telematics"|"set_telematics"} command to set a login cookie, get or remove
 * @param  {Object} props optional arguements
 * @param  {String} [props.key] sets the token/org_name inside cookie
 * @param  {Number} [props.id] only for impersonating users, the id of the impersonated user. Now also can be used to contain the org_id
 * @param  {import("js-cookie").CookieAttributes} [options] js-cookie options
 * @return {Boolean|{name:String, id:Number, user_role: Number}|string} true/false, or org_name and org_id or the token cookie, or the impersonated user id
 *
 * @example UseAuth("set",{key: "random string from login token"}) => true
 */
const UseAuth = (command, props, options = {}) => {
  const { key, id } = { key: null, id: null, ...props };
  options = { expires: in8Hours, ...options };
  switch (command) {
    case "set":
      //login
      console.log("input token", key);
      Cookie.remove("token");
      Cookie.remove("impersonated_token");
      Cookie.remove("impersonated_id");
      clearLocalDb();
      Cookie.set("token", key, {
        expires: in8Hours,
        // expires: 0.00011574, // for testing purpose only. logs out the user in less than 10 secs
      });
      console.log("stored token", Cookie.get("token"));
      return true;
    case "alt_set":
      //login impersonated user
      Cookie.remove("impersonated_token");
      Cookie.remove("impersonated_id");
      clearLocalDb();
      Cookie.set("impersonated_token", key, { expires: in8Hours });
      Cookie.set("impersonated_id", id, { expires: in8Hours });
      return true;
    case "set_membership":
      // set the selected membership id
      if (Cookie.get("impersonated_id"))
        //if the user is currently impersonating someone, set the impersonated_membership id
        Cookie.set("impersonated_membership", key, { expires: in8Hours });
      else Cookie.set("membership", key, { expires: in8Hours });
      return true;
    case "get_membership":
      // get the selected membership id
      return Cookie.get("impersonated_membership") || Cookie.get("membership");
    case "get": {
      //returns the impersonate token, or the token if there is no impersonate
      const token = Cookie.get("token");
      const altToken = Cookie.get("impersonated_token");
      if (!token) {
        return false;
      }
      if (altToken) {
        // if currently signed in user is impersonating another
        return altToken;
      }
      return token;
    }
    case "true_get": {
      //returns the actual token, regardless of impersonation status
      const token = Cookie.get("token");
      if (!token) {
        return false;
      }
      return token;
    }
    case "get_id":
      //returns the impersonated user's id
      return Cookie.get("impersonated_id");
    case "set_details":
      Cookie.set("organization_name", key, options);
      Cookie.set("organization_id", id, options);
      Cookie.set("user_role", props.user_role, options);
      return true;
    case "get_details":
      const name = Cookie.get("organization_name");
      const org_id = +Cookie.get("organization_id");
      const user_role = +Cookie.get("user_role");
      return { name, id: org_id, user_role };
    case "set_telematics":
      Cookie.set("telematics", key, options);
      return true;
    case "get_telematics":
      return Cookie.get("telematics");
    case "remove":
      //logout, delete all cookies
      Object.keys(Cookie.get()).forEach((key) => Cookie.remove(key));
      clearLocalDb();
      return true;
    case "alt_remove":
      //logout impersonated user
      Cookie.remove("impersonated_membership");
      Cookie.remove("impersonated_token");
      Cookie.remove("impersonated_id");
      Cookie.remove("organization_name");
      Cookie.remove("organization_id");
      Cookie.remove("user_role");
      clearLocalDb();
      return true;
    default:
      return false;
  }
};

export default UseAuth;
