import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import stepInfo, {
  assessmentStepZero,
  comparisonStepInfo,
  multiDayUploadStepInfo,
} from "../../static/constants/stepInfo";
import { StepperStyle } from "../../static/constants/theme";

/**
 * Displays a "Stepper" for the project portion of the site
 * @param {Object} props
 * @param {Number} props.stepNum the page number for the stepper to display
 * @param {Number} [props.projectId] the id of the project (used for going back to "depot", when on a specific depot's resourceInventory)
 */
function ProjectStepper(props) {
  const steps = {
    "Create Project": "/project",
    "Add Depot(s)": `/depot${
      props?.projectId ? `?projectId=${props.projectId}` : ""
    }`,
    "Add Resources": "/resource-inventory",
    "Create Analysis": "/fleet-input",
  };
  const activeStep = (props?.stepNum || 1) - 1; //index of the current page, offset by 1 (to match the number displayed in the stepper)

  const { pageAccessMemo, accessRights } = useContext(DataContext);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {Object.entries(steps).map(([label, route], index) => (
          <Step
            key={label}
            sx={StepperStyle}
            disabled={
              index == activeStep ||
              pageAccessMemo.pageAccess < 1 ||
              (!accessRights.analysis.create_analysis && index == 3)
            }
          >
            <StepButton component={Link} to={route}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

/**
 * Displays a "Stepper" for the "Assessment"-type analyses
 * @param {Object} props
 * @param {Number} props.stepNum the page number for the stepper to display
 */
function AssessmentAnalysisStepper(props) {
  const { analysisStepsViewMemo } = useContext(DataContext);
  const stepZero =
    assessmentStepZero[analysisStepsViewMemo?.state?.input_method || 0];

  const stepInfoSliced = [stepZero].concat(stepInfo.slice(1, -1)); //removes the "fleet input" and "summary" steps, and preprends the variable input method step

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.stepNum} alternativeLabel>
        {stepInfoSliced.map(({ route, label }, index) => (
          <Step
            key={`${label}_stepper`}
            sx={StepperStyle}
            disabled={index == props.stepNum}
          >
            <StepButton component={Link} to={route}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

/**
 * Displays a "Stepper" for the "Comparison"-type analyses
 * @param {Object} props
 * @param {Number} props.stepNum the page number for the stepper to display
 */
function CompareAnalysisStepper(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.stepNum} alternativeLabel>
        {comparisonStepInfo.map(({ route, label }, index) => (
          <Step
            key={`${label}_stepper`}
            sx={StepperStyle}
            disabled={index == props.stepNum}
          >
            <StepButton component={Link} to={route}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function MultiDayUploadAnalysisStepper(props) {
  const buttonIcons = [1, "A", "B", "C", "D", 2];
  const { analysisStepsViewMemo } = useContext(DataContext);
  const stepZero =
    assessmentStepZero[analysisStepsViewMemo?.state?.input_method || 4];
  const stepperInfo = multiDayUploadStepInfo;
  stepperInfo[0] = stepZero;

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.stepNum} alternativeLabel>
        {multiDayUploadStepInfo.map(({ route, label }, index) => (
          <Step
            key={`${label}_stepper`}
            sx={StepperStyle}
            disabled={index == props.stepNum}
          >
            <StepButton component={Link} to={route} icon={buttonIcons[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function CustomStepper({ steps, activeStep, setActiveStep }) {
  // Receiving steps, activeStep, and setActiveStep as props
  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={StepperStyle}
            onClick={() => handleStepClick(index)} //  Click event to set active step
            disabled={index == activeStep}
          >
            <StepButton>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export {
  AssessmentAnalysisStepper,
  CompareAnalysisStepper,
  CustomStepper,
  MultiDayUploadAnalysisStepper,
  ProjectStepper,
};

