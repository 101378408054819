import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import DataContextProvider from "./contexts/dataContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

createRoot(document.getElementById("root")).render(
  <React.StrictMode style={{ backgroundColor: "#F8F8F8" }}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DataContextProvider>
        <App />
      </DataContextProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
