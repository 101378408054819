import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  depotURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import stepInfo, {
  multiDayUploadStepInfo,
} from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitLargeMap,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import UseAuth from "../../auth/useAuth";
import MRT_DownloadButton from "../../secondary/mrtDownloadButton";
import { MultiDayUploadAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import { getUnits, unitFeet, unitMiles } from "../../secondary/unitConversions";
import {
  errorHandler,
  getLabel,
  getLocalData,
  MFM_to_AMPM,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";

const STEP_NUMBER = 4;

const tableViewOptions = [
  { key: "sample_schedule_high", label: "Highest Day" },
  { key: "sample_schedule_low", label: "Average Day" },
];

const ScheduleGenerationTable = ({
  tableData,
  tableView,
  setTableView,
  depotLookup,
  subheaderContent,
}) => {
  const units = getUnits(); //cookies are an instant lookup, don't need to bother setting states

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        accessorKey: "veh_type",
        header: "Vehicle Type",
        Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
        filterFn: (row, columnId, filterValue) =>
          TYPE_STRINGS.VEHICLE_TYPE[row.getValue(columnId)].indexOf(
            filterValue
          ) != -1,
      },
      {
        accessorKey: "size",
        header: "Size",
        Cell: ({ row, cell }) =>
          // Note: I should really standardize this function...
          row.getValue("veh_type") == 1 ? (
            <>
              {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
            </>
          ) : row.getValue("veh_type") == 4 ? (
            //if selected vehicle type is a schoolbus, display Vehicle Type Character instead
            `Type ${cell.getValue()}`
          ) : cell.getValue() == 14 ? (
            "Transit"
          ) : (
            `Class ${cell.getValue()}`
          ),
        filterFn: (row, columnId, filterValue) =>
          (row.getValue("veh_type") == 1
            ? unitFeet(row.getValue(columnId))
            : row.getValue("veh_type") == 4
            ? `Type ${row.getValue(columnId)}`
            : row.getValue(columnId) == 14
            ? "Transit"
            : `Class ${row.getValue(columnId)}`
          )
            .toString()
            .indexOf(filterValue) != -1,
      },
      {
        accessorKey: "block_id_original",
        header: getLabel("block_id_original"),
      },
      {
        accessorKey: "blockId",
        header: getLabel("block_id"),
      },
      {
        accessorKey: "dh_st_time",
        header: "Depot Depart Time",
        Cell: ({ cell }) => MFM_to_AMPM(cell.getValue()),
        filterFn: (row, columnId, filterValue) =>
          MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
      },
      {
        accessorKey: "dh_end_time",
        header: "Depot Return Time",
        Cell: ({ cell }) => MFM_to_AMPM(cell.getValue()),
        filterFn: (row, columnId, filterValue) =>
          MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
      },
      {
        accessorKey: "endDepot",
        header: "Depot",
        Cell: ({ cell }) => depotLookup[cell.getValue()] ?? "Loading...",
        filterVariant: "select",
        filterSelectOptions: Object.entries(depotLookup).map(
          ([value, label]) => ({ value, label })
        ),
        muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } },
      },
      {
        header: "Distance",
        accessorFn: (row) => roundNumber(unitMiles(row.distance), 1),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
          </>
        ),
      },
    ],
    [depotLookup]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    columns,
    data: tableData[tableView],
    enableExport: true,

    // data selections settings
    renderTopToolbarCustomActions: () => (
      <>
        {/* {tableViewOptions.map(({ key, label }) => (
          <Chip
            key={key}
            label={label}
            variant={tableView == key ? "filled" : "outlined"}
            onClick={() => setTableView(key)}
          />
        ))} */}
        <span style={{ width: "100%" }} />
        <MRT_DownloadButton
          table={table}
          fileName={multiDayUploadStepInfo[STEP_NUMBER].label}
          disabled={!tableData[tableView]?.length}
        />
      </>
    ),
  });

  return (
    <>
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {multiDayUploadStepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          <MaterialReactTable table={table} />
        </Paper>
      </Container>
    </>
  );
};

const ScheduleGeneration = () => {
  const [data, setData] = useState(
    tableViewOptions.reduce((data, { key }) => ({ ...data, [key]: [] }), {})
  );

  /** @type {["sample_schedule_high"|"sample_schedule_low"]} */
  const [tableView, setTableView] = useState("sample_schedule_high");
  const [depotLookup, setDepotLookup] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [subheaderContent, setSubheaderContent] = useState([]);

  const { snackBarElement } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const units = getUnits();

  // this useEffect recomputes the subheader
  useEffect(() => {
    async function computeSubheader() {
      const trips = data[tableView];
      const totalDistance = trips.reduce(
        (sum, trip) => sum + unitMiles(trip.distance),
        0
      );
      const numTrips = trips.length;
      const longestTrip = Math.max(
        ...trips.map((trip) => unitMiles(trip.distance))
      );

      const { input: fleetSizes = {} } = await getLocalData(
        "fleetProjection",
        "input"
      );

      setSubheaderContent([
        {
          value: Object.values(fleetSizes)
            .reduce((sum, curr) => sum + curr, 0)
            .toLocaleString(),
          label: `Number of Vehicles`,
        },
        {
          value: `~ ${Math.round(totalDistance).toLocaleString()}`,
          label: `Total Distance (${unitLargeMap[units]})`,
        },
        {
          value: Math.round(numTrips).toLocaleString(),
          label: `Number of Trips`,
        },
        {
          value: `~ ${Math.round(longestTrip).toLocaleString()}`,
          label: `Longest Trip (${unitLargeMap[units]})`,
        },
      ]);
    }

    if (data[tableView] && data[tableView].length) computeSubheader();
  }, [data, tableView]);

  useEffect(() => {
    const fetchScheduleData = async () => {
      document.querySelector("#App").scrollIntoView(); //scrolls to top of app

      const { data: scheduleData } = await getLocalData(
        "scheduleGeneration",
        "data"
      );
      if (!scheduleData) {
        snackBarElement?.current?.displayToast("No Data Found", "warning");
        return;
      }

      //join all the sample schedules into two lists, one for high, and one for low
      const data = tableViewOptions.reduce(
        (data, { key }) => ({ ...data, [key]: [] }),
        {}
      );
      // Todo later: ask the backend to unnest this response, so that it's just two lists, high & low schedule
      Object.values(scheduleData).forEach((depotData) =>
        Object.values(depotData).forEach(({ sample_schedules }) =>
          // for each of the tableViewOptions keys
          tableViewOptions.forEach(
            ({ key }) => (data[key] = data[key].concat(sample_schedules[key]))
          )
        )
      );
      setData(data);
    };
    fetchScheduleData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data: currentProject } = await getLocalData("project", "data");

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      let depot_lookup = {};
      //fetches all the depots associated with the selected project
      fetch(`${depotURL}?project_id=${currentProject.id}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(({ data: depots }) => {
              depots.forEach((depot) => (depot_lookup[depot.id] = depot.name));
              setDepotLookup(depot_lookup);
            });
          } else errorHandler(res, snackBarElement);
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something went wrong in depot fetch",
            "error"
          );
        });
    }

    fetchData();
  }, []);

  const handleProceedWithAnalysis = async () => {
    if (!data[tableView].length) {
      snackBarElement.current.displayToast("No Rows Selected", "warning");
      return;
    }

    setIsLoading(true);

    const blocks = data[tableView];

    storeLocalData("blocks", { data: blocks });

    const { data: sim } = await getLocalData("simulation", "data");

    const backendBody = {
      id: sim.id,
      current_page: stepInfo[1].route,
      steps: {
        blocks: blocks,

        //clear out the future pages' backend data
        routeEnergy: {},
        battery: {},
        fleetSizing: {},
        evAssessment: {},
        financial: {},
        tco: {},
      },
      completed: false,
    };

    //clear out the future pages' frontend data
    partialClearLocalData([
      "routeEnergy",
      "battery",
      "fleetSizing",
      "evAssessment",
      "financial",
      "tco",
    ]);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    fetch(simulationURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(backendBody),
    })
      .then((response) => {
        if (response.ok) navigate("/assessment/route-definition");
        else {
          errorHandler(
            response,
            snackBarElement,
            "Error Sending Data to Server"
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network Error:", error);
        snackBarElement.current.displayToast(
          "Network Error: Unable to connect to backend",
          "error"
        );
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        margin: "20px auto",
        padding: "20px",
        width: "95%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <br />
      <br />
      <MultiDayUploadAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <ScheduleGenerationTable
        tableData={data}
        tableView={tableView}
        setTableView={setTableView}
        depotLookup={depotLookup}
        subheaderContent={subheaderContent}
      />
      <br />
      <br />
      <Container>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="outlined"
            className="btn"
            sx={{ width: "95%" }}
            component={Link}
            to={multiDayUploadStepInfo[STEP_NUMBER - 1].route}
            startIcon={<ArrowBackIosNew />}
            disabled={false}
          >
            Back to {multiDayUploadStepInfo[STEP_NUMBER - 1].label}
          </Button>
          <NextPageButton
            sx={{ width: "95%" }}
            onClick={handleProceedWithAnalysis}
            disabled={!data[tableView].length}
            loading={isLoading}
          >
            Continue to {stepInfo[1].label}
            {/* {tableView == "sample_schedule_high"
              ? "Use the Highest Day for Assessment"
              : "Use the Average Day for Assessment"} */}
          </NextPageButton>
        </Stack>
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ScheduleGeneration;
