import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  impersonateLogoutURL,
  logoutURL,
} from "../../static/constants/backendRoutes";
import { clearLocalDb } from "../utils";
import UseAuth from "./useAuth";

// logout dialog box
const Logout = ({ logoutState, setLogoutState, isImpersonation }) => {
  const handleClose = () => {
    setLogoutState((state) => ({ ...state, isOpen: false }));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    //if user is impersonating another account, logout of the impersonation too.
    if (!logoutState.isImpersonationLogout) {
      //if user is doing a normal/complete logout
      fetch(logoutURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("true_get")}`,
        },
      });

      setTimeout(() => {
        //regardless of whether or not the logout succeded on the backend, have the frontend logout
        //wait 0.25 seconds to make give the fetch enough time to fire before removing the tokens
        clearLocalDb();
        UseAuth("remove");
      }, 250);
      setTimeout(() => {
        window.location.assign("/");
      }, 500);
    } else {
      //if user is exiting an impersonation
      fetch(impersonateLogoutURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${UseAuth("true_get")}`,
        },
        body: JSON.stringify({ user_id: UseAuth("get_id") }),
      });

      setTimeout(() => {
        //regardless of whether or not the logout succeded on the backend, have the frontend logout
        //wait 0.5 seconds to make give the fetch enough time to fire before removing the tokens
        clearLocalDb();
        UseAuth("alt_remove");
        window.location.assign("/project");
      }, 500);
    }
  };

  return (
    <Dialog
      style={{ zIndex: 1700 }}
      open={logoutState.isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {logoutState.isImpersonationLogout ? "Exit Impersonation" : "Logout"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {logoutState.isImpersonationLogout
            ? "Are you sure you want to exit impersonation?"
            : "Are you sure you want to logout?"}
          {!logoutState.isImpersonationLogout && isImpersonation && (
            <>
              <br />
              This will logout both impersonated account and the user account.
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleLogout} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Logout;
