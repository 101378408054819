import { Box, Chip } from "@mui/material";
import { useContext, useMemo, useRef } from "react";
import { Scatter } from "react-chartjs-2";
import { DataContext } from "../../../contexts/dataContext";
import { unitLargeAbbr } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitMiles } from "../../secondary/unitConversions";
import { getLabel, MFM_to_Military, roundNumber } from "../../utils";

const ScatterChart = ({ outlierData, highestEnergyBlocks }) => {
  const chartRef = useRef(null);
  const { logo } = useContext(DataContext);
  const units = getUnits();

  const chartData = useMemo(() => {
    const scatterData = [];
    const excludedData = [];
    const designRunData = new Set();

    highestEnergyBlocks.forEach((block) => {
      designRunData.add(block.blockId);
    });

    Object.keys(outlierData).forEach((busClass) => {
      Object.keys(outlierData[busClass]).forEach((locationKey) => {
        const locationData = outlierData[busClass][locationKey];
        const energyResults = locationData.energy_results || {};
        const outlierBlockIdSet = new Set(locationData.outliers);

        Object.values(energyResults).forEach(({ blocks }) =>
          blocks.forEach((block) => {
            if (outlierBlockIdSet.has(block.blockId)) {
              excludedData.push({
                x: block.dh_end_time - block.dh_st_time,
                y: block.distance.toFixed(2),
                blockId: block.blockId,
                vehicle_id: block.vehicle_id,
                location: locationKey,
                busClass,
              });
            } else if (!designRunData.has(block.blockId)) {
              // non-outliers & non-design run
              scatterData.push({
                x: block.dh_end_time - block.dh_st_time,
                y: block.distance.toFixed(2),
                blockId: block.blockId,
                vehicle_id: block.vehicle_id,
                location: locationKey,
                busClass,
              });
            }
          })
        );
      });
    });

    return {
      datasets: [
        {
          label: `Design ${getLabel("blocks")}`,
          data: highestEnergyBlocks.map((block) => ({
            x: block.dh_end_time - block.dh_st_time,
            y: block.distance.toFixed(2),
            blockId: block.blockId,
            vehicle_id: block.vehicle_id,
            location: block.location,
            busClass: block.busClass,
          })),
          borderColor: "green",
          backgroundColor: "green",
          pointRadius: 5,
        },
        {
          label: `Analyzed ${getLabel("blocks")}`,
          data: scatterData,
          borderColor: "blue",
          backgroundColor: "blue",
          pointRadius: 5,
        },
        {
          label: "Currently Excluded",
          data: excludedData,
          borderColor: "red",
          backgroundColor: "red",
          pointRadius: 5,
        },
      ],
    };
  }, [outlierData]);

  const chartOptions = {
    animations: false,
    scales: {
      x: {
        suggestedMin: 0,
        title: {
          display: true,
          text: "Duration (h:mm)",
          font: { size: 16, weight: "bold" },
        },
        ticks: { callback: (val) => MFM_to_Military(val) },
      },
      y: {
        suggestedMin: 0,
        title: {
          display: true,
          text: `Distance (${unitLargeAbbr[units]})`,
          font: { size: 16, weight: "bold" },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
        intersect: true,
        callbacks: {
          label: (ctx) => {
            let datasetType = "";
            if (ctx.dataset.label.includes("Design")) {
              datasetType = "Design";
            } else if (ctx.dataset.label.includes("Analyzed")) {
              datasetType = "Analyzed";
            } else if (ctx.dataset.label.includes("Currently Excluded")) {
              datasetType = "Currently Excluded";
            }

            return datasetType === "Currently Excluded"
              ? [
                  datasetType,
                  `Vehicle ID: ${ctx.raw.vehicle_id}`,
                  `Block ID: ${ctx.raw.blockId}`,
                  `Duration: ${MFM_to_Military(ctx.raw.x)}`,
                  `Distance: ${roundNumber(unitMiles(ctx.raw.y), 1)} ${
                    unitLargeAbbr[units]
                  }`,
                ]
              : [
                  `${datasetType} ${getLabel("blocks")}`,
                  `Vehicle ID: ${ctx.raw.vehicle_id}`,
                  `Block ID: ${ctx.raw.blockId}`,
                  `Duration: ${MFM_to_Military(ctx.raw.x)}`,
                  `Distance: ${roundNumber(unitMiles(ctx.raw.y), 1)} ${
                    unitLargeAbbr[units]
                  }`,
                ];
          },
        },
      },
      legend: { display: true, position: "top" },
      zoom: {
        zoom: {
          mode: "xy",
          drag: { enabled: true, maintainAspectRatio: true },
        },
      },
    },
  };

  return (
    <div className="chartdiv">
      <Box display="flex" justifyContent="right" m={1}>
        <Chip
          label="Reset Zoom"
          onClick={() => chartRef.current?.resetZoom()}
        />
      </Box>

      <Scatter ref={chartRef} data={chartData} options={chartOptions} />

      <div
        style={{ right: "1%", bottom: "50px" }}
        className="watermark-container"
      >
        <img src={logo} width="auto" height="50px" className="watermark-img" />
      </div>
    </div>
  );
};

export default ScatterChart;
