import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { membershipURL } from "../../static/constants/backendRoutes";
import BannerImage from "../../static/images/banner.jpeg";
import { errorHandler, stringCapitalize } from "../utils";
import UseAuth from "./useAuth";

export default function SelectMembership() {
  const { snackBarElement } = useContext(SnackBarContext);
  const [memberships, setMemberships] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  function chooseMembership(membership_id) {
    setIsLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    fetch(membershipURL, {
      method: "POST",
      headers,
      body: JSON.stringify({ membership_id }),
    })
      .then((response) => {
        if (response.ok) {
          UseAuth("set_membership", { key: membership_id });
          window.location.assign("/project");
        } else {
          setIsLoading(false);
          errorHandler(
            response,
            snackBarElement,
            "Failed to select active membership"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network Error: Failed to select active membership",
          "error",
          5000
        );
        setIsLoading(false);
      });
  }

  //upon login, list the user's memberships, and have the user select one from a list
  // if the user only has one membership, then auto-select, fire to backend and redirect straight to projects page.
  useEffect(() => {
    function fetchData() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      fetch(membershipURL, { method: "GET", headers })
        .then((response) => {
          if (response.ok)
            response.json().then(({ data: memberships }) => {
              if (memberships.length == 1) chooseMembership(memberships[0].id);
              else if (memberships.length > 1) {
                setSelected(memberships[0].id);
                setMemberships(
                  memberships.map((membership) => ({
                    ...membership,
                    label: `${
                      membership.organization_name
                    } - ${stringCapitalize(membership.user_role)}`,
                  }))
                );
              }
            });
          else
            errorHandler(
              response,
              snackBarElement,
              "Failed to get memberships"
            );
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: failed to get membership list",
            "error",
            5000
          );
        });
    }

    fetchData();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      height="calc(100vh - 64px)"
      sx={{
        backgroundImage: `url(${BannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        chooseMembership(selected);
      }}
    >
      {memberships &&
        (memberships.length > 1 ? (
          <Container
            elevation={3}
            sx={{ p: 3 }}
            fixed
            maxWidth="sm"
            component={Paper}
          >
            <Typography component="h1" variant="h5" mb={2}>
              Select which membership to use:
            </Typography>
            <Autocomplete
              autoFocus
              autoSelect // automatically selects the input if put in manually
              autoComplete //auto fills the input to match
              autoHighlight // automatically highlights the first option in the dropdown
              value={memberships.find(({ id }) => id == selected)?.label || ""}
              onChange={(_e, newValue) => setSelected(newValue?.id)}
              disablePortal
              options={memberships}
              isOptionEqualToValue={(option, value) => option.label == value}
              renderInput={(params) => (
                <TextField {...params} label="Membership" />
              )}
            />
            <br />
            <br />
            <Button
              type="submit"
              className="btn"
              variant="contained"
              disabled={!selected}
            >
              Use Membership
            </Button>
          </Container>
        ) : (
          <Container
            elevation={3}
            sx={{ p: 3 }}
            fixed
            maxWidth="sm"
            component={Paper}
          >
            <Typography component="h1" variant="h5" mb={2}>
              No memberships found for this account. Please contact MGL at{" "}
              <a
                href={
                  "mailto:EVOPTSupport@microgridlabs.com?subject=" +
                  "Missing Memberships"
                }
                target="_blank"
              >
                EVOPTSupport@microgridlabs.com
              </a>{" "}
              for support.
            </Typography>
          </Container>
        ))}
      <Backdrop sx={{ color: "#fff" }} open={!memberships || isLoading}>
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Loading...</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </Box>
  );
}
