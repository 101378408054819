import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import introJs from "intro.js/intro";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../../contexts/dataContext";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  batteryURL,
  fleetSizingURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import stepInfo from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import {
  batterySizingTableRouteTourOptions,
  chargerTourOptions,
} from "../../../static/constants/tourOptions";
import UseAuth from "../../auth/useAuth";
import MRT_DownloadButton from "../../secondary/mrtDownloadButton";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import TourBeacon from "../../secondary/tourBeacon";
import { getUnits, unitFeet, unitMiles } from "../../secondary/unitConversions";
import {
  MFM_to_AMPM,
  errorHandler,
  getLabel,
  getLocalData,
  getMasterData,
  parseFromValuesOrFunc,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import ChargerOptions from "../dialogs/chargerOptions";
import {
  BatteryAdvancedOptions,
  updateIndexDb,
} from "../dialogs/editAnalysisInputs";
import FeasibleSubmissionDialog from "../dialogs/feasibleSubmissionDialog";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import BatterySizingGraph from "../graphs/batterySizingGraph";
import BatteryChild from "../tables/batteryChild";
import BatterySizingGroupEdit from "../tables/batterySizingGroupEdit";
//note: batter_size does not need to be stored in steps, as it is only ever used on this page, and is always calculated in initial useEffect
// consider removing batter_size from steps

/** maps the project TYPE to the kW range of chargers, for auto-selecting
 * chargers from masterdata on first visits (inclusive range, so <= and >=)
 */
const projectChargerRange = {
  1: [19.2, 200],
  2: [11.5, 80],
  3: [7.2, 19.2],
  4: [11.5, 80],
  5: [7.2, 80],
  6: [7.2, 80],
};

const STEP_NUMBER = 3;

export const rowDataConversion = (row, vehicleInfoLookup, operating_year) => {
  row.batter_size = vehicleInfoLookup[row.vehicleModel].battery_capacity;
  const vehicleInfo = vehicleInfoLookup[row.vehicleModel];
  const chartSoC =
    row.batter_size * (1 - (vehicleInfo.max_soc - vehicleInfo.min_soc) / 100);
  const chartBatterySize = row.batter_size - chartSoC;
  const chartDegredation =
    chartBatterySize - chartBatterySize * 0.97 ** operating_year;

  row.mileage = roundNumber(
    unitMiles(
      (row.batter_size - chartSoC) / row.detailed_energy.updated_efficiency
    ),
    0
  );
  row.nth_mileage = roundNumber(
    unitMiles(
      (row.batter_size - chartSoC - chartDegredation) /
        row.detailed_energy.updated_efficiency
    ),
    0
  );
};

export default function BatterySizing() {
  const [data, setData] = useState([]);
  /** @type {["block"|"fleet"]} */
  const [selectedChip, setSelectedChip] = useState("block");
  const originalColumnVisibility = useRef(null); //used to restore column visibility after edit, if an editable column was hidden
  const [columnVisibility, setColumnVisibility] = useState({
    //initially hidden columns
    block_id_original: false,
    dh_st_time: false,
    dh_end_time: false,
    mileage: false,
    distance: false,
    nth_mileage: false,
  });
  const [enableHiding, setEnableHiding] = useState(true); // used to prevent users from hiding editable columns during edits
  /** @type {[MRT.MRT_Row<MRT.TData> | null]} */
  const [editingRow, setEditingRow] = useState(null); //note: had to take control of the editing row in order to handle the visibility column changes
  /** @type {[import("material-react-table").MRT_RowSelectionState]} */
  const [rowSelection, setRowSelection] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [allChargers, setAllChargers] = useState([]); //all chargers (fetched from masterdata)
  const [inputs, setInputs] = useState({});
  const [showTable, setShowTable] = useState(0); ///0 for display table, false for display chart
  const viewNameLookup = { 0: "Table", 1: "Chart" };
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [batteryData, setBatteryData] = useState([]);
  const [batterySizing, setBatterySizing] = useState({});
  const [dataFetchError, setDataFetchError] = useState(false);
  const [chargerUpdateOptionsLookup, setChargerUpdateOptionsLookup] = useState(
    {}
  ); //for the updateBatterySizing dialog options
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [optionsOpen, setOptionsOpen] = useState(0); // 0 is closed, 1 is charger options, 2 is advanced options
  const [groupEditOpen, setGroupEditOpen] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false);
  const [openFeasibleDialog, setOpenFeasibleDialog] = useState(false);

  const [vehicleInfoLookup, setVehicleInfoLookup] = useState({}); //used in the batterySizingGraph column calculations, and bulkUpdate (update battery sizing) data set
  const [routeEnergyInputs, setRouteEnergyInputs] = useState({
    //used when calculating fleet and charger sizing
    operating_year: 12,
    veh_count: 1, //NOTE: veh_count is unused, but left in this setState, because the value exists in fetched object (in case we need it later)
  });
  const [subheaderContent, setSubheaderContent] = useState([]);
  const [currentProject, setCurrentProject] = useState(undefined);
  const feasibleChoice = useRef(0); //0 = chose 1, 1 = "assume larger battery will be available and include the blocks", 2="remove these blocks from electrification and Maintain ICE operation for them"

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const units = getUnits();

  function updateSubheader(
    data,
    operatingYear = routeEnergyInputs?.operating_year
  ) {
    let [uniqueVehicleModels, sumInfeasibleSurplus, feasibleCount] = [[], 0, 0];

    data.forEach((row) => {
      if (!uniqueVehicleModels.includes(row.vehicleModel) && !row.feasible)
        uniqueVehicleModels.push(row.vehicleModel);
      const vehicleInfo = vehicleInfoLookup[row.vehicleModel];
      const SoC =
        row.batter_size *
        (1 - (vehicleInfo.max_soc - vehicleInfo.min_soc) / 100);
      const batterySize = row.batter_size - SoC;
      const surplus =
        row.batter_size -
        (batterySize - batterySize * 0.97 ** operatingYear) -
        SoC -
        row.detailed_energy.total_energy;
      sumInfeasibleSurplus += surplus < 0 ? surplus : 0;
      feasibleCount += !row.feasible;
    });

    setSubheaderContent([
      {
        value: uniqueVehicleModels.length || 1,
        label: "Unique Vehicle Battery Sizes",
      },
      { value: feasibleCount, label: `Total Feasible ${getLabel("blocks")}` },
      {
        value: data.length - feasibleCount,
        label: `Total Infeasible ${getLabel("blocks")}`,
      },
      {
        value: Math.round(Math.abs(sumInfeasibleSurplus)).toLocaleString(),
        label: "Daily Energy Shortfall (kWh)",
      },
    ]);
  }

  useEffect(() => {
    if (data?.length) updateSubheader(data, routeEnergyInputs?.operating_year);
  }, [data]);

  useEffect(() => {
    //todo: see if you can make the useEffect moreEfficient by moving the backend calls to the end, and updating the batter_size implementations to be the only part that is still loading while waiting for the backend to respond
    // todo: look into combining several of these setStates into a single state to reduce rerenders
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        //get routeEnergyInputs, for when calculating the fleet and charger sizing
        const operating_year = await getLocalData("routeEnergy", "input").then(
          ({ input }) => {
            if (input) setRouteEnergyInputs(input); //todo: replace this state with a useRef() equivalent
            return input?.operating_year;
          }
        );

        //retrieves blocks from the localDb
        const { data: currentBlocks, input: indexedBatterySizingInputs } =
          await getLocalData("battery");

        if (!currentBlocks?.blocks?.length) {
          setDataFetchError(true);
          return;
        }

        //retrieves the project JSON from the localDb
        const { data: currentProject } = await getLocalData("project", "data");
        setCurrentProject(currentProject);

        const chargerPromise = getMasterData(snackBarElement, {
          is_chargers: true,
        });
        const vehiclePromise = getMasterData(snackBarElement);

        const [charger, vehicle] = await Promise.all([
          chargerPromise,
          vehiclePromise,
        ]);

        // chargerPromise response usage
        setAllChargers(charger);

        //if there are previously stored selected chargers, then auto-set those.
        let charger_models = indexedBatterySizingInputs?.charger_models;
        if (!charger_models) {
          //if there are not previously stored selected chargers,
          //then auto - set based on project ID's charger rating range
          const range = projectChargerRange[currentProject.type]; //gets the rating range for the chargers, based on project type
          charger_models = charger
            .filter(
              (charger) =>
                (charger.rating >= range[0] && charger.rating <= 19.2) ||
                (charger.rating % 30 == 0 &&
                  charger.rating >= range[0] &&
                  charger.rating <= range[1])
            )
            .map((selectedCharger) => selectedCharger.model);
        }
        setInputs({ ...indexedBatterySizingInputs, charger_models });

        setBatterySizing(currentBlocks.battery_sizing);

        let blockEnergyData = currentBlocks.blocks;
        const chargerUpdateOptionsLookup = {}; // battery sizing PATCH dialog stuff
        const vehLookup = {}; //for more efficient finding in battery sizing chart
        vehicle.forEach((veh) => {
          vehLookup[veh.model] = veh;

          //chargerUpdatesOptionsLookup code
          if (!(`t_${veh.type}_s_${veh.size}` in chargerUpdateOptionsLookup))
            chargerUpdateOptionsLookup[`t_${veh.type}_s_${veh.size}`] = {
              [veh.model]: veh.battery_capacity,
            };
          else
            chargerUpdateOptionsLookup[`t_${veh.type}_s_${veh.size}`][
              veh.model
            ] = veh.battery_capacity;
        });
        setVehicleInfoLookup(vehLookup);
        setChargerUpdateOptionsLookup(chargerUpdateOptionsLookup);

        let rowSelection = {};
        blockEnergyData.forEach((row, index) => {
          row.id = index;
          rowDataConversion(row, vehLookup, operating_year);
          if (row?.tableData?.checked || row.checked)
            rowSelection[row.id] = true;
          delete row.tableData;
        });

        if (!Object.keys(rowSelection).length)
          //if there are no previously saved selected rows, auto-select all FEASIBLE rows
          rowSelection = blockEnergyData
            .filter((row) => !row.feasible)
            .reduce((prev, row) => ({ ...prev, [row.id]: true }), {});

        const batteryData = Object.entries(currentBlocks.battery_sizing).map(
          ([model, data]) => ({ ...vehLookup[model], data: data })
        );

        setData(blockEnergyData);
        setRowSelection(rowSelection);
        setBatteryData(batteryData);

        //wait a brief moment for all prior computations to finish
        setTimeout(() => setDataFetchError(true), [50]);
      } catch (e) {
        if (e.response)
          errorHandler(e, snackBarElement, "Failed to fetch resources");
        else {
          snackBarElement.current.displayToast(
            "Something went wrong in charger data retrieval",
            "warning"
          );
          console.log(e);
        }
        setDataFetchError(true);
      }
    }

    fetchData();
  }, []);

  /** "run block scheduling" button-
   * gathers all the selected input blocks (rows) and
   * sends them to the backend API for processing/scheduling
   * and stores the response (s25d) into indexDb
   * @param {*} event
   */
  async function handleBlockScheduling(event) {
    if (!Object.values(rowSelection).some((i) => i)) {
      snackBarElement.current.displayToast("No Rows Selected", "warning");
      return;
    }
    if (!inputs.charger_models?.length) {
      snackBarElement.current.displayToast("No Chargers Selected", "warning");
      return;
    }

    let selectedRowsList = data.filter((row) => rowSelection[row.id]);
    const inFeasibleExists = selectedRowsList.some((row) => row.feasible != 0);

    // should check if infeasible exists and user has made the choice
    // if infeasible exists and the choice is not made then
    // (a) we show dialog (b) let user make choice (c) modify the data (d) update a flag that choice is made (e) re-run the function
    if (inFeasibleExists && feasibleChoice.current == 0) {
      //if infeasible rows are selected, open a dialog to determine what the user wants to do with the infeasible rows
      setOpenFeasibleDialog(true);
      return;
    } else if (inFeasibleExists && feasibleChoice.current == 2) {
      // feasibleChoice.current= 2 => "Remove these Blocks from the Electrification Plan and Maintain ICE operation for them"
      // if user chose to remove infeasible blocks from selection, filter them out
      selectedRowsList = selectedRowsList.filter((row) => row.feasible == 0);
    }
    if (selectedRowsList.length == 0) {
      //user chose to discard infeasible rows, and all selected rows were infeasible, display error message and cancel
      snackBarElement.current.displayToast(
        "No feasible Rows were selected",
        "error",
        5000
      );
      return;
    }

    //update the data's checked status to match new selected, mainly for rapid lookup on the following forEach loop
    const newRowSelection = selectedRowsList.reduce(
      (selection, row) => ({ ...selection, [row.id]: true }),
      {}
    );
    //update the row.checked status to match the currently selected rows
    data.forEach((row) => (row.checked = Boolean(newRowSelection[row.id])));

    setButtonLoading(true);

    const body = {
      charger_models: inputs.charger_models,
      depot_id: selectedRowsList[0].endDepot,
      settings: {
        bev_settings: {
          battery_lifetime: routeEnergyInputs.operating_year, //operating year from the routeBlockDefiniton page
          ...inputs?.settings?.bev_settings,
        },
      },
      blocks: selectedRowsList.map((block) => ({
        //puts the selectedRows in the desired format for fleet-and-charger-sizing api
        blockId: block.blockId,
        dh_st_time: block.dh_st_time,
        dh_end_time: block.dh_end_time,
        distance: block.distance,
        startDepot: block.startDepot,
        endDepot: block.endDepot,
        vehicleEff: block.detailed_energy.updated_efficiency,
        vehicleModel: block.vehicleModel,
      })),
    };

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.battery_sizing.fleet_and_charger_sizing_analysis}`,
    };

    fetch(fleetSizingURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          response.json().then(async ({ data: responseData }) => {
            storeLocalData("fleetSizing", {
              data: responseData,
              input: { row: 0 },
            });

            /**
             * overwrite the old battery indexDb with the current one, so that we know which rows were
             * selected on future pages (fleetChargerSizing and LoadProfile)
             */
            storeLocalData("battery", {
              data: { blocks: data, battery_sizing: batterySizing },
              input: inputs,
            });

            //Save the data on the backend Body
            const backendBody = {
              id: sim.id, //The simulation ID
              current_page: stepInfo[STEP_NUMBER + 1].route,
              steps: {
                fleetSizing: responseData,
                battery: {
                  //save the selected Battery rows on backend
                  blocks: data,
                  battery_sizing: batterySizing,
                },
                input: {
                  battery: inputs,
                  fleetSizing: { row: 0 },
                },

                //clear out future pages' backend data
                evAssessment: {},
                financial: {},
                tco: {},
              },
              completed: false,
            };

            //clear out the future pages' frontend data
            partialClearLocalData(["evAssessment", "financial", "tco"]);

            fetch(simulationURL, {
              method: "PATCH",
              headers: headers,
              body: JSON.stringify(backendBody),
            })
              .then((response) => {
                if (response.ok) {
                  snackBarElement.current.displayToast(
                    `${stepInfo[STEP_NUMBER].label} Analysis Complete`,
                    "success"
                  );
                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Error Sending Data to Backend"
                  );
                  setButtonLoading(false);
                }
              })
              .catch((e) => {
                snackBarElement.current.displayToast(
                  "Error Sending Data to Backend",
                  "error"
                );
                setButtonLoading(false);
                console.log("error", e);
              });
          });
        } else {
          errorHandler(
            response,
            snackBarElement,
            `Error running ${stepInfo[STEP_NUMBER + 1].label} Analysis`
          );
          setButtonLoading(false);
        }
      })
      .catch((e) => {
        snackBarElement.current.displayToast(
          "Error Calculating Fleet Sizing",
          "error"
        );
        setButtonLoading(false);
        console.log("error", e);
      });
  }

  function handleOptionsClose() {
    setOptionsAnchorEl(null);
    setOptionsOpen(0);
  }

  /**
   * saves the advanced options inputs
   * @param {SubmitEvent} event
   */
  function handleOptionsSubmit(event) {
    event.preventDefault();
    const newInputs = updateIndexDb("battery", event);
    setInputs(newInputs);
    storeLocalData("battery", { input: newInputs });
    handleOptionsClose();
  }

  /**
   * manages the submission of the "charger options inputs" button
   * @param {*} event
   */
  function handleChargerSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //NOTE: data keys are the selected charger names, but data's values (unused) are the entire selected charger objects
    const newSelectedChargers = Array.from(data.keys());
    if (!newSelectedChargers.length) {
      //if no chargers were selected
      snackBarElement.current.displayToast(
        "Must Select at Least 1 Charger",
        "warning"
      );
      return;
    }

    //array equality check
    if (
      newSelectedChargers.every(
        (charger, index) => charger == inputs.charger_models[index]
      ) &&
      inputs.charger_models.length == newSelectedChargers.length
    ) {
      //if the selected chargers haven't been altered from original, close the box and cancel the function
      handleOptionsClose();
      return;
    }

    setInputs({ ...inputs, charger_models: newSelectedChargers });
    handleOptionsClose();

    //update the local frontend with the new selected Chargers
    storeLocalData("battery", { input: inputs });
  }

  /**
   * should fire when closing EITHER row edit or bulk edit,
   * resets the column visibility to state that it was prior to edit
   */
  function generalEditClose() {
    setEnableHiding(true); //prevents users from hiding editable columns during edits
    if (originalColumnVisibility.current != null) {
      //if the column visisbility was automatically altered to include hidden editable columns, restore it to original form
      setColumnVisibility(originalColumnVisibility.current);
      originalColumnVisibility.current = null;
    }
  }

  /**
   * should fire when opening either bulk or row edit,
   * ensures all editable columns are visible, and if they're not,
   * it also saves the original visibility status for when edit is closed
   * @param {MRT.MRT_Row<MRT.TData> | null | undefined} [row] optional param used in row edit change, to determine that the row wasn't just closed
   * @param {boolean} [isBulk] technically, this field is unnecessary, due to the lack of true bulk edit on this page, but left in for consistency
   */
  function generalEditOpen(row = undefined, isBulk = true) {
    if (row !== null || isBulk) setEnableHiding(false); //prevents users from hiding editable columns during edits, use if statement to avoid running when canceling a row edit
    const editableCols = mainColumns.filter(
      (col) => col.enableEditing != false
    ); // != false is important, as field is not defined as true
    if (
      editableCols.some(
        (col) => columnVisibility?.[col.accessorKey] == false
      ) &&
      row !== null
    ) {
      originalColumnVisibility.current = { ...columnVisibility };
      setColumnVisibility((prev) => ({
        ...prev,
        ...editableCols.reduce(
          (colVis, col) => ({ ...colVis, [col.accessorKey]: true }),
          {}
        ),
      }));
    }
  }

  function onEditingRowClose() {
    // setValidationErrors({});
    setEditingRow(null);
    generalEditClose();
  }

  /**
   * @param {{ exitEditingMode: () => void, row: import("material-react-table").MRT_Row<never>, table: MRT_TableInstance<never>, values: Record<string & Record<never, never>, any>}} props
   */
  async function onEditingRowSave({ exitEditingMode, row, table, values }) {
    if (
      row.getValue("batter_size") == row.original.batter_size &&
      row.original.feasible == 0
    ) {
      snackBarElement.current.displayToast(
        "Battery Size not altered",
        "warning",
        5000
      );
      return;
    }
    if (!row.getValue("batter_size")) {
      //note: this shouldn't happen
      snackBarElement.current.displayToast(
        "Must select a battery size",
        "warning",
        5000
      );
      return;
    }

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.route_energy.battery_sizing_analysis}`,
    };

    //note: finds the vehicle model with the matching type, size, and batter_size
    const selectedVehicleModel = Object.entries(
      chargerUpdateOptionsLookup[
        `t_${row.getValue("veh_type")}_s_${row.getValue("size")}`
      ]
    ).find(
      ([_model, batter_size]) => batter_size == row.getValue("batter_size")
    )[0];

    const body = JSON.stringify({
      block_schedule: [row.original],
      operating_year: routeEnergyInputs.operating_year,
      vehicle_models: [selectedVehicleModel],
    });

    fetch(batteryURL, { method: "PATCH", headers, body })
      .then((res) => {
        if (res.ok) {
          res.json().then((responseData) => {
            const newRow = responseData[0];
            const index = data.findIndex((row) => row.id == newRow.id);

            data[index] = {
              ...newRow,
              batter_size: row.getValue("batter_size"), // batter_size was manually selected by user, no need for vehicleInfoLookup
            };

            //update the table's checked status before saving to indexDb
            data.forEach(
              (row) => (row.checked = Boolean(rowSelection[row.id]))
            );

            storeLocalData("battery", {
              data: { blocks: data, battery_sizing: batterySizing },
            });

            setData([...data]);
            exitEditingMode();
            onEditingRowClose();
          });
        } else
          errorHandler(res, snackBarElement, "Failed to update battery size");
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network error: failed to update battery size",
          "error",
          5000
        );
      });
  }

  /**
   * bulk PATCH battery sizing handler
   * @param {React.FormEvent<HTMLDivElement>} event
   */
  async function handleGroupEditSave(event) {
    event.preventDefault();
    const newDataForm = new FormData(event.target);
    //gets the status of the isAllRows toggle, and then deletes the value from the form
    const isAllRows = Boolean(newDataForm.get("isAllRows"));
    newDataForm.delete("isAllRows");
    const oldVehicleModel_newVehicleModelLookup = {};
    //note: iterator.filter() doesn't work in safari/firefox, hence manual implemention of filter
    for (const [oldModel, newModel] of newDataForm.entries())
      if (newModel != "unaltered")
        oldVehicleModel_newVehicleModelLookup[oldModel] = newModel;

    const newVehicleModels = Object.values(
      oldVehicleModel_newVehicleModelLookup
    );

    if (!newVehicleModels.length) {
      snackBarElement?.current?.displayToast(
        "Battery Sizes were not changed",
        "info"
      );
      setGroupEditOpen(false);
      return;
    }
    setButtonLoading(true);

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
      Accept: `application/json; version=${sim.analysis_type_steps.route_energy.battery_sizing_analysis}`,
    };

    const body = JSON.stringify({
      block_schedule: data.filter(
        (row) =>
          //filter out unaltered rows, and (if user only wants to alter selected rows) rows that aren't selected
          row.vehicleModel in oldVehicleModel_newVehicleModelLookup &&
          (isAllRows || rowSelection[row.id])
      ),
      operating_year: routeEnergyInputs.operating_year,
      vehicle_models: newVehicleModels,
    });

    fetch(batteryURL, { method: "PATCH", headers, body })
      .then((res) => {
        if (res.ok) {
          res.json().then((responseData) => {
            responseData.forEach((row) => {
              row.checked = Boolean(rowSelection[row.id]);
              rowDataConversion(
                row,
                vehicleInfoLookup,
                routeEnergyInputs?.operating_year
              );
            });
            const newDataLookup = {};
            responseData.forEach((row) => (newDataLookup[row.id] = row));
            const newData = data.map((row) => newDataLookup[row.id] ?? row);
            storeLocalData("battery", {
              data: { blocks: newData, battery_sizing: batterySizing },
            });

            setData(newData);
            setGroupEditOpen(false);
          });
        } else
          errorHandler(res, snackBarElement, "Failed to update battery sizes");
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "Network error: failed to update battery sizes",
          "error",
          5000
        );
      })
      .finally(() => setButtonLoading(false));
  }

  /**
   * @param {HTMLFormElement} event
   */
  function handleFeasibleChoiceSubmit(event) {
    event.preventDefault();

    const newDataForm = new FormData(event.target);
    const newDataJSON = Object.fromEntries(newDataForm.entries());

    feasibleChoice.current = +Object.values(newDataJSON)[0] || 0;

    if (feasibleChoice.current == 0) {
      snackBarElement.current.displayToast(
        "You must select an option to continue, or change data selection",
        "info",
        7500
      );
      return;
    } else {
      snackBarElement.current.displayToast("", "info", 0); //clears the snackbar alerts, if there were any being displayed
    }

    handleBlockScheduling();
    setOpenFeasibleDialog(false);
  }

  /**
   * the tour for the page's primary material-react-table
   */
  const handleMainTourStart = () => {
    setSelectedChip("block");
    setTimeout(() => {
      //wait a 10th of a second, in case of table re-render
      setIsTourActive(true);
      let intro = introJs();
      intro
        .setOptions(batterySizingTableRouteTourOptions())
        .onbeforechange(() => {
          //   if (intro._currentStep > 3 && intro._currentStep < 6)
          //     setSelectedChip("battery");
          //   else setSelectedChip("energy");

          //this relocates the element for the step that can be lost in between page re-renders (the elements that are lost to a re-render should be outlined in the tourOption's steps)
          //see https://github.com/usablica/intro.js/issues/328#issuecomment-646445137 for more info
          if (intro._options.steps[intro._currentStep]?.target)
            intro._introItems[intro._currentStep].element =
              document.querySelector(
                intro._options.steps[intro._currentStep]?.target
              );
        })
        .onexit(() => setIsTourActive(false))
        .start();
    }, [100]);
  };

  /**
   * the tour for the charger options dialog
   */
  const handleChargerOptionsTour = () => {
    setIsTourActive(true);
    let tour = introJs();
    tour
      .setOptions(chargerTourOptions())
      .onexit(() => setIsTourActive(false))
      .start();
  };

  /**
   * defines the column structure of the table
   */
  const batteryColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => {
      const vehTypeFilterOptionsSet = new Set(
        batteryData.map((row) => +row.type)
      ); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        // {
        //   header: "Vehicle ID",
        //   accessorKey: "model",
        // },
        {
          header: "Vehicle Type",
          accessorKey: "type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "select",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
        },
        {
          header: "Vehicle Size",
          accessorKey: "size",
          Cell: ({ row, cell }) =>
            row.getValue("type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : cell.getValue() == 14 ? (
              "Transit"
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("type") == 4
              ? `Type ${row.getValue(columnId)}`
              : row.getValue(columnId) == 14
              ? "Transit"
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
        },
      ];
    },
    [batteryData]
  );

  /**
   * defines the column structure of the table
   */
  const mainColumns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */
    () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          header: getLabel("block_id_original"),
          accessorKey: "block_id_original",
          accessorFn: (row) => row.block_id_original ?? row.blockId,
          visibleInShowHideMenu: data.some((i) => i.block_id_original),
          enableEditing: false,
        },
        {
          header: getLabel("block_id"),
          accessorKey: "blockId",
          enableEditing: false,
        },
        {
          header: "Depot Depart Time",
          accessorKey: "dh_st_time",
          Cell: ({ cell }) => MFM_to_AMPM(cell.getValue()),
          filterFn: (row, columnId, filterValue) =>
            MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
          enableEditing: false,
        },
        {
          header: "Depot Arrive Time",
          accessorKey: "dh_end_time",
          Cell: ({ cell }) => (
            <>
              {MFM_to_AMPM(cell.getValue())}{" "}
              {cell.getValue() >= 1440 ? (
                <Tooltip title="Arrival time is on the next day">
                  {unitWrapper("Next Day", { style: { color: "blue" } })}
                </Tooltip>
              ) : (
                ""
              )}
            </>
          ),
          filterFn: (row, columnId, filterValue) =>
            MFM_to_AMPM(row.getValue(columnId)).indexOf(filterValue) != -1,
          enableEditing: false,
        },
        // {
        //   header: "Vehicle ID",
        //   accessorKey: "vehicleModel",
        // },
        {
          header: "Vehicle Type",
          accessorKey: "veh_type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "select",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } }, //sx: { width: "calc(var(--header-veh_type-size)* 1px)" },
          enableEditing: false,
        },
        {
          header: "Vehicle Size",
          accessorKey: "size",
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : cell.getValue() == 14 ? (
              "Transit"
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : row.getValue(columnId) == 14
              ? "Transit"
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
          enableEditing: false,
        },
        {
          header: "Total Energy",
          accessorKey: "detailed_energy.total_energy",
          accessorFn: (row) => roundNumber(row.detailed_energy.total_energy),
          units: "kWh", //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
          enableEditing: false,
        },
        {
          Header: (
            <>
              Needed
              <br />
              Battery&nbsp;Size
              <br />
              (per&nbsp;{getLabel("block").toLowerCase()})
            </>
          ),
          header: "Needed Battery Size",
          accessorKey: "battery_detailed.minimum_battery_size",
          accessorFn: (row) =>
            roundNumber(row.battery_detailed.minimum_battery_size, 0),
          units: "kWh", //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("kWh")}
            </>
          ),
          enableEditing: false,
        },
        {
          Header: <>Available Battery&nbsp;Size (fleet&nbsp;wide)</>,
          header: "Available Battery Size",
          accessorKey: "batter_size",
          accessorFn: (row) =>
            row.feasible == 0 ? row.batter_size : "None Available",
          enableHiding,
          units: "kWh", //used for exporting table
          Cell: ({ cell, row }) =>
            row.original.feasible == 0 ? (
              <>
                {cell.getValue()} {unitWrapper("kWh")}
              </>
            ) : (
              cell.getValue()
            ),
          editVariant: "select",
          muiEditTextFieldProps: {
            SelectProps: {
              MenuProps: { PaperProps: { sx: { maxHeight: "30%" } } },
            },
          },
          editSelectOptions: ({ row }) => {
            //todo: consider combining this function with the Selected Battery Cell in batterySizingGroupEdit.js
            const key = `t_${row.getValue("veh_type")}_s_${row.getValue(
              "size"
            )}`;
            const options =
              key in chargerUpdateOptionsLookup
                ? Object.values(chargerUpdateOptionsLookup[key])
                : [];
            return options.sort().map((batter_size) => ({
              value: batter_size,
              label: `${batter_size} kWh`,
            }));
          },
        },
        {
          header: `${getLabel("block")} Distance`,
          accessorKey: "distance",
          accessorFn: (row) => roundNumber(unitMiles(row.distance), 1),
          units: unitLargeAbbr[units], //used for exporting table
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
            </>
          ),
          enableEditing: false,
        },
        {
          header: "Year 1 Battery Range",
          accessorKey: "mileage",
          accessorFn: (row) => (row.feasible == 0 ? row.mileage : " - "),
          units: unitLargeAbbr[units], //used for exporting table
          Cell: ({ cell, row }) =>
            row.original.feasible == 0 ? (
              <>
                {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
              </>
            ) : (
              cell.getValue()
            ),
          enableEditing: false,
        },
        {
          header: `Year ${routeEnergyInputs?.operating_year} Battery Range`,
          accessorKey: "nth_mileage",
          accessorFn: (row) => (row.feasible == 0 ? row.nth_mileage : " - "),
          units: unitLargeAbbr[units], //used for exporting table
          Cell: ({ cell, row }) =>
            row.original.feasible == 0 ? (
              <>
                {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
              </>
            ) : (
              cell.getValue()
            ),
          enableEditing: false,
        },
      ];
    },
    [chargerUpdateOptionsLookup, enableHiding]
  );

  /** @returns {import("material-react-table").MRT_TableOptions<never} */
  const sharedTableProps = () => ({
    ...materialReactTableOptions(),
    state: {
      ...materialReactTableOptions().state,
      isLoading: !dataFetchError,
    },
    // toolbar additions
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Stack id="route-fleet-stack" direction="row" spacing={1} width="100%">
          <Chip
            label={`Battery - ${getLabel("block")}`}
            variant={selectedChip == "block" ? "filled" : "outlined"}
            disabled={!table.getCoreRowModel().rows.length}
            onClick={() => setSelectedChip("block")}
          />
          <Chip
            label="Battery - Fleet Wide"
            variant={selectedChip == "fleet" ? "filled" : "outlined"}
            disabled={!table.getCoreRowModel().rows.length}
            onClick={() => setSelectedChip("fleet")}
          />
        </Stack>
        <Box>
          <Chip
            variant="outlined"
            onClick={() => setGroupEditOpen(true)}
            hidden={!table.getCoreRowModel().rows.length}
            label="Update Battery Sizing"
          />
        </Box>
        <Box>
          <TourBeacon
            onClick={handleMainTourStart}
            hidden={
              isTourActive ||
              !table.getCoreRowModel().rows.length ||
              !accessRights.analysis.create_battery_sizing_analysis
            }
          />
        </Box>
      </>
    ),
  });

  // the "battery-fleet wide" table instance
  const batteryTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: batteryData,
    columns: batteryColumns,
    //only 3 columns, don't need to hide/pin
    enableHiding: false,
    enableColumnPinning: false,
    // detail panel (nested table) options
    enableExpandAll: true,
    renderDetailPanel: ({ row }) => <BatteryChild rowData={row.original} />,
    muiDetailPanelProps: (rest) => {
      const props = parseFromValuesOrFunc(
        sharedTableProps().muiDetailPanelProps,
        rest
      );
      return {
        ...props,
        sx: (theme) => ({
          ...parseFromValuesOrFunc(props?.sx, theme),
          padding: 0,
        }),
      };
    },
    muiTableBodyRowProps: (rest) => {
      const props = parseFromValuesOrFunc(
        sharedTableProps().muiTableBodyRowProps,
        rest
      );
      return {
        ...props,
        onClick: () => rest.row.toggleExpanded(), // allows user to click anywhere on row to open/close the batteryChild
        sx: (theme) => ({
          ...parseFromValuesOrFunc(props?.sx, theme),
          cursor: "pointer",
        }),
      };
    },
  });

  // the "battery- block" table instance
  const mainTable = useMaterialReactTable({
    ...sharedTableProps(),
    data: data,
    columns: mainColumns,
    state: {
      ...sharedTableProps().state,
      rowSelection,
      showAlertBanner: Object.values(rowSelection).some((i) => i),
      editingRow,
      columnVisibility,
    },
    //miscellaneous options
    onColumnVisibilityChange: setColumnVisibility,
    muiTableBodyRowProps: ({ row, ...rest }) => {
      const props = parseFromValuesOrFunc(
        sharedTableProps().muiTableBodyRowProps,
        { row, ...rest }
      );
      return {
        ...props,
        sx: (theme) => ({
          ...parseFromValuesOrFunc(props?.sx, theme),
          backgroundColor: row.original.feasible == 1 ? "#e5737380" : undefined, //makes infeasible rows red
          "&.Mui-selected,&.Mui-selected:hover": {
            backgroundColor:
              row.original.feasible == 1 ? "#e5737350" : undefined,
          },
        }),
      };
    },

    //selection settings
    enableRowSelection: true,
    getRowId: (row) => row.id, //note: I assign the ID at the start, though in theory, blockID and model could be used as alternates for the ID. but, left ID to be consistent with other page's implementations
    onRowSelectionChange: setRowSelection,

    // row edit settings
    enableEditing: true,
    editDisplayMode: "row",
    onEditingRowChange: (row) => {
      generalEditOpen(row, false);
      setEditingRow(row ? { ...row } : null);
    },
    onEditingRowSave: onEditingRowSave,
    onEditingRowCancel: onEditingRowClose,

    //add download option to header
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {sharedTableProps().renderTopToolbarCustomActions({ table })}
        <MRT_DownloadButton
          table={table}
          fileName={stepInfo[STEP_NUMBER].label}
          disabled={!data.length}
        />
      </>
    ),
  });

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          style={{ fontWeight: 600, marginRight: "1%" }}
        >
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle setBatteryInputs={setInputs} />
        <Chip
          label={viewNameLookup[showTable]}
          onClick={(e) => setViewAnchorEl(e.currentTarget)}
          sx={{ minWidth: "6rem" }}
        />
        <Menu
          open={Boolean(viewAnchorEl)}
          anchorEl={viewAnchorEl}
          onClose={() => setViewAnchorEl(null)}
          PaperProps={{
            style: { minWidth: viewAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(viewNameLookup).map(([key, label]) => (
            <MenuItem
              key={`ShowTable_${key}_Option`}
              value={key}
              onClick={(e) => {
                updateSubheader(data, routeEnergyInputs?.operating_year);
                setShowTable(e.currentTarget.value);
                setViewAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          {showTable == 0 ? (
            selectedChip == "block" ? (
              <MaterialReactTable table={mainTable} />
            ) : (
              <MaterialReactTable table={batteryTable} />
            )
          ) : (
            // creates a graph with all selected rows (if no rows are selected, then graph contains all rows)
            <BatterySizingGraph
              allData={
                Object.values(rowSelection).some((i) => i)
                  ? data.filter((row) => rowSelection[row.id])
                  : data
              }
              routeEnergyInputs={routeEnergyInputs}
              vehicleInfoLookup={vehicleInfoLookup}
              updateSubheader={updateSubheader}
            />
          )}
        </Paper>
      </Container>
      <br />
      <br />
      <Container>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          sx={{ width: "100%", margin: "0 auto" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                component={Link}
                to={stepInfo[STEP_NUMBER - 1].route}
                startIcon={<ArrowBackIosNew />}
              >
                Previous Step: {stepInfo[STEP_NUMBER - 1].label}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                id="charger-options-button"
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                onClick={(e) => setOptionsAnchorEl(e.currentTarget)}
              >
                Options
              </Button>
              <Menu
                anchorEl={optionsAnchorEl}
                open={Boolean(optionsAnchorEl)}
                onClose={() => setOptionsAnchorEl(null)}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "center",
                }}
                PaperProps={{
                  style: { minWidth: optionsAnchorEl?.clientWidth },
                }} // makes the dropdown the same size as the button
              >
                <MenuItem
                  disabled={!(data.length > 0)}
                  onClick={() => setOptionsOpen(1)}
                >
                  Charger Options
                </MenuItem>
                <MenuItem
                  disabled={!(data.length > 0)}
                  onClick={() => setOptionsOpen(2)}
                >
                  Advanced Options
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <NextPageButton
            id="next-page"
            onClick={handleBlockScheduling}
            disabled={
              //check that at least 1 selected row is true
              !Object.values(rowSelection).some((i) => i) ||
              !accessRights.analysis.create_fleet_and_charger_sizing_analysis
            }
            loading={buttonLoading}
          >
            Run {stepInfo[STEP_NUMBER + 1].label} Analysis
          </NextPageButton>
        </Stack>
      </Container>

      <Dialog
        component="form"
        open={openFeasibleDialog}
        onSubmit={handleFeasibleChoiceSubmit}
        onClose={() => {
          feasibleChoice.current = 0;
          setOpenFeasibleDialog(false);
        }}
        maxWidth="lg"
      >
        <FeasibleSubmissionDialog
          initialFeasibleChoice={feasibleChoice.current}
        />
        <DialogActions>
          <Button
            onClick={() => {
              feasibleChoice.current = 0;
              setOpenFeasibleDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button disabled={!feasibleChoice} type="submit">
            Save and Continue
          </Button>
        </DialogActions>
      </Dialog>
      {/*  Charger Options Selection table pop-up*/}
      <Dialog
        maxWidth="md"
        fullWidth
        open={optionsOpen == 1}
        onClose={handleOptionsClose}
      >
        {/* this formID is also used in the chargerOptions.js make sure it matches if it is changed */}
        <form id="ChargerOptionsForm" onSubmit={handleChargerSubmit} />
        <DialogTitle display="flex" justifyContent="space-between">
          Charger Options
          <TourBeacon
            hidden={
              isTourActive ||
              !accessRights.analysis.create_battery_sizing_analysis
            }
            onClick={handleChargerOptionsTour}
          />
        </DialogTitle>
        {optionsOpen == 1 ? ( //this if-else statement is really only here to suppress a "defaultChecked has been altered" warning on submit
          <DialogContent>
            <DialogContentText sx={{ textAlign: "center" }}>
              Typical Charger Rating for{" "}
              {TYPE_STRINGS.PROJECT_TYPE[currentProject?.type]} Fleet
              <br />
              {projectChargerRange[currentProject?.type][0]} {unitWrapper("kW")}{" "}
              - {projectChargerRange[currentProject?.type][1]}{" "}
              {unitWrapper("kW")}
            </DialogContentText>
            <br />
            <ChargerOptions
              allChargers={allChargers}
              selectedChargers={inputs?.charger_models || []}
            />
            <br />
          </DialogContent>
        ) : (
          "Loading..."
        )}
        <DialogActions>
          <DialogContentText id="new-charger-email" pr="55px">
            Not Seeing Your Charger Ratings? Email{" "}
            <a
              href={
                "mailto:EVOPTSupport@microgridlabs.com?subject=" +
                "New Charger Request" +
                "&body=" +
                "I'd like a new charger to be added for my project in EVOPT." +
                "%0D%0A" +
                "I am looking to use a **INSERT MANUFACTURERS NAME** charger with a rating of **INSERT RATING HERE**." +
                "%0D%0A%0D%0A" + //two newlines
                "Thank You!"
              }
              target="_blank"
            >
              EVOPTSupport@microgridlabs.com
            </a>
            .
          </DialogContentText>
          <span id="cancel-submit-buttons">
            <Button onClick={handleOptionsClose}>Cancel</Button>
            <Button type="submit" form="ChargerOptionsForm">
              Save and Continue
            </Button>
          </span>
        </DialogActions>
      </Dialog>

      {/* Advanced Options dialog */}
      <Dialog
        component="form"
        fullWidth
        open={optionsOpen === 2}
        onSubmit={handleOptionsSubmit}
        onClose={handleOptionsClose}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Advanced Options
        </DialogTitle>
        <DialogContent>
          <BatteryAdvancedOptions inputs={inputs} project={currentProject} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOptionsClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>

      {/* The "Update Battery Sizing" dialog box */}
      <Dialog
        component="form"
        open={groupEditOpen}
        onSubmit={handleGroupEditSave}
        onClose={() => setGroupEditOpen(false)}
        maxWidth="xl"
      >
        <DialogTitle display="flex" justifyContent="space-between">
          Update Battery Sizing
          <span style={{ display: "flex", alignItems: "center" }}>
            <Typography>Edit Selected Rows</Typography>
            <Switch name="isAllRows" />
            <Typography>Edit All Rows</Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <BatterySizingGroupEdit
            allData={data}
            rowSelection={rowSelection}
            chargerUpdateOptionsLookup={chargerUpdateOptionsLookup}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupEditOpen(false)}>Cancel</Button>
          <LoadingButton loading={buttonLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* The loading screen that appears for 3 seconds after the data has been sent to backend */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={buttonLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}
