import Edit from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip, lighten } from "@mui/material";

/**
 * common options shared between all pages
 * note: materialReactTableOptions is function, to avoid the risk of cross-contamination of the nested objects, like initialState
 * (for an example, see the columnOrdering overwrite here: https://github.com/KevinVandy/material-react-table/blob/v2/packages/material-react-table/src/hooks/useMRT_TableInstance.ts#L71)
 *
 * @returns {import("material-react-table").MRT_TableOptions<never>} */
const materialReactTableOptions = () => ({
  enableColumnPinning: true, //mainly so that checkboxes can be auto-pinned
  initialState: {
    columnPinning: { left: ["mrt-row-actions"] }, //pins the checkboxes, if there are any
    density: "compact", //makes the columns as tightly packed as possible
  },
  //style options
  renderToolbarAlertBannerContent: ({ groupedAlert, selectedAlert, table }) => {
    //note: selectedAlert is the default implementation, but it doesn't match what we want, so we're ignoring it
    const { localization, rowCount } = table.options; //grab the localization, for possible translations implemented down the line
    const selectedRowCount = Object.values(
      table.getState().rowSelection
    ).filter(Boolean).length;
    const allRowsCount = table.getCoreRowModel().rows.length;

    const customSelectedAlertMessage =
      selectedRowCount > 0
        ? localization.selectedCountOfRowCountRowsSelected
            ?.replace("{selectedCount}", selectedRowCount.toLocaleString())
            ?.replace("{rowCount}", (rowCount ?? allRowsCount).toLocaleString())
            ?.replaceAll(" ", "\xa0") // makes all spaces non-breaking
        : null;

    return (
      <Stack sx={{ p: "0rem 1.25rem" }}>
        <Box display="flex">
          {customSelectedAlertMessage}
          {groupedAlert}
        </Box>
      </Stack>
    );
  },
  muiToolbarAlertBannerProps: {
    sx: (theme) => ({
      "& .MuiAlert-message": { width: "98%" },
      fontWeight: 600,
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }),
  },
  muiTableHeadCellProps: {
    sx: { verticalAlign: "middle" }, // vertically centers the column headers, in the case of multi-line headers
    // align: "center", //note: centering horizontally expands the column width
  },
  // muiTableBodyCellProps: { align: "center" }, //note: centering horizontally expands the column width
  // muiTableFooterCellProps: { align: "center" }, //note: centering horizontally expands the column width
  defaultColumn: { size: 5 }, //shrinks the columns down to the widest displayed column value
  muiFilterTextFieldProps: { sx: { minWidth: 1 } }, //shrinks the filter column inputs down to the smallest possible size, to match the column's width
  // muiToolbarAlertBannerProps: { color: "secondary" }, //intended to lighten the "n rows selected" banner to more closely match the blue of the site, but it did not work :(
  //selection options
  // getRowId: (row) => row.id, //good for the selection lookups (not sure about this one being a default yet, might uncomment later)
  // enableMultiRowSelection: true, //when row selection is true, allows for multiple rows to be selected at a time (unnecessary, as true is the default value)
  selectAllMode: "all", //when row selection is true, enables a "select `all rows" checkbox
  //miscellaneous functionality options
  // enableColumnDragging: true,//allows user to drag columns into whatever order they prefer (main downside is increased column width caused by icon)
  //disabled feature options
  enableGlobalFilter: false, //prevents global sorting, since it doesn't really mesh well with the individual column filtering functions
  positionGlobalFilter: "left", //puts the "search" textfield on the left (if/when global filtering is enabled), so that it doesn't overlap with bulk edits (or other toolbar additions)
  enableDensityToggle: false, //prevents users from altering the table toggle density (hard-setting density to compact)
  muiSearchTextFieldProps: { inputProps: { sx: { minWidth: "7em" } } },
  // enableFullScreenToggle: false, // removes the ability to turn on fullscreen.
  //pagination options
  muiPaginationProps: { rowsPerPageOptions: [5, 10, 20, 30] },
  //edit icon (this makes the icon a bit darker)
  renderRowActions: ({ row, table, children, ...rest }) => (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <Tooltip title="Edit">
        {/* note: this onclick is functionally identical to saying onEditingRowChange(row) */}
        <IconButton onClick={() => table.setEditingRow(row)}>
          <Edit />
        </IconButton>
      </Tooltip>
      {children}
    </Box>
  ),
});

export default materialReactTableOptions;
