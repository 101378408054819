import { ThemeProvider } from "@mui/material/styles";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import "intro.js/introjs.css"; //tour styling
import { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminAnalysisView from "./components/adminPages/adminAnalysisView";
import AdminProjectView from "./components/adminPages/adminProjectView";
import CustomerView from "./components/adminPages/customerView";
import LicensesView from "./components/adminPages/licensesView";
import MemberView from "./components/adminPages/memberView";
import OrganizationProfile from "./components/adminPages/organizationProfile";
import PartnerView from "./components/adminPages/partnerView";
import UserProfile from "./components/adminPages/userProfile";
import AssessmentAnalyses from "./components/assessmentPages";
import ForgotPassword from "./components/auth/forgotPassword";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ResetPassword from "./components/auth/resetPassword";
import SelectMembership from "./components/auth/selectMembership";
import UseAuth from "./components/auth/useAuth";
import CompareAnalyses from "./components/comparisonPages";
import TermsConditions from "./components/dialogs/termsConditions";
import FleetInput from "./components/fleetInput";
import MasterData from "./components/masterData";
import Depot from "./components/projectPages/depot";
import Project from "./components/projectPages/project";
import ResourceInventory from "./components/projectPages/resourceInventory";
import Footer from "./components/secondary/footer";
import Header from "./components/secondary/header";
import HomePage from "./components/secondary/homePage";
import NotAuthorized from "./components/secondary/notAuthorized";
import NotFound from "./components/secondary/notFound";
import SnackbarAlert from "./components/secondary/snackBar";
import Simulations from "./components/simulations";
import { handleLogoutUser } from "./components/utils";
import SnackBarContextProvider from "./contexts/snackBarContext";
import "./print-pdf.css";
import theme from "./static/constants/theme";

//global chartjs registration and custom items
Tooltip.positioners.mouse = function (_items, eventPosition) {
  return eventPosition;
};

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  zoomPlugin,
  annotationPlugin
);

function App() {
  const snackBarElement = useRef();
  /** prevents non-signed in users from accessing pages,
   * and redirects them to login page */
  function PrivateRoute({ children }) {
    const auth = UseAuth("get");
    if (auth) return children;
    else handleLogoutUser();
  }

  useEffect(() => {
    //sign-in check (checks if user is signed in every 2 seconds)
    //only begins checking if user is logged in on initial render
    if (UseAuth("get")) {
      let intervalTimer = setInterval(() => {
        const auth = UseAuth("get");
        if (!auth) {
          if (snackBarElement.current) {
            snackBarElement.current.displayToast(
              "Logging out, session expired",
              "info",
              1000
            );
          }
          clearInterval(intervalTimer);
          setTimeout(() => {
            handleLogoutUser();
          }, 1000);
        }
      }, 2000);

      return () => {
        clearInterval(intervalTimer); // clears interval whenever the component unmounts.
      };
    }
  }, []);

  //contains all the private routes
  const privateRoutes = {
    "select-membership": <SelectMembership />,
    project: <Project />,
    depot: <Depot />,
    "resource-inventory": <ResourceInventory />,
    simulations: <Simulations />,
    profile: <UserProfile />,
    organizationProfile: <OrganizationProfile />,
    masterdata: <MasterData />,
    partner: <PartnerView />,
    customer: <CustomerView />,
    members: <MemberView />,
    licenses: <LicensesView />,
    "admin-projects": <AdminProjectView />,
    "admin-analysis": <AdminAnalysisView />,
    "fleet-input": <FleetInput />,
    "assessment/*": <AssessmentAnalyses />,
    "compare/*": <CompareAnalyses />,
  };

  return (
    <div className="content-container">
      <ThemeProvider theme={theme}>
        <SnackBarContextProvider>
          <BrowserRouter>
            {/* NOTE: This is a special snackBarElement for just the App.js' stuff */}
            <SnackbarAlert ref={snackBarElement} />
            <Header />
            <div className="App" id="App">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route
                  path="password/reset/confirm"
                  element={<ResetPassword />}
                />

                {Object.keys(privateRoutes).map((key) => (
                  <Route
                    key={key}
                    path={key}
                    element={<PrivateRoute>{privateRoutes[key]}</PrivateRoute>}
                  />
                ))}

                <Route path="notauthorized" element={<NotAuthorized />} />

                <Route path="termsconditions" element={<TermsConditions />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
        </SnackBarContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
