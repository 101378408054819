import { Box, Tooltip, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import "../../../Grid.css";
import {
  jsxToString,
  Military_to_AMPM,
  roundNumber,
  unitWrapper,
} from "../../utils";
import { monthNames } from "../pages/financialAnalysis";
import { colorGradient } from "./drivingActivityTimeTable";

/**
 * component pointing to one of 2 chart views
 * @param {Object} param0
 * @param {Number} param0.selectedChart 5 = energy, 7=Fuel
 * @param {{title: import("react").ReactElement, field: string, unitConversion: (v:number)=>number, perUnitConversion: (v:number)=> number}[]} param0.fieldInfoLookup
 * @param {{ interest: Number, depreciation: Number, discount: Number, energySaved: number, fuelSaved: number, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}} param0.data
 * @param {Number} param0.tariff_style 1 = yearly, 2=monthly, 3 = detailed
 */
export default function FinancialAnalysisCharts(props) {
  if (props.selectedChart == 5)
    return <FinancialAnalysisEnergyChart {...props} />;
  else return <FinancialAnalysisFuelChart {...props} />;
}

/**
 *
 * @param {Object} param0
 * @param {{title: import("react").ReactElement, field: string, unitConversion: (v:number)=>number, perUnitConversion: (v:number)=> number}[]} param0.fieldInfoLookup
 * @param {{ interest: Number, depreciation: Number, discount: Number, energySaved: number, fuelSaved: number, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}} param0.data
 * @param {Number} param0.tariff_style 1 = yearly, 2=monthly
 */
function FinancialAnalysisFuelChart({ data, fieldInfoLookup, tariff_style }) {
  /** @type {import("chart.js").ChartOptions<"line">} */
  const chartOptions = {
    interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
    maintainAspectRatio: false, //allows for variable height
    scales: {
      y: {
        min: 0,
        title: {
          display: true,
          // slice the title to be just the unit display portion
          text: jsxToString(fieldInfoLookup[0].title).slice(
            jsxToString(fieldInfoLookup[0].title).indexOf("(") + 1,
            -1
          ),
          font: { size: 16 },
        },
      },
    },
    plugins: {
      tooltip: {
        position: "nearest", //has the tooltip appear at the point on a line closest to mouse
        callbacks: {
          // slice the title to split the name and unit display portions in 2
          label: ({ dataset: { label }, formattedValue }) =>
            `${label.slice(
              0,
              label.indexOf(" (")
            )}: ${formattedValue} ${label.slice(label.indexOf("(") + 1, -1)}`,
        },
      },
    },
  };

  /** @type {import("chart.js").ChartData<"line", (number | import("chart.js").Point | null)[], unknown>} */
  const fuelChartData = {
    labels: monthNames,
    datasets: fieldInfoLookup.map(({ title, field, unitConversion }) => ({
      label: jsxToString(title),
      data: data.fuelCosts.map((monthlyCost) =>
        roundNumber(unitConversion(monthlyCost[field]), 4)
      ),
    })),
  };

  return <Line data={fuelChartData} options={chartOptions} />;
}

/**
 *
 * @param {Object} param0
 * @param {{ interest: Number, depreciation: Number, discount: Number, energySaved: number, fuelSaved: number, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}} param0.data
 * @param {{title: import("react").ReactElement, field: string, unitConversion: (v:number)=>number, perUnitConversion: (v:number)=> number}[]} param0.fieldInfoLookup
 * @param {Number} param0.tariff_style 1 = yearly, 2=monthly, 3=detailed
 */
function FinancialAnalysisEnergyChart({ data, fieldInfoLookup, tariff_style }) {
  /** @type {import("chart.js").ChartOptions<"line">} */
  const chartOptions = {
    interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
    maintainAspectRatio: false, //allows for variable height
    scales: {},
    plugins: {
      tooltip: {
        position: "nearest", //has the tooltip appear at the point on a line closest to mouse
        callbacks: {
          // slice the title to split the name and unit display portions in 2
          label: ({ dataset: { label }, formattedValue }) =>
            `${label.slice(
              0,
              label.indexOf(" (")
            )}: ${formattedValue} ${label.slice(label.indexOf("(") + 1, -1)}`,
        },
      },
    },
  };

  fieldInfoLookup.forEach(({ title }, i) => {
    chartOptions.scales[`y${i}`] = {
      type: "linear",
      position: i ? "right" : "left",
      min: 0,
      title: {
        display: true,
        text: jsxToString(title),
        color: i ? "rgb(255, 99, 132)" : "rgb(53, 162, 235)",
        font: { size: 16 },
      },
      grid: { drawOnChartArea: !i },
    };
  });

  /** @type {import("chart.js").ChartData<"line", (number | import("chart.js").Point | null)[], unknown>} */
  const energyChartData = {
    labels: monthNames,
    datasets: fieldInfoLookup.map(({ title, field, unitConversion }, i) => ({
      yAxisID: `y${i}`,
      label: jsxToString(title),
      data: data.energyCosts.map((dailyCost) =>
        roundNumber(unitConversion(dailyCost[0][field]), 4)
      ),
    })),
  };

  return tariff_style < 3 ? (
    <Line data={energyChartData} options={chartOptions} />
  ) : (
    <Box display="flex" justifyContent="space-evenly">
      <DetailedEnergyHeatmap
        data={data.energyCosts}
        fieldInfoLookup={fieldInfoLookup}
        tableKey="energy"
      />
      <DetailedEnergyHeatmap
        data={data.energyCosts}
        fieldInfoLookup={fieldInfoLookup}
        tableKey="demand"
      />
    </Box>
  );
}

/**
 *
 * @param {Object} param0
 * @param {{ interest: Number, depreciation: Number, discount: Number, energySaved: number, fuelSaved: number, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}} param0.data
 * @param {{title: import("react").ReactElement, field: string, unitConversion: (v:number)=>number, perUnitConversion: (v:number)=> number}[]} param0.fieldInfoLookup
 */
function DetailedEnergyHeatmap({ data, fieldInfoLookup, tableKey }) {
  const hours = Array.from({ length: 24 }, (_, index) => index); //hours in day
  let maxCost = 0,
    minCost = Infinity;
  data.flat().forEach((dailyCost) => {
    const cost = dailyCost[tableKey];
    if (maxCost < cost) maxCost = cost;
    else if (minCost > cost) minCost = cost;
  });
  const costRange = maxCost - minCost;

  const fieldInfo = fieldInfoLookup.find(({ field }) => field == tableKey);
  let [title, unitDisplay] = jsxToString(fieldInfo.title).split("(");
  unitDisplay = unitDisplay.slice(0, -1);

  return (
    <Box>
      <Typography>{title}</Typography>
      <Box justifyContent="space-evenly" display="flex" height={300}>
        <table className="table-heatmap">
          <tbody>
            <tr>
              <th className="header-cell" />
              {hours.map((hour) => (
                <th
                  key={`${hour}:00_header`}
                  className="header-cell vertical-print-bt"
                >
                  {Military_to_AMPM(`${hour}:00`).replaceAll(" ", "\xa0")}
                </th>
              ))}
            </tr>
            {data.map((rowData, rowIndex) => (
              <tr key={monthNames[rowIndex]}>
                <th className="header-cell">{monthNames[rowIndex]}</th>
                {rowData.map((cellData, colIndex) => (
                  <Tooltip
                    key={`${rowIndex}_${colIndex}`}
                    title={
                      <>
                        {roundNumber(
                          fieldInfo.unitConversion(cellData[tableKey]),
                          4
                        )}{" "}
                        {unitWrapper(unitDisplay)}
                      </>
                    }
                  >
                    <td
                      className="data-cell"
                      style={{
                        cursor: "default",
                        backgroundColor:
                          colorGradient[
                            Math.floor(
                              colorGradient.length -
                                1 -
                                ((colorGradient.length - 1) *
                                  (cellData[tableKey] - minCost)) /
                                  costRange
                            )
                          ],
                      }}
                    />
                  </Tooltip>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        &nbsp;
        <div
          style={{
            height: "100%",
            width: "10px",
            borderWidth: "1px",
            borderColor: "black",
            borderStyle: "solid",
            background: `linear-gradient(${colorGradient.join(", ")})`,
          }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          className="vertical-print-bt"
          sx={{ fontSize: "13px" }}
        >
          <div>{roundNumber(fieldInfo.unitConversion(minCost), 4)}</div>
          {unitWrapper(unitDisplay)}
          <div>{roundNumber(fieldInfo.unitConversion(maxCost), 4)}</div>
        </Box>
      </Box>
    </Box>
  );
}
