import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { depotURL } from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { multiDayUploadStepInfo } from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitLargeMap,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { NextPageButton } from "../../assessmentPages/commonComponents";
import UseAuth from "../../auth/useAuth";
import { MultiDayUploadAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import { getUnits, unitFeet, unitMiles } from "../../secondary/unitConversions";
import {
  AMPM_to_Military,
  errorHandler,
  getLabel,
  getLocalData,
  MFM_to_AMPM,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import ScatterChart from "./scatterChart";

const STEP_NUMBER = 2;

export const getSizeDisplay = (vehTypeDisplay, size) => {
  const units = getUnits();
  let sizeDisplay;

  if (vehTypeDisplay == "Transit" || vehTypeDisplay == 1)
    sizeDisplay = `${unitFeet(size)} ${unitSmallMap[units]}`;
  else if (vehTypeDisplay == "School Bus" || vehTypeDisplay == 4)
    sizeDisplay = `Type ${size}`;
  else if (size == 14) sizeDisplay = "Transit";
  else sizeDisplay = `Class ${size}`;

  return sizeDisplay;
};

const HistoricalAnalysisTable = ({
  selectedRows,
  setSelectedRows,
  outlierData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [showTable, setShowTable] = useState(0); ///0 for display table, false for display chart
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const viewNameLookup = { 0: "Table", 1: "Chart" };
  const [depotLookup, setDepotLookup] = useState({});
  const { snackBarElement } = useContext(SnackBarContext);
  const [subheaderContent, setSubheaderContent] = useState([]);
  const [highestEnergyBlocks, setHighestEnergyBlocks] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    depotDepartureTime: false,
    depotArrivalTime: false,
  });

  const designRunRows = highestEnergyBlocks.map((row) => row.blockId);
  const units = getUnits();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      //construt depot Lookup
      const { data: currentProject } = await getLocalData("project", "data");

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      let depot_lookup = {};
      //fetches all the depots associated with the selected project
      fetch(`${depotURL}?project_id=${currentProject.id}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(({ data: depots }) => {
              depots.forEach((depot) => (depot_lookup[depot.id] = depot.name));
              setDepotLookup(depot_lookup);
            });
          } else errorHandler(res, snackBarElement);
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something went wrong in depot fetch",
            "error"
          );
        });

      //find design rows to highlight
      const blocksByLocation = [];
      Object.keys(outlierData).forEach((busClass) => {
        Object.keys(outlierData[busClass]).forEach((locationKey) => {
          const locationData = outlierData[busClass][locationKey];
          const energyResults = locationData.energy_results || {};
          const outlierBlockIdSet = new Set(locationData.outliers || []);

          const maxEnergyBlocks = new Map();
          Object.values(energyResults).forEach((result) => {
            result.blocks.forEach((block) => {
              if (outlierBlockIdSet.has(block.blockId)) return;
              const totalEnergy = block.detailed_energy?.total_energy || 0;
              if (
                !maxEnergyBlocks.has(busClass) ||
                totalEnergy > maxEnergyBlocks.get(busClass).total_energy
              ) {
                block.total_energy = totalEnergy;
                block.location = locationKey;
                maxEnergyBlocks.set(busClass, block);
              }
            });
          });
          blocksByLocation.push(...Array.from(maxEnergyBlocks.values()));
        });
      });

      // Processed data
      const energy_results = [];
      const processedDataArray = [];
      const outlierSet = new Set();

      Object.values(outlierData).forEach((busClassData) => {
        Object.values(busClassData).forEach((locationData) => {
          locationData.outliers.forEach((outlier) => outlierSet.add(outlier));
          Object.values(locationData.energy_results).forEach((result) =>
            energy_results.push(...result.blocks)
          );
        });
      });

      energy_results.forEach((block) => {
        const {
          veh_type,
          size,
          vehicle_id: veh_id,
          date,
          blockId,
          dh_st_time,
          dh_end_time,
          distance,
          endDepot,
        } = block;

        const duration = dh_end_time - dh_st_time;
        const isOutlier = outlierSet.has(blockId);
        const depotDepartureTime = MFM_to_AMPM(dh_st_time);
        const depotArrivalTime = MFM_to_AMPM(dh_end_time);
        const roundedDistance = distance;
        const vehicleTypeDisplay =
          TYPE_STRINGS.VEHICLE_TYPE[veh_type] || veh_type;
        const sizeDisplay = getSizeDisplay(vehicleTypeDisplay, size);
        const arrivalDepot = endDepot;

        processedDataArray.push({
          vehicleType: vehicleTypeDisplay,
          size: sizeDisplay,
          vehicleId: veh_id,
          date,
          blockId,
          depotDepartureTime,
          depotArrivalTime,
          arrivalDepot,
          distance: roundedDistance,
          duration,
          isOutlier,
        });
      });

      const sortedData = processedDataArray.sort((a, b) =>
        a.isOutlier ? 1 : -1
      );

      // Initialize row selection
      const { data: rowSelection } = await getLocalData(
        "historicalAnalysisData",
        "data"
      );

      if (rowSelection && Object.values(rowSelection).filter(Boolean).length)
        setSelectedRows(rowSelection);
      else
        setSelectedRows(
          sortedData.reduce((acc, row) => {
            if (!row.isOutlier) acc[row.blockId] = true;
            return acc;
          }, {})
        );
      setProcessedData(sortedData);
      setHighestEnergyBlocks(blocksByLocation);
      setIsLoading(false);
    };

    if (!Array.isArray(outlierData)) fetchData();
  }, [outlierData]);

  const computeSelectedRowMetrics = useMemo(() => {
    if (!processedData.length) return {};

    const numOutliers = processedData.filter((row) => row.isOutlier).length;

    if (!Object.values(selectedRows).filter(Boolean).length)
      return { numOutliers };

    const selectedData = processedData.filter(
      (row) => selectedRows[row.blockId]
    );

    if (!selectedData.length) return null;

    const totalDistance = selectedData.reduce(
      (sum, row) => sum + row.distance,
      0
    );
    const maxDistance = Math.max(...selectedData.map((row) => row.distance), 0);
    const avgDistance = totalDistance / selectedData.length;

    const totalDuration = selectedData.reduce(
      (sum, row) => sum + row.duration,
      0
    );
    const maxDuration = Math.max(...selectedData.map((row) => row.duration), 0);
    const avgDuration = totalDuration / selectedData.length;

    return {
      numOutliers,
      avgDistance,
      maxDistance,
      avgDuration,
      maxDuration,
    };
  }, [selectedRows, processedData]);

  useEffect(() => {
    if (computeSelectedRowMetrics) {
      const formatDuration = (duration = 0) => {
        const hours = Math.floor(duration / 60);
        const minutes = Math.round(duration % 60);
        return `${hours}h\xa0${minutes}m`;
      };

      setSubheaderContent([
        {
          value: (isNaN(computeSelectedRowMetrics.numOutliers)
            ? "-"
            : Math.round(computeSelectedRowMetrics.numOutliers)
          ).toLocaleString(),
          label: <>Number of&nbsp;Outliers</>,
        },
        {
          value: `~ ${Math.round(
            computeSelectedRowMetrics.avgDistance ?? 0
          ).toLocaleString()}`,
          label: (
            <>
              Average&nbsp;Distance&nbsp;of Selected&nbsp;Rows&nbsp;(
              {unitLargeMap[units]})
            </>
          ),
        },
        {
          value: `~ ${Math.round(
            computeSelectedRowMetrics.maxDistance ?? 0
          ).toLocaleString()}`,
          label: (
            <>
              Maximum&nbsp;Distance&nbsp;of Selected&nbsp;Rows&nbsp;(
              {unitLargeMap[units]})
            </>
          ),
        },
        {
          value: `~\xa0${formatDuration(
            computeSelectedRowMetrics.avgDuration
          )}`,
          label: <>Average&nbsp;Duration of&nbsp;Selected&nbsp;Rows</>,
        },
        {
          value: `~\xa0${formatDuration(
            computeSelectedRowMetrics.maxDuration
          )}`,
          label: <>Maximum&nbsp;Duration of&nbsp;Selected&nbsp;Rows</>,
        },
      ]);
    }
  }, [computeSelectedRowMetrics]);

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Outlier",
        id: "isOutlier",
        accessorFn: (row) => (row.isOutlier ? "true" : "false"), //must be strings (according to MRT, for filtering)
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() == "true" ? "Yes" : "No"),
      },
      { accessorKey: "vehicleType", header: "Vehicle Type" },
      { accessorKey: "size", header: "Size" },
      { accessorKey: "vehicleId", header: getLabel("block_id_original") },
      { accessorKey: "date", header: "Date" },
      { accessorKey: "blockId", header: getLabel("block_id") },
      {
        accessorKey: "depotDepartureTime",
        header: "Depot Departure Time",
        sortingFn: (rowA, rowB, columnId) =>
          AMPM_to_Military(rowA.getValue(columnId)) >
          AMPM_to_Military(rowB.getValue(columnId)),
      },
      {
        accessorKey: "depotArrivalTime",
        header: "Depot Return Time",
        sortingFn: (rowA, rowB, columnId) =>
          AMPM_to_Military(rowA.getValue(columnId)) >
          AMPM_to_Military(rowB.getValue(columnId)),
      },
      {
        header: "Depot",
        accessorKey: "arrivalDepot",
        Cell: ({ cell }) => depotLookup[cell.getValue()] ?? "Loading...",
        filterVariant: "select",
        filterSelectOptions: Object.entries(depotLookup).map(
          ([value, label]) => ({ value, label })
        ),
        muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } },
      },
      {
        accessorKey: "distance",
        header: "Distance",
        accessorFn: (row) => roundNumber(unitMiles(row.distance), 1),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
          </>
        ),
      },
      {
        accessorKey: "duration",
        header: "Duration",
        Cell: ({ cell }) => formatDuration(cell.getValue()),
      },
    ],
    [depotLookup]
  );

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return (
      <Box component="span">
        <span style={{ color: "black" }}>{hours}</span>
        <span style={{ color: "grey" }}>h </span>
        <span style={{ color: "black" }}>{mins}</span>
        <span style={{ color: "grey" }}>m</span>
      </Box>
    );
  };

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    columns,
    data: processedData,
    state: {
      rowSelection: selectedRows,
      isLoading: Array.isArray(outlierData) || isLoading,
      rowPinning: { top: designRunRows },
      columnVisibility,
    },
    // Enable column visibility toggle
    onColumnVisibilityChange: setColumnVisibility,

    //miscellaneous options
    enableEditing: false,
    getRowId: (row) => row.blockId,

    // row pinning options
    rowPinningDisplayMode: "top",
    muiTableBodyRowProps: ({ row, ...rest }) => {
      if (designRunRows.includes(row.id))
        return {
          title: `Design ${getLabel("blocks")}`,
          sx: (theme) => ({
            backgroundColor: row.getIsSelected()
              ? `${theme.palette.success.light}77 !important`
              : `${theme.palette.success.light}aa !important`,
          }),
        };
    },

    // row selection options
    enableRowSelection: true,
    onRowSelectionChange: setSelectedRows,
  });

  return (
    <>
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {multiDayUploadStepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
        <Alert severity="info" sx={{ width: "150%" }}>
          Take a moment to review the outliers below and re-select any you
          believe should be included.
        </Alert>
        &nbsp;
        <Chip
          label={viewNameLookup[showTable]}
          onClick={(e) => setViewAnchorEl(e.currentTarget)}
          sx={{ minWidth: "6rem" }}
          disabled={!Object.keys(outlierData).length}
        />
        <Menu
          open={Boolean(viewAnchorEl)}
          anchorEl={viewAnchorEl}
          onClose={() => setViewAnchorEl(null)}
          PaperProps={{
            style: { minWidth: viewAnchorEl?.clientWidth }, // makes the dropdown the same size as the chip
            className: "btn",
          }}
        >
          {Object.entries(viewNameLookup).map(([key, label]) => (
            <MenuItem
              key={`ShowTable_${key}_Option`}
              value={key}
              onClick={(e) => {
                setShowTable(e.currentTarget.value);
                setViewAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <Subheader content={subheaderContent} />
          {showTable == 0 ? (
            <MaterialReactTable table={table} />
          ) : (
            <ScatterChart
              outlierData={outlierData}
              highestEnergyBlocks={highestEnergyBlocks}
            />
          )}
        </Paper>
      </Container>
    </>
  );
};

export default function HistoricalAnalysis() {
  const [selectedData, setSelectedData] = useState({});
  const [outlierData, setOutlierData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    document.querySelector("#App").scrollIntoView(); //scrolls to top of app

    async function fetchOutlierData() {
      const { data } = await getLocalData("fleetProjection", "data");

      if (!data) {
        snackBarElement?.current?.displayToast(
          "No data found",
          "warning",
          5000
        );
        setOutlierData({});
        return;
      }
      setOutlierData(data);
    }
    fetchOutlierData();
  }, []);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      //clear out the future pages' frontend data
      partialClearLocalData(["scheduleGeneration"]);
      storeLocalData("historicalAnalysisData", { data: selectedData });
      navigate(multiDayUploadStepInfo[STEP_NUMBER + 1].route);
    } catch (error) {
      console.error("Navigation to the next page failed:", error);
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        margin: "20px auto",
        padding: "20px",
        width: "95%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <br />
      <br />
      <MultiDayUploadAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <HistoricalAnalysisTable
        selectedRows={selectedData}
        setSelectedRows={setSelectedData}
        outlierData={outlierData}
      />
      <br />
      <br />
      <Container>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="outlined"
            className="btn"
            fullWidth
            component={Link}
            to={multiDayUploadStepInfo[STEP_NUMBER - 1].route}
            startIcon={<ArrowBackIosNew />}
          >
            Back to {multiDayUploadStepInfo[STEP_NUMBER - 1].label}
          </Button>
          <NextPageButton
            fullWidth
            onClick={handleContinue}
            disabled={Object.values(selectedData).filter(Boolean).length == 0} // Disable if no rows are selected
            loading={isLoading}
          >
            Continue to {multiDayUploadStepInfo[STEP_NUMBER + 1].label}
          </NextPageButton>
        </Stack>
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
