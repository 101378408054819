import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  appliedLicenseStatusURL,
  licensesURL,
  organizationProfileURL,
} from "../../static/constants/backendRoutes";
import BannerImage from "../../static/images/banner.jpeg";
import UseAuth from "../auth/useAuth";
import { errorHandler, stringCapitalize, useQuery } from "../utils";
import { LocationDropDowns } from "./defaultDialog";
import { ChangeAppliedLicenseDialog, LicenseStatus } from "./licensesOptions";

export default function OrganizationProfile() {
  const OrganizationID = useQuery().get("orgId");
  const [organizationData, setOrganizationData] = useState(undefined);
  //note: the licensedata must be consistent with the setLicenseData in the dialog at bottom of this file
  const [licenseData, setLicenseData] = useState({
    overview: [
      { num_projects: 0, usage: 0 },
      { num_users: 0, usage: 0 },
    ],
  });
  const [licenseList, setLicenseList] = useState();
  const [changeLicenseDialogOpen, setChangeLicenseDialogOpen] = useState(false);
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [orgLogo, setOrgLogo] = useState();

  const { accessRights, setLogo } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  /** additional props shared by all input Textfields */
  const inputProps = {
    fullWidth: true,
    inputProps: { disabled: !editMode }, //prevents user from editting input when not in edit mode
    variant: editMode ? "filled" : "standard",
  };

  function fetchAppliedLicenses() {
    const headers = { Authorization: `Token ${UseAuth("get")}` };
    return fetch(
      `${appliedLicenseStatusURL}?organization_id=${OrganizationID}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((res) => {
        if (res.ok) res.json().then(({ data }) => setLicenseData(data));
        else
          errorHandler(
            res,
            snackBarElement,
            "Failed to get Organization's License Data"
          );
      })
      .catch((error) => {
        console.log(error);
        snackBarElement?.current?.displayToast(
          "Network Error occurred while trying to get applied license data",
          "error",
          5000
        );
      });
  }

  useEffect(() => {
    function fetchData() {
      const headers = { Authorization: `Token ${UseAuth("get")}` };

      /**
       * fetches the organization profile
       */
      fetch(`${organizationProfileURL}?organization_id=${OrganizationID}`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            setError(false);
            response.json().then(({ data }) => setOrganizationData(data));
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Failed to get Organization Profile details"
            );
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
          console.log(error);
          snackBarElement?.current?.displayToast(
            "Network Error occurred while trying to get organization profile details",
            "error",
            5000
          );
        });

      if (accessRights.admin.list_licenses && OrganizationID != 1) {
        //if user admin, and NOT site organization
        /** fetches the liscensing status */
        fetchAppliedLicenses();

        fetch(licensesURL, { method: "GET", headers })
          .then((res) => {
            if (res.ok) res.json().then(({ data }) => setLicenseList(data));
            else
              errorHandler(
                res,
                snackBarElement,
                "Failed to get list of licenses"
              );
          })
          .catch((e) => {
            console.log(e);
            snackBarElement?.current?.displayToast(
              "Network Error: failed to get list of licenses",
              "error",
              5000
            );
          });
      }
    }

    if (OrganizationID) fetchData();
  }, [OrganizationID, accessRights]);

  /**
   * Sends updated Organization details to backend
   * @param {SubmitEvent} event
   */
  function handleUpdate(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    //checks that at least 1 field has been altered, before making backend update
    const isModified = !Object.entries(formJSON).every(
      ([key, value]) => value == (organizationData && organizationData[key])
    );
    if (!isModified) {
      snackBarElement.current.displayToast(
        "Organization was not altered from Original",
        "info"
      );
      setEditMode(false);
      return;
    }

    formData.append("name", organizationData.name);
    formData.append("organization_id", OrganizationID);

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
    };
    setButtonLoading(true);

    fetch(organizationProfileURL, { method: "PATCH", headers, body: formData })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast(
            "Organization Updated",
            "success"
          );
          if (
            window.location.host.split(".")[0] == organizationData.subdomain &&
            formJSON?.logo?.size
          )
            setLogo(URL.createObjectURL(formJSON.logo));
          setOrganizationData({ ...organizationData, ...formJSON });
          setEditMode(false);
        } else {
          errorHandler(response, snackBarElement);
        }
      })
      .catch((e) => {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong",
          "error",
          4000
        );
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }

  /**
   * @param {React.InputHTMLAttributes<HTMLInputElement>.onChange?: React.ChangeEventHandler<HTMLInputElement>} event
   */
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file?.size >= 20971520) {
      alert("File size is too large! Please select a smaller image.");
      return undefined;
    }
    setOrgLogo(file);
  };

  function handleChangeLicenseClose(data) {
    if (data && licenseData.license_id) {
      setLicenseData((prev) => ({
        ...prev,
        license_id: data.license_id,
        name: data.license_name,
        overview: [
          { ...prev.overview[0], num_projects: data.num_projects },
          { ...prev.overview[1], num_users: data.num_users },
        ],
      }));
      setChangeLicenseDialogOpen(false);
    } else if (data)
      //if no license id, then there aren't yet proper usage values, and I should populate them as a result
      fetchAppliedLicenses().finally(() => setChangeLicenseDialogOpen(false));
    else setChangeLicenseDialogOpen(false);
  }

  return (
    <>
      <Box
        sx={{
          height: "25vh",
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: -1,
        }}
      />

      {!OrganizationID ? (
        // some quick error checks
        <Typography variant="h3" gutterBottom component="div">
          <br />
          Organization Not Specified
        </Typography>
      ) : error ? (
        // error page
        <h3>
          <br />
          Something Went Wrong Retrieving Organization Data
        </h3>
      ) : !organizationData ? (
        //if profile has not yet been recieved from the backend, display loading
        <div>
          <br /> Loading... <br />
          <CircularProgress variant="indeterminate" />
        </div>
      ) : (
        <form onSubmit={handleUpdate}>
          <br />
          <Container sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              component="div"
              align="left"
              className="page-title"
            >
              <span className="header-green">
                {organizationData.name.replaceAll(" ", "\xa0")}
              </span>
              &nbsp;Profile
            </Typography>{" "}
            <Container fixed>
              {organizationData.is_active ? (
                <Typography
                  variant="h6"
                  align="left"
                  style={{ fontWeight: 500, color: "green" }}
                >
                  Active
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  align="left"
                  style={{ fontWeight: 500, color: "darkgrey" }}
                >
                  Inactive
                </Typography>
              )}
            </Container>
            {editMode && (
              <>
                {orgLogo && (
                  <>
                    <b>Logo:</b>&nbsp;{orgLogo.name.replaceAll(" ", "_")}
                  </>
                )}
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ px: 5, mx: 2 }}
                >
                  Change&nbsp;Logo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    id="logo"
                    name="logo"
                    onChange={handleLogoUpload}
                  />
                </Button>
              </>
            )}
          </Container>
          <Container>
            <br />
            <Grid container spacing={4}>
              {accessRights.admin.list_licenses && ( //only show to admin users
                <>
                  {licenseData?.name && ( //do not display if organization is site
                    <Grid item xs={12}>
                      <Paper elevation={3}>
                        <LicenseStatus licenseData={licenseData} />
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="brand_status"
                      name="brand_status"
                      label="Brand Status"
                      defaultValue={
                        TYPE_STRINGS.BRAND_STATUS[organizationData.brand_status]
                          ?.label ?? ""
                      }
                      fullWidth
                      inputProps={{ disabled: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="subdomain"
                      name="subdomain"
                      label="Subdomain"
                      defaultValue={organizationData.subdomain}
                      fullWidth
                      inputProps={{ disabled: true }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="website"
                  name="website"
                  label="Website"
                  type="url"
                  defaultValue={organizationData.website}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  multiline
                  maxRows={4}
                  defaultValue={organizationData.description}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="phone_number"
                  name="phone_number"
                  label="Phone"
                  type="tel"
                  defaultValue={organizationData.phone_number}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6} textAlign="start">
                <TextField
                  id="organization_type"
                  name="organization_type"
                  label="Organization Type"
                  type="text"
                  defaultValue={stringCapitalize(
                    organizationData.organization_type
                  )}
                  fullWidth
                  inputProps={{ disabled: true }}
                />
              </Grid>
              {editMode ? (
                <LocationDropDowns
                  profile={organizationData}
                  props={inputProps}
                />
              ) : (
                <>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="country_code"
                      name="country_code"
                      label="Country"
                      defaultValue={organizationData.country_code}
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="state_code"
                      name="state_code"
                      label="State"
                      defaultValue={organizationData.state_code}
                      {...inputProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={3}>
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  defaultValue={organizationData.city}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="zipcode"
                  name="zipcode"
                  autoComplete="postal-code"
                  label="Zipcode"
                  defaultValue={organizationData.zipcode}
                  {...inputProps}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            {accessRights.admin.list_licenses && ( //only show to admin users
              <Stack direction="row" spacing={2}>
                {organizationData?.organization_type == "partner" && (
                  <Button
                    fullWidth
                    variant="outlined"
                    className="btn"
                    component={Link}
                    to={`/customer?partner_id=${OrganizationID}`}
                  >
                    Manage Customers
                  </Button>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  className="btn"
                  component={Link}
                  to={`/membership?orgId=${OrganizationID}`}
                >
                  Manage Users
                </Button>
                {editMode ? (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    className="btn"
                    type="submit"
                    loading={buttonLoading}
                  >
                    Save Changes
                  </LoadingButton>
                ) : (
                  <Button
                    fullWidth
                    variant="outlined"
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault(); //prevents auto-submitting form (this... seems unnecessary, as it's not a submit-type button)
                      setEditMode(true);
                    }}
                    disabled={!accessRights.admin.update_organization_profile}
                  >
                    Edit Organization
                  </Button>
                )}
                {/* {OrganizationID != 1 && accessRights.admin.list_licenses && (
                  <Button
                    fullWidth
                    variant="outlined"
                    className="btn"
                    onClick={() => setChangeLicenseDialogOpen(true)}
                    disabled
                    // disabled={
                    //   !(
                    //     accessRights.admin.list_licenses &&
                    //     accessRights.admin.update_applied_license &&
                    //     licenseList
                    //   )
                    // }
                  >
                    Change License
                  </Button>
                )} */}
              </Stack>
            )}
          </Container>
        </form>
      )}

      <Dialog
        open={changeLicenseDialogOpen}
        onClose={() => setChangeLicenseDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>Change License</DialogTitle>
        <ChangeAppliedLicenseDialog
          onClose={handleChangeLicenseClose}
          data={{ applied_license: licenseData, license_list: licenseList }}
          organization_id={OrganizationID}
        />
      </Dialog>
    </>
  );
}
